// **********************************************************************************
// IMPORTANT: This file is used explicitly for the Shopping application DO NOT REMOVE
// **********************************************************************************

/*!
 * Sass Bootstrap v3.0.16
 *
 * Copyright 2013 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world by @mdo and @fat.
 * 
 * Sass -ified by Aaron Lademann @alademann
 */

// Core variables and mixins

  @import "../../../../shopping/styles/application/app-theme.scss";

  @import "variables";
  @import "mixins";

// Reset
  @import "normalize";
  @import "print";

// Core CSS
  @import "scaffolding";
  @import "type";
  //@import "code"; Unused
  @import "grid";
  @import "tables";
  @import "forms";
  //@import "buttons"; Moved to shopping/styles/application/ui-components/

// Components: common
  @import "component-animations";
  //@import "glyphicons"; Unused
  @import "dropdowns";
  //@import "button-groups"; Moved to shopping/styles/application/ui-components/
  @import "input-groups";
  @import "navs";
  @import "navbar";
  //@import "breadcrumbs"; Unused
  //@import "pagination"; Moved to shopping/styles/application/components/
  //@import "pager";
  //@import "labels"; Replaced with tags in shopping
  //@import "badges"; Replaced with badges in shopping
  //@import "jumbotron"; Unused
  //@import "thumbnails"; Unused
  //@import "progress-bars"; Unused
  @import "media";
  @import "list-group";
  @import "panels";
  //@import "wells"; Moved to shopping/styles/application/components/
  @import "close";

// Components w/ javascript
  @import "alerts";
  //@import "modals"; Moved to shopping/styles/application/ui-components/
  @import "tooltip";
  @import "popovers";
  //@import "carousel"; Unused (using external library)

// Utility classes
  @import "utilities";

// Has to be last to override when necessary
  @import "responsive-utilities";
