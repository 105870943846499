// STACK
// -----
// This is meant to be used when there's a group of child elements you need spaced consistently from one another,
// while correctly handling first and last element style.
// .stack-bordered puts borders around each child (top on first and bottom on last child) and no extra padding on each child
// .stack-separated separates each child with margin, padding and border

.stack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  > * + * {
    margin-top: $stack-offset-space;
  }

  &.stack-bordered,
  &.stack-separated {
    > *:first-of-type {
      border-top: $stack-bordered-border-width solid $stack-bordered-border-color;
    }

    > * + * {
      border-top: $stack-bordered-border-width solid $stack-bordered-border-color;
      margin-top: $stack-bordered-offset-space;
    }

    > *:last-of-type {
      border-bottom: $stack-bordered-border-width solid $stack-bordered-border-color;
    }
  }

  &.stack-md {
    > * + * {
      margin-top: $stack-offset-space * 2;
    }
  }

  &.stack-lg {
    > * + * {
      margin-top: $stack-offset-space * 4;
    }
  }

  &.stack-xl {
    > * + * {
      margin-top: $stack-offset-space * 6;
    }
  }

  &.stack-separated {
    > *:first-of-type {
      border-top: 0;
    }

    > *:last-of-type {
      border-bottom: 0;
    }

    &.stack-md {
      > * + * {
        margin-top: $stack-offset-space * 2;
        padding-top: $stack-offset-space * 2;
      }
    }

    &.stack-lg {
      > * + * {
        margin-top: $stack-offset-space * 4;
        padding-top: $stack-offset-space * 4;
      }
    }

    &.stack-xl {
      > * + * {
        margin-top: $stack-offset-space * 6;
        padding-top: $stack-offset-space * 6;
      }
    }
  }

  &.stack-p {
    > *:last-of-type {
      margin-bottom: $padding-base-vertical * 8;
    }
  }

  &.stack-styled { //used in My Trips
    a {
      @include section-body-styled($section-body-styled-border-radius);
      box-shadow: $stack-list-styled-box-shadow;
      text-decoration: none;
      color: $text-color;

      &:hover {
        box-shadow: $shadow-lg;
      }
    }
  }
}