// Desktop/Tablet
// -------------------------

.case-base.page-activity-detail {
  // content block positions
  $activity-detail-pos1: 0 10px;
  $activity-detail-pos2: 10px 80px;
  $activity-detail-pos3: 200px 80px;
  $activity-detail-pos4: 200px 135px;
  $activity-detail-pos5: 200px 185px;

  $activity-detail-pos6: 210px 275px;
  $activity-detail-pos7: 92.5% 275px;
  $activity-detail-pos8: 210px 325px;

  $activity-detail-pos9: 210px 500px;
  $activity-detail-pos10: 92.5% 500px;
  $activity-detail-pos11: 210px 550px;

  $activity-detail-pos12: 210px 725px;
  $activity-detail-pos13: 92.5% 725px;
  $activity-detail-pos14: 210px 775px;

  $activity-detail-pos15: 0 930px;

  // content block sizes
  $activity-detail-size1: 465px 40px;
  $activity-detail-size2: 175px 100px;
  $activity-detail-size3: 40% 40px;
  $activity-detail-size4: 60% 40px;
  $activity-detail-size5: 60% 40px;

  $activity-detail-size6: 40% 40px;
  $activity-detail-size7: 33% 40px;
  $activity-detail-size8: 40% 40px;

  $activity-detail-size9: 40% 40px;
  $activity-detail-size10: 33% 40px;
  $activity-detail-size11: 40% 40px;

  $activity-detail-size12: 40% 40px;
  $activity-detail-size13: 33% 40px;
  $activity-detail-size14: 40% 40px;

  $activity-detail-size15: 100% 40px;

  .page-body--is-loading .page-body {
    margin-top: 80px;

    &::after {
      @include skeleton-setup(activity-detail-animate);

      $activity-detail-lines : (
        lines(3, $skeleton-screen-content-block-color, 79%, 200px, 25px, 200px, 265px, 79%, true, 1px)
      );

      @include skeleton (
        animate($skeleton-screen-overlay-rbga-color, 85vw, 100vh, 100%, 0),

        rectangle($skeleton-screen-content-block-color, $activity-detail-size1, $activity-detail-pos1),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size2, $activity-detail-pos2),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size3, $activity-detail-pos3),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size4, $activity-detail-pos4),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size5, $activity-detail-pos5),

        rectangle($skeleton-screen-content-block-color, $activity-detail-size6, $activity-detail-pos6),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size7, $activity-detail-pos7),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size8, $activity-detail-pos8),

        rectangle($skeleton-screen-content-block-color, $activity-detail-size9, $activity-detail-pos9),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size10, $activity-detail-pos10),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size11, $activity-detail-pos11),

        rectangle($skeleton-screen-content-block-color, $activity-detail-size12, $activity-detail-pos12),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size13, $activity-detail-pos13),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size14, $activity-detail-pos14),

        rectangle($skeleton-screen-content-block-color, $activity-detail-size15, $activity-detail-pos15),

        $activity-detail-lines...
      );

      @media (max-width: $screen-lg) {
        @include skeleton (
          animate($skeleton-screen-overlay-rbga-color, 85vw, 100vh, 100%, 0),

          rectangle($skeleton-screen-content-block-color, $activity-detail-size1, $activity-detail-pos1),
          rectangle($skeleton-screen-content-block-color, $activity-detail-size2, $activity-detail-pos2),
          rectangle($skeleton-screen-content-block-color, $activity-detail-size3, $activity-detail-pos3),
          rectangle($skeleton-screen-content-block-color, $activity-detail-size4, $activity-detail-pos4),
          rectangle($skeleton-screen-content-block-color, $activity-detail-size5, $activity-detail-pos5),
          rectangle($skeleton-screen-content-block-color, $activity-detail-size6, $activity-detail-pos6),
          rectangle($skeleton-screen-content-block-color, $activity-detail-size7, 96.5% 275px),
          rectangle($skeleton-screen-content-block-color, $activity-detail-size8, $activity-detail-pos8),
          rectangle($skeleton-screen-content-block-color, $activity-detail-size9, $activity-detail-pos9),
          rectangle($skeleton-screen-content-block-color, $activity-detail-size10, 96.5% 500px),
          rectangle($skeleton-screen-content-block-color, $activity-detail-size11, $activity-detail-pos11),
          rectangle($skeleton-screen-content-block-color, $activity-detail-size12, $activity-detail-pos12),
          rectangle($skeleton-screen-content-block-color, $activity-detail-size13, 96.5% 725px),
          rectangle($skeleton-screen-content-block-color, $activity-detail-size14, $activity-detail-pos14),
          rectangle($skeleton-screen-content-block-color, $activity-detail-size15, $activity-detail-pos15),
          $activity-detail-lines...
        );
      }
    }
  }

  $activity-detail-background-size-list: (
    $activity-detail-size1,
    $activity-detail-size2,
    $activity-detail-size3,
    $activity-detail-size4,
    $activity-detail-size5,
    $activity-detail-size6,
    $activity-detail-size7,
    $activity-detail-size8,
    $activity-detail-size9,
    $activity-detail-size10,
    $activity-detail-size11,
    $activity-detail-size12,
    $activity-detail-size13,
    $activity-detail-size14,
    $activity-detail-size15
  );

  $activity-detail-background-size-border-list: (
    calc(79% - 2px) calc(200px - 2px),
    79% 200px,
    calc(79% - 2px) calc(200px - 2px),
    79% 200px,
    calc(79% - 2px) calc(200px - 2px),
    79% 200px
  );

  @keyframes activity-detail-animate {
    0% {
      background-size:
        100vw 100vh,
        $activity-detail-background-size-list,
        $activity-detail-background-size-border-list
    }

    100% {
      background-size:
        0 100vh,
        $activity-detail-background-size-list,
        $activity-detail-background-size-border-list
    }
  }
}

.case-handheld.page-activity-detail {
  // content block positions
  $activity-detail-pos1h: 10px 0;
  $activity-detail-pos2h: 15px 40px;
  $activity-detail-pos3h: 123px 40px;
  $activity-detail-pos4h: 123px 65px;
  $activity-detail-pos5h: 15px 140px;

  $activity-detail-pos6h: 25px 190px;
  $activity-detail-pos7h: 25px 220px;
  $activity-detail-pos8h: 25px 250px;
  $activity-detail-pos9h: 25px 350px;
  $activity-detail-pos10h: 25px 390px;

  $activity-detail-pos11h: 25px 510px;
  $activity-detail-pos12h: 25px 540px;
  $activity-detail-pos13h: 25px 570px;
  $activity-detail-pos14h: 25px 670px;
  $activity-detail-pos15h: 25px 710px;

  $activity-detail-pos16h: 25px 830px;
  $activity-detail-pos17h: 25px 860px;
  $activity-detail-pos18h: 25px 890px;
  $activity-detail-pos19h: 25px 990px;
  $activity-detail-pos20h: 25px 1030px;

  // content block sizes
  $activity-detail-size1h: 92% 30px;
  $activity-detail-size2h: 100px 60px;
  $activity-detail-size3h: 61% 20px;
  $activity-detail-size4h: 61% 20px;
  $activity-detail-size5h: 95% 1px;

  $activity-detail-size6h: 85% 20px;
  $activity-detail-size7h: 85% 20px;
  $activity-detail-size8h: 85% 20px;
  $activity-detail-size9h: 85% 30px;
  $activity-detail-size10h: 30% 20px;

  $activity-detail-size11h: 85% 20px;
  $activity-detail-size12h: 85% 20px;
  $activity-detail-size13h: 85% 20px;
  $activity-detail-size14h: 85% 30px;
  $activity-detail-size15h: 30% 20px;

  $activity-detail-size16h: 85% 20px;
  $activity-detail-size17h: 85% 20px;
  $activity-detail-size18h: 85% 20px;
  $activity-detail-size19h: 85% 30px;
  $activity-detail-size20h: 30% 20px;


  .page-body--is-loading .page-body {
    &::after {
      @include skeleton-setup(activity-detail-animate-h);

      $activity-detail-lines-h: (
        lines(3, $skeleton-screen-content-block-color, 95%, 300px, 25px, center, 160px, 95%, true, 1px)
      );

      @include skeleton (
        animate($skeleton-screen-overlay-rbga-color, 85vw, 100vh, 100%, 0),

        rectangle($skeleton-screen-content-block-color, $activity-detail-size1h, $activity-detail-pos1h),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size2h, $activity-detail-pos2h),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size3h, $activity-detail-pos3h),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size4h, $activity-detail-pos4h),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size5h, $activity-detail-pos5h),

        rectangle($skeleton-screen-content-block-color, $activity-detail-size6h, $activity-detail-pos6h),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size7h, $activity-detail-pos7h),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size8h, $activity-detail-pos8h),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size9h, $activity-detail-pos9h),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size10h, $activity-detail-pos10h),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size11h, $activity-detail-pos11h),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size12h, $activity-detail-pos12h),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size13h, $activity-detail-pos13h),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size14h, $activity-detail-pos14h),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size15h, $activity-detail-pos15h),

        rectangle($skeleton-screen-content-block-color, $activity-detail-size16h, $activity-detail-pos16h),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size17h, $activity-detail-pos17h),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size18h, $activity-detail-pos18h),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size19h, $activity-detail-pos19h),
        rectangle($skeleton-screen-content-block-color, $activity-detail-size20h, $activity-detail-pos20h),

        $activity-detail-lines-h...

      );
    }
  }

  $activity-detail-background-size-list-h: (
    $activity-detail-size1h,
    $activity-detail-size2h,
    $activity-detail-size3h,
    $activity-detail-size4h,
    $activity-detail-size5h,
    $activity-detail-size6h,
    $activity-detail-size7h,
    $activity-detail-size8h,
    $activity-detail-size9h,
    $activity-detail-size10h,
    $activity-detail-size11h,
    $activity-detail-size12h,
    $activity-detail-size13h,
    $activity-detail-size14h,
    $activity-detail-size15h,
    $activity-detail-size16h,
    $activity-detail-size17h,
    $activity-detail-size18h,
    $activity-detail-size19h,
    $activity-detail-size20h
  );

  $activity-detail-background-size-border-list-h: (
    calc(95% - 2px) calc(300px - 2px),
    95% 300px,
    calc(95% - 2px) calc(300px - 2px),
    95% 300px,
    calc(95% - 2px) calc(300px - 2px),
    95% 300px
  );

  @keyframes activity-detail-animate-h {
    0% {
      background-size:
        100vw 100vh,
        $activity-detail-background-size-list-h,
        $activity-detail-background-size-border-list-h
    }

    100% {
      background-size:
        0 100vh,
        $activity-detail-background-size-list-h,
        $activity-detail-background-size-border-list-h
    }
  }
}