//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit

// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Account for hiding of scrollbar
body.modal-open,
.modal-open .navbar-fixed-top,
.modal-open .navbar-fixed-bottom {
  margin-right: 15px
}

// Container that the modal scrolls within
.modal {
  display: none;
  text-align: left;
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-background;

  // When fading in the modal, animate it to slide down
  &.fade .modal-dialog {
    @include translate(0, -25%);
    @include transition-transform(#{"0.3s ease-in-out"});
  }
  &.in .modal-dialog {
    @include translate(0, 0);
  }

  &.modal-iframe-content .modal-body {
    padding-right: 0;
    height: 100%;
  }

  &.modal-iframe-flushed {
    .modal-content {
      border: none;
    }
    .modal-body {
      padding: 0;
      height: 100%;
    }
  }

  &.modal-wide {
    @extend %wide-modal;
  }

  &.modal-content-width {
    @extend %content-width-modal;
  }

  &.modal-xl {
    @extend %modal-xl;
  }
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  margin-left: auto;
  margin-right: auto;
  padding: $modal-dialog-padding;
  z-index: ($zindex-modal-background + 10);
}

// Actual modal
.modal-content {
  position: relative;
  background-color: $modal-content-bg;
  border: none;
  border-radius: $modal-border-radius;
  @include box-shadow(0 3px 9px rgba(0, 0, 0, .5));
  background-clip: padding-box;
  // Remove focus outline from opened modal
  outline: none;
  // Set up grid layout for sticky header/footer + scrolling body
  display: grid;
  -ms-grid-rows: auto 1fr auto;
  grid-template-rows: auto 1fr auto;
  max-height: 85vh;
  // Fix for iOS scroll bug, requires overflow: auto on modal-body
  -webkit-overflow-scrolling: touch;
  margin: 0 auto;

  &.modal-loading {
    // Forces loading modals to be their natural height
    height: auto;

    .modal-body {
      // Makes sure no scrolling in loading modals
      overflow: hidden;
    }
  }

  .empty {
    // TODO: See if this is really needed + refactor
    background: transparent;
  }

  .hr-text {
    @include hrText($modal-content-bg, currentColor);
  }
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ($zindex-modal-background - 10);
  background-color: $modal-backdrop-bg;
  // Fade for backdrop
  &.fade {
    @include opacity(0);
  }
  &.in {
    opacity: $modal-backdrop-in-opacity;
  }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  padding: $modal-title-padding;
  @media (max-width: $screen-sm) {
    padding: 10px;
  }
  border-bottom: 1px solid $modal-header-border-color;
  min-height: 50px; //prevents collapse when zoomed at 200%
  height: 100%; //prevents collapse when h1 is sr-only
  background: $modal-header-bg;
  color: $modal-header-text-color;
  overflow: hidden;
  border-top-left-radius: $modal-border-radius;
  border-top-right-radius: $modal-border-radius;

  @media(max-width: $screen-xxs) {
    padding: 2vw;
    padding-bottom: 1.5vw;
  }

  h3 {
    color: $modal-header-text-color;
  }
}

// Close icon
.modal-header .close {
  margin-top: -2px;
  color: $modal-header-close-color;
  @include opacity($modal-header-close-opacity);

  &:focus {
    opacity: 1;
  }
}

// Title text within header
// higher specificity is needed to accommodate themes with solid modal header background
.modal-header .modal-title {
  margin: 0;
  padding-right: 1em; // allows room for ellipsis
  line-height: $modal-title-line-height;
  color: $modal-title-text-color;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  padding: $modal-inner-padding;
  background: $modal-body-bg;
  overflow: auto;
  word-break: break-word;
  -webkit-overflow-scrolling: touch;
  border-radius: $modal-border-radius;
  border-bottom: 3px solid $modal-body-bg; //needed for when there is a border radius

  .modal-processing-text {
    padding-top: $padding-large-vertical;
    padding-bottom: $padding-large-vertical*2;
    font-size: $modal-processing-text-font-size;
    color: $modal-processing-text-color;
    font-weight: $modal-processing-text-font-weight;
  }

  .modal-longer-processing-wait-text {
    font-family: 'switchflyShopping';

    &:before {
      content: "\e920";
      font-size: 500%;
      color: $brand-warning;
    }
  }

  .modal-progress-bar {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 10px;
      background: $gray-lighter;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
      overflow: hidden;
      margin-top: $padding-base-vertical;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 0;
      height: 100%;
      box-shadow: 0 0 5px rgba(0, 0, 0, .2);
      animation: load 60s normal forwards;
    }

    @keyframes load {
      0% {
        width: 0;
        background: $brand-info;
      }

      25% {
        width: 40%;
        background: $brand-primary;
      }

      50% {
        width: 60%;
        background: $brand-primary;
      }

      100% {
        width: 100%;
        background: $brand-success;
      }
    }
  }

  iframe {
    width: 100%;
    height: 365px;
    height: 50vh;
  }

  .financial span {
    .currency-symbol,
    .currency-value {
      display: inline;
    }

    .currency-value {
      width: 80%;
      text-align: right;
    }

    .shortfall .currency-value {
      width: auto;
      display: inline-block;
    }

    .shortfall .currency-symbol {
      display: inline-block;
    }
  }

  > a[href^="www"],
  > a[href^="http"] {
    word-break: break-all;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
}

// Footer (for actions)
.modal-footer {
  margin-top: 0;
  background: $modal-footer-bg;
  padding: $modal-footer-padding;
  @media (max-width: $screen-sm) {
    padding: 10px;
  }
  text-align: right;
  // right align buttons
  border-top: 1px solid $modal-footer-border-color;
  @include clearfix;
  // clear it in case folks use .pull-* classes on buttons
  border-bottom-left-radius: $modal-border-radius;
  border-bottom-right-radius: $modal-border-radius;

  // Properly space out buttons
  .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0;
    // account for input[type="submit"] which gets the bottom margin like all other inputs
  }
  // but override that for button groups
  .btn-group .btn + .btn {
    margin-left: -1px;
  }
  // and override it for block buttons as well
  .btn-block + .btn-block {
    margin-left: 0;
  }

  .pull-left {
    // TODO: See if this is really needed + refactor
    text-align: left;
  }
}

.case-handheld {
  .modal-footer .btn {
    @extend .btn-block;
  }

  #modify-search-modal {
    .modal-body {
      word-break: inherit;
    }
  }
}

.modal-full {
  margin: 1vw;
  overflow: hidden; //prevents double scroll

  .modal-dialog {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
  .modal-content {
    height: 92vh;
    max-height: 92vh;
  }

  &.modal-full-padding {
    .modal-body {
      padding: $modal-inner-padding;
    }

    .hr-text {
      @include hrText($modal-content-bg, $hr-border, solid);
      font-weight: $text-medium-font-weight;
    }
  }
}

// Scale up the modal
@media screen and (min-width: $screen-sm) {
  .modal-dialog {
    left: 50%;
    right: auto;
    width: $modal-width-sm;
    padding-top: $modal-dialog-padding-vertical;
    padding-bottom: $modal-dialog-padding-vertical;
  }
  .modal-content {
    @include box-shadow(0 5px 15px rgba(0, 0, 0, .5));
  }
}

@media (min-width: $screen-md)  {
  .modal-dialog {
    width: $modal-width-md;
  }
}

@media screen and (min-width: $screen-lg) {
  .modal-dialog {
    width: $modal-width-lg;
  }
}

// Safari 11+ check
@media not all and (min-resolution: .001dpcm) {
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    // Prevents modals from jumping back to top
    // when interacting with modal content on tablets
    #modify-search-modal .modal-content {
      height: 80vh;
    }
  }
}

// Safari iOS Device Check
@media screen {
  @supports (-webkit-overflow-scrolling: touch) {
    #loyalty-program-option-help-modal-body.modal-body {
      height: 80vh;
    }
  }
}

// Modal w/ Compact Header
// -------------------------
// Removes margin on modal header > h1

.modal-compact-header .modal-header > h1 {
  margin: 0;
}

// Wide Modal
// -------------------------
// Extend for when a modal's content requires a wider modal to accommodate
// Examples are: seat map, pricing summary breakdown
// Sample usage:
// #seat-map-modal {
//   @extend %wide-modal;
// }

%wide-modal {
  .modal-dialog {
    width: 80vw;
    max-width: $modal-wide-max-width;
    @media (max-width: $screen-sm) {
      width: 95vw;
    }
  }
}

%modal-xl { //Modify Search needs a wider modal to accommodate search form
  .modal-dialog {
    @media screen and (min-width: $screen-sm)  {
        width: $modal-xl-width;
        max-width: $screen-lg;
    }
  }
}

%content-width-modal {
  .modal-dialog {
    width: 100%;
  }

  @media (min-width: $screen-sm) {
    .modal-dialog {
      max-width: $container-tablet;
    }
  }

  @media (min-width: $screen-md) {
    .modal-dialog {
      max-width: $container-desktop;
    }
  }

  @media (min-width: $screen-lg-desktop) {
    .modal-dialog {
      max-width: $container-lg-desktop;
    }
  }
}
