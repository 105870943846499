.branded-fare-upsell-modal {

  @mixin upsell-branded-fare-kard($fare-short-description-color, $list-icon-color) {
    @include box-shadow($upsell-modal-fare-card-box-shadow);
    border-width: $upsell-modal-fare-card-border-width;
    border-color: $upsell-modal-fare-card-border-color;

    .kard-block {
      padding: $padding-large-horizontal;
    }

    .kard-title {
      color: $upsell-modal-card-fare-name-color;
      font-size: $upsell-modal-card-fare-name-size;
    }

    .short-description {
      color: $fare-short-description-color;
      font-weight: $text-strong-font-weight;
      margin-bottom: $padding-large-vertical;
    }

    .fare-product-price {
      margin: $padding-large-horizontal 0;
      .price {
        color: $upsell-modal-price-color;
        font-size: $upsell-modal-price-size;
      }
    }

    .list-icon-bullets li:before {
      color: $list-icon-color;
    }
  }

  .kard-selected {
    background-color: rgba($upsell-modal-selected-fare-card-background-color, $upsell-modal-selected-fare-card-background-color-opacity);
    @include upsell-branded-fare-kard($upsell-modal-selected-fare-short-description-color, $upsell-modal-selected-fare-inclusion-list-icon-color);
  }

  .kard-upsell {
    background-color: rgba($upsell-modal-upsell-fare-card-background-color, $upsell-modal-upsell-fare-card-background-color-opacity);
    @include upsell-branded-fare-kard($upsell-modal-upsell-fare-short-description-color, $upsell-modal-upsell-fare-inclusion-list-icon-color);
  }
}
