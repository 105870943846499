@media print {
  button,
  .btn,
  a,
  .circle-loader.load-complete {
    display: none;
  }

  a[href]:after {
    content: none !important;
  }

  .page-body .page-header,
  p,
  .p,
  .p-sm,
  h2,
  .h2 {
    margin: 0 !important;
  }

  h1,
  h2,
  .h1,
  .h2 {
    font-size: $font-size-large;
  }

  #site-header .logo-primary {
    @if ($site-header-logo-primary-file-path != "") {
      content: url('#{$site-header-logo-primary-file-path}');
    }
    display: block;
    min-width: initial;
    max-width: 200px;
  }

}