// The following styles apply only to handheld devices,
// and only for when in "base" case (i.e. we haven't explicitly identified the user as handheld and are providing all handheld assets)
// These are IN ADDITION to all other app-*.scss styles
// Note: this is called "mobile" to clarify its usage relative to "handheld" naming, which indicates an assets CASE (base vs handheld).


.case-handheld {

  #booking-form .section-body [class*=col-] {
    width: 100%;
    display: block;
  }

  #site-header {
    margin-bottom: 0;
    .points {
      font-size: inherit;
    }
  }

  .page-content {
    padding: 0;
  }

  h1 {
    margin: 10px 0;
  }

  .main-header {

    input.typeahead {
      margin-bottom: 0;
    }

  }

  .product {

    .rate-deal {
      margin: 0;
      position: absolute;
      top: 0;
      let: 0;
      width: 100%;
      text-align: left;
      padding: 8px 0;
      @include opacity(0.85);
      line-height: 1;
      word-break: inherit;
    }

    .product-deal {
      &.client-approved,
      &.exclusive-deal {
        padding: 8px;
        line-height: 1;
        border-top: 1px solid $product-border-color;
      }
    }

    .product-body {
      display: flex;
      border-width: 1px 0;

      > [class*=col-]:first-of-type {
        background: #eee;
        margin-left: -1px;
        overflow: hidden;
      }
    }

    .price-container {
      border-left: 0;
    }

    .media-body > .col-xs-10 {
      padding-top: $padding-base-vertical;
      padding-bottom: $padding-base-vertical;
    }

    img.media-object {
      margin: 5px auto;
    }

    img.media-object:not([src]) {
      visibility: hidden;
    }

    .media-icon {
      line-height: 13;
    }

    &.product-selected {

      .product-body,
      .product-deal {
        border-color: $product-border-color-selected;
      }
    }

    &.product-loading {
      background: $product-body-bg-color-selected;

      .icon-collapsible--closed:before {
        @extend .icon-spin;
        content: "\f110";
      }
    }

    &.product-mini {
      img.media-object {
        min-height: inherit;
      }
    }

  }

  .itinerary-container, .dash-cart-container {
    .section-body {
      padding: 10px 5% 10px 2%;
    }
  }

  .open .dropdown-menu {
    display: block;
  }

  .header-toolbar-container {
    padding: 0;
    border-bottom: 1px solid #cecece;

    .header-tool {
      border-right: 1px solid $gray-lighter;

      &.last {
        border-right: 0;
      }

      button,
      a {
        width: 100%;
        border-radius: 0;
      }
      
      a {
        text-decoration: none;
      }

      .dropdown-menu {
        margin-top: -1px;
        border-radius: 0;
        text-align: left;

        li a:before {
          content: "-";
          display: inline-block;
          width: 16px;
          margin-right: 3px;
        }

        li.active a:before {
          font-family: "switchflyShopping";
          content: "\e942";
          color: #fff;
        }

        a {
          text-decoration: none;
        }
      }
    }
  }

  #filters {
    width: 100%;

    .filter {
      width: 66%;
      margin: 0 auto;
    }
  }

  .modal-body {
    padding: 10px;
  }
}