// Transform - CSS3 Animation
// -------------------------
  // Sample: @include transform(rotate(180deg));

  @mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
  }

  // Transform origin
  // Sample: @include transform-origin(left top);
  @mixin transform-origin ($origin) {
    -moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
  }

// Animation - CSS3 Animation
// -------------------------
  // Sample: @include animation(spin 1s infinite linear);

  @mixin animation($params) {
    -webkit-animation: $params;
    -moz-animation: $params;
    -ms-animation: $params;
    -o-animation: $params;
    animation: $params;
  }

  // Transition
  // Sample: @include transition(border 500ms ease-out);
  @mixin transition($params) {
    -webkit-transition: $params;
    -moz-transition: $params;
    -ms-transition: $params;
    -o-transition: $params;
    transition: $params;
  }

  // Animation Duration
  // Sample: @include animation-duration(1.2s);
  @mixin animation-duration($duration) {
    -webkit-animation-duration: $duration;
    -moz-animation-duration: $duration;
    -ms-animation-duration: $duration;
    -o-animation-duration: $duration;
    animation-duration: $duration;
  }

  // Animation Timing
  // Sample: @include animation-timing-function(ease);
  @mixin animation-timing-function($name) {
    -webkit-animation-timing-function: $name;
    -moz-animation-timing-function: $name;
    -ms-animation-timing-function: $name;
    -o-animation-timing-function: $name;
    animation-timing-function: $name;
  }

  // Animation Name
  // Sample: @include animation-name(spin);
  @mixin animation-name($name) {
    -webkit-animation-name: $name;
    -moz-animation-name: $name;
    -ms-animation-name: $name;
    -o-animation-name: $name;
    animation-name: $name;
  }

  // Animation Delay
  // Sample: @include animation-delay(-0.3s);
  @mixin animation-delay($delay) {
    -webkit-animation-delay: $delay;
    -moz-animation-delay: $delay;
    -ms-animation-delay: $delay;
    -o-animation-delay: $delay;
    animation-delay: $delay;
  }
