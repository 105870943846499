#compare-fare-types-modal table {
  background: transparent;

  thead th {
    border-bottom: 0;
    border-top: 0;
    vertical-align: middle;
    width: 100%;

    &.fare-type {
      background: $branded-flight-column-header-cell-background-color;
      border: 1px solid $branded-flight-column-header-border-color;
      border-bottom: 0;
      color: #fff;
      text-align: center;

      &:nth-of-type(2) {
        background: lighten($branded-flight-column-header-cell-background-color, 3%);
      }

      &:nth-of-type(3) {
        background: lighten($branded-flight-column-header-cell-background-color, 6%);
      }

      &:nth-of-type(4) {
        background: lighten($branded-flight-column-header-cell-background-color, 9%);
      }

      &:nth-of-type(5) {
        background: lighten($branded-flight-column-header-cell-background-color, 12%);
      }

      &:nth-of-type(6) {
        background: lighten($branded-flight-column-header-cell-background-color, 15%);
      }
    }

    &.fare-type-price {
      background: $modal-content-bg;
      text-align: center;

      &:nth-of-type(2) {
        color: lighten($branded-flight-column-header-cell-price-color, 3%);
      }

      &:nth-of-type(3) {
        color: lighten($branded-flight-column-header-cell-price-color, 6%);
      }

      &:nth-of-type(4) {
        color: lighten($branded-flight-column-header-cell-price-color, 9%);
      }

      &:nth-of-type(5) {
        color: lighten($branded-flight-column-header-cell-price-color, 12%);
      }

      &:nth-of-type(6) {
        color: lighten($branded-flight-column-header-cell-price-color, 15%);
      }
    }

  }

}