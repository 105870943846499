// Ancillary Modules
// -----------------------------
// Ancillary seat maps (#seat-selection) styles are in _seatmap.base.scss

.main-contents-container {

  .ancillary-module {
    margin-bottom: calc(#{$line-height-computed} * 3);

    li.product {
      list-style-type: none;
      margin-bottom: 15px;
    }

    header {
      color: $ancillary-header-text-color;
      padding: 0 0 $padding-large-vertical 0;
      border-bottom: 0;

      p {
        color: $ancillary-header-subtitle-text-color;
        padding-bottom: $padding-base-vertical;
        font-weight: 400;
      }

      .price {
        color: $ancillary-header-title-text-color;
      }
    }

    .section-body {
      background: $ancillary-section-body-bg-color;
      padding: $ancillary-section-body-padding;
    }

    .product-detail ul {
      list-style-type: none;
      padding: 0;
    }

    .head-table-structure, .body-table-structure {
      background: #fff;
      & > li {
        display: inline-block;
        width: calc(30% - 17px);

        p {
          margin: 0;
        }
      }
      & > div {
        display: inline-table;
        table-layout: fixed;
        width: calc(70% + 13px); //todo find a better way! this is just a quick fix
      }
    }

    .body-table-structure > li {
      vertical-align: text-top;
      padding: 8px;
      vertical-align: middle;
      line-height: 4em;
      padding: 0 $padding-small-horizontal;
    }

    .row-table-structure {
      border: 1px solid $ancillary-addons-product-table-border-color;
      border-top: 0;
      display: block;
      & > div {
        width: 100%;
      }
    }

    .cell-table-structure, .head-table-structure > li {
      border: none;
      vertical-align: bottom;
      padding: 8px;
      font-weight: 700;
    }

    .head-table {
      border: 0;
      border-bottom: 1px solid $ancillary-addons-product-table-border-color;
    }

    .cell-table-structure {
      text-align: center;
    }

    .cell-table-structure, .body-table-structure > div > div {
      display: table-cell;
    }

    .col-interactive > div {
      padding: 8px 0;
    }

    .components-header {
      background: $gray-lighter;
      font-weight: $text-strong-font-weight;
    }

    &.compound-module {

      small {
        display: block;
      }

      .product {
        background: $ancillary-addons-product-background-color;
        padding: 0 10px 10px;
        margin-bottom: $padding-base-vertical;

        &.selected {
          outline: 1px solid $link-color;
        }

        td, th {
          text-align: center;

          &:first-of-type {
            text-align: left;
          }

          &.disabled {
            background: rgba(0, 0, 0, .1);
            @include opacity(0.5);
          }
        }

        thead th {
          border-top: 0;
        }

        tbody td {
          vertical-align: middle;
        }

        .col-interactive {
          background: $ancillary-col-interactive-background-color;
          color: $ancillary-col-interactive-text-color;
          min-height: 4em;
          display: inline-block;
          width: 100%;

          &.selected {
            background: $ancillary-col-interactive-selected-background-color;
            color: $ancillary-col-interactive-selected-text-color;

            &:hover {
              background: darken($ancillary-col-interactive-selected-background-color, 15%);
            }

            .price-strikethrough {
              color: $ancillary-col-interactive-selected-text-color;
            }

          }

          &:hover {
            background: $ancillary-col-interactive-hover-background-color;
          }

          .price-strikethrough {
            color: $ancillary-col-interactive-link-color;
          }

          .icon-spin {
            line-height: 3.5em;
          }
        }

        .product-detail {
          margin-top: ($padding-small-vertical * 5);
          padding-top: 10px;
          margin-bottom: ($padding-small-vertical * 5);
        }
      }

      .product-body {
        background: transparent;
        border: 0;

        ul {
          margin-bottom: 0;

          li {
            line-height: 1.5;
          }
        }

        .addon-container {
          position: relative;
          padding-top: ($padding-small-vertical * 4)
        }

        .addon-link-bottom {
          position: absolute;
          bottom: $padding-small-vertical;
          left: $padding-small-horizontal;
        }

        .addon-description-container {
          margin-bottom: $padding-large-vertical * 4;
        }

      }

      .cols-height-match > [class*=col-]:last-of-type {
        vertical-align: bottom;
      }

    }

    .product-header {
      > h3 {
        font-weight: 400;
        margin-bottom: 0;
      }
      padding-bottom: 0;
      border-bottom: 1px dotted $ancillary-addons-product-contents-border-color;
      font-weight: 400;
    }

    .product-summary,
    .product-summary-footer {
      padding: 10px 0;
    }

    .product-summary-footer {
      padding-bottom: 0;
      .btn {
        margin-left: $grid-gutter-width;
      }
    }

    .product-footer {

      .price-success {
        color: $brand-success;
      }

    }

    .addons-footer {
      padding: 40px 25px 10px;
      border-top: 0;
    }

    .membership-tier {
      font-style: italic;
      text-transform: lowercase;

      &:first-letter {
        text-transform: capitalize;
      }
    }
  }

  // ADDONS SECTION
  // -----------------

  #hotel-results,
  #car-results,
  #addons-selection {
    .section-body {
      .product {
        border-bottom: 4px solid $ancillary-addons-product-selection-border-bottom-color;
        margin-bottom: 0;
        padding: 15px 25px 30px;

        .product-footer {
          padding-top: 0;
        }
      }
      .addons-footer {
        padding: 40px 25px 10px;
        border-top: 0;
      }
    }
  }

  #hotel-results,
  #car-results {
    .section-body {
      .product {
        padding: 0;
        margin-top: 0;

      }
      .product-body {
        border: 0;
        .product-padding .img {
          top: 0;
        }
        .media-heading {
          font-weight: $text-medium-font-weight;
        }
      }
      li:last-of-type {
        border-bottom: 0;
      }
    }
  }

  #addons-selection {
    .product-header {
      padding-bottom: $padding-small-vertical;
    }

    .section-body {
      .product:nth-last-of-type(2) {
        border-bottom: 0;
      }
    }
  }

  .product {
    .traveler {
      background: darken($table-bg, 10%);
    }

    tbody:nth-of-type(even) .traveler {
      background: darken($table-tbody-stripe-bg-color, 10%);
    }
  }

  .col-product-option {
    min-width: 12em;
    max-width: 20em;
  }

  .table tbody > tr > td.col-product-option {
    padding: 20px 8px;
  }

  #bundles-selection {

    .card {
      max-width: 40%;
    }

    .card-footer {
      align-items: flex-end;
    }

    .icon-product__selected {
      color: $list-product-selected-color;
    }

    .ancillary-bundles {
      margin-top: 40px;
    }

    .ancillary-bundle-savings span {
      background: $ancillary-bundles-selection-savings-background-color;
      padding: $padding-base-vertical $padding-large-horizontal;
    }

    .ancillary-bundle-item {
      margin-bottom: $padding-large-vertical;
    }

    .ancillary-bundle-legs {
      border-bottom: 1px solid $product-border-color;
    }

    .ancillary-bundle-subtotal span:nth-of-type(1) {
      margin-top: $padding-large-vertical;
      margin-right: $padding-large-horizontal;
    }
  }

  #hotel-preview-results {
    .card-deck {
      justify-content: flex-start;
      align-items: flex-start;
    }

    .card {
      background: $gray-lighter;
      flex: 0 1 50%;
      max-width: none;

      img {
        background: white;
      }
    }

    .card-block {
      flex-direction: column;
      padding: $padding-large-vertical;
      padding-bottom: ($padding-large-vertical * 2);

      .card-header-title {
        margin-top: $padding-large-vertical;

        .card-title-name {
          padding-top: $padding-small-vertical;
        }
      }
    }

    .hotel-preview-selected {
      padding-bottom: ($padding-small-vertical * 4);

      .container {
        padding: ($padding-large-vertical*2);
      }

      h3 {
        margin-top: 0;
      }

      h4 {
        display: inline;
      }

      .selected-label {
        padding-bottom: ($padding-small-vertical*3);
      }

      .selected-hotel-info {
        background: transparent;
        margin-top: ($padding-large-vertical*2);

        td:nth-child(odd) {
          min-width: ($padding-small-horizontal * 11)
        }

        .room-title {
          vertical-align: text-top;
        }
      }

      .hotel-information {
        border-right: 1px solid $product-border-color-selected;
      }
    }
  }
}

.popover-bag-details {
  .popover-body {
    padding-left: 0;
  }
}
