.page-manage-booking {
  background-color: $manage-booking-main-bg-color;

  #shopping-page-container {
    &.page-content {
      padding-left: 0;
      padding-right: 0;
    }

    >.page-body {
      margin-bottom: 0;
    }
  }
}

.manage-booking-container {
  .grid-container {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) 3fr;
    column-gap: $padding-large-vertical * 3;

    .grid-main-content {
      background-color: $manage-booking-main-bg-color;
      padding-bottom: $page-body-margin-bottom;
    }
  }

  .nav-pills-container {
    background-color: $manage-booking-section-bg-color;
    width: 100%;
    min-height: 90vh;
  }

  .nav-pills-alt {
    padding: $manage-booking-nav-pills-padding;

    li {
      display: block;
    }
  }

  section {
    @extend .stack;
    @extend .stack-md;
  }

  .section-body {
    @include section-body-styled($manage-booking-section-border-radius);

    h3 {
      margin-top: 0;
    }
  }

  .stack * > h4 {
    margin: 0 0 20px;
  }

  .flex-container {
    column-gap: $padding-large-horizontal;
  }

  .flex-item > .text-strong {
    text-transform: uppercase;
  }

  .box {
    text-align: center;
    background-color: $manage-booking-main-bg-color;
    border-color: $base-border-color;
  }

  .last-pane {
    margin-top: $stack-offset-space * 6;
  }

  #pricing-detail-content caption.deposit-balance-due-text {
    font-weight: normal;
  }

  @media (max-width: $screen-xs) {
    .flex-container {
      display: block;

      .flex-item + .flex-item {
        margin-top: $padding-large-vertical;
      }
    }
  }

  @media (max-width: $screen-sm) {
    .grid-main-content {
      @include container-fixed;
    }

    .nav-pills-container {
      min-height: inherit;
    }

    .grid-container {
      display: block;
    }

    .nav-pills-alt { //horizontal scroll
      scrollbar-width: none; //ff
      -ms-overflow-style: none;
      white-space: nowrap;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      li {
        display: inline-block;
        padding-right: $padding-small-horizontal;
      }
    }
  }

  @media (min-width: $screen-sm) {
    .nav-pills-alt {
      li {
        margin-bottom: $padding-large-vertical;
        line-height: inherit;

        a,
        button {
          border: none;
        }
      }
    }
  }

  .hr-lined {
    position: relative;
    text-align: center;
    width: 100%;
  }

  .hr-lined::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: $base-border-color;
  }

  .hr-lined-text {
    padding: 0 20px;
    background-color: $almost-white;
    display: inline-block;
    z-index: 999;
    position: relative;
  }
}