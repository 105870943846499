/* Bing Map */


// Use for supporting basic client theming colors for Bing Maps v8 PushPins
// This will be set on hidden elements on DOM to be targeted dynamically for setting on the PushPin options
.hidden-map-marker {
  display: none;
  background: $map-marker-color;
}

.hidden-map-marker-cluster {
  display: none;
  color: $map-marker-cluster-text-color;
  background: $map-marker-cluster-background-color;
  border: $map-marker-cluster-border-width solid $map-marker-cluster-border-color;
}

.MicrosoftMap {
  .OverlaysBR-logoAware {
    display: none;
  }

  overflow: hidden;

  a {
    color: $link-color;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &.btn.btn-primary {
      color: $btn-primary-color;
    }
  }

  .map-marker {
    display: inline-block;
    height: 20px;
    width: 20px;
    // Note: fixed size is important for pin location accuracy; .js positioning is dependent on these dimensions.
    border-radius: 50%;
    background: $map-marker-color;
    @include box-shadow(0 0 0 2px $map-marker-outline-color);
    cursor: pointer;
    pointer-events: all;

  }

  .map-marker-cluster {
    text-align: center;
    border-radius: 50%;
    width: 2em; // Must be this size to accommodate 3 digit numbers
    height: 2em;
    line-height: 1.9;
    letter-spacing: -.5px; // Helps fit 3 digits numbers
    color: $map-marker-cluster-text-color;
    background: $map-marker-cluster-background-color;
    @include box-shadow(0 0 0 3px $map-marker-cluster-outline-color);
    border: $map-marker-cluster-border-width solid $map-marker-cluster-border-color;
    cursor: pointer;
    pointer-events: all;
    position: relative;
    z-index: 1;
  }

  .map-marker-active {
    color: $map-marker-active-text-color;
    background: $map-marker-active-background-color;
    border: $map-marker-cluster-border-width solid $map-marker-active-border-color;
    @include box-shadow(0 0 0 3px $map-marker-active-outline-color);
    z-index: 2;
    position: relative;
  }


  // Add downward facing arrow on Infobox pointing to associated map pin
//  .Infobox + div:after {
//    content: "";
//    display: inline-block;
//    height: 0;
//    width: 0;
//    z-index: 999;
//    border-top: 15px solid $map-product-mini-background-color;
//    border-left: 15px solid transparent;
//    border-right: 15px solid transparent;
//    position: absolute;
//    left: 155px;
//    bottom: 5px;
//  }

  // Mini product cards for a single map pin
  .product-mini {
    background: $map-product-mini-background-color;
    border: 1px solid $map-product-mini-border-color;
    color: $map-product-mini-text-color;
    padding: 10px 2px;
    width: 90vw;
    max-width: 350px;
    position: relative;
    z-index: 100;
    bottom: 20px;
    box-shadow: 0 0 15px rgba(0,0,0,.5); // Note: purposefully ignoring the mixin
    cursor: pointer;

    &:after {
      left: 150px !important;
    }

    .rating span.star {
      &:before {
        color: darken($map-product-mini-background-color, 15%);
      }
    }

    .price-container {
      border-left: 0;
      padding-top: 5px;

      > ul {
        margin-bottom: 0;
      }
    }

    .popover-footer {
      border-top: 1px solid $gray-lighter;
      padding: $popover-body-spacing-v;
    }
  }

  // Mini product cards for clustered map pins
  .product-mini-cluster {
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0 0 15px rgba(0,0,0,.5); // Note: purposefully ignoring the mixin
    border: 0;
    padding: 0;

    .product-mini {
      margin: 0 0 -1px 0;
      box-shadow: none;
      padding: 5px 0;
      bottom: 0;

      .price-info .price-total:after {
        content: "";
        display: block;
      }

      .price-info .price-total {
        padding-left: 5px;
      }

      > a {
        max-height: 90px;
        overflow: hidden;
      }

      > a, > div {
        padding: 0 5px;
      }
    }

    .media-heading {
      font-size: $font-size-base;
      line-height: 1;
    }
  }
}

.filter-bing-map-container {
  height: $filter-bing-map-container-height;
  margin-bottom: $padding-large-vertical * 2;
}

.map-container {
  position: relative;
}

.filter-bing-map {
  height: 100%;
  position: relative;

  .map-container {
    height: 100%;
  }

  .map-warning {
    position: absolute;
    left: 0;
    right: 0;
    top: 40%;
    margin: 0 auto;
  }

  .basic-typeahead-container {
    width: 100%;
    padding: 8px 0 17px 0;
    border: 1px solid $gray-lighter;
    background: $almost-white;
    margin-bottom: 10px;
    @include box-shadow($shadow-sm);

    .tt-dropdown-menu {
        width: 100%;
        overflow-y: auto !important;
        z-index: 1100 !important;
    }
  }

  #map-search {
    z-index: 1100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: $almost-white;
    padding-top: $padding-large-vertical;
    @include box-shadow($shadow-sm);
  }

}

#info-card-modal {
  .modal-body {
    max-height: 60vh;
    overflow-y: auto;
  }

  .product .price-container {
    border-left: 0;
  }

  .list-group-item {
    display: block;
    margin-top: $padding-base-vertical;
    @include box-shadow($shadow-md);
  }

  .list-inline > li:first-of-type {
    padding-left: 0;
  }
}
