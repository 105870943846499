// Consider .handheld case when updating this file.

.products-list {

  &.products-rooms {

    li.product {
      padding: $padding-large-vertical 0;

      .room-selected {
        background: $product-room-selected-bg-color;
        border: 3px solid $product-border-color-selected;

        .product-body {
          border: none;
        }

        .col-interactive {
          background: $product-room-selected-bg-color;
        }
      }

      .product-body-footer {
        background: transparent; // override of default behavior
      }
    }

    .price-container {
      background-color: transparent;
      border: none;
      height: initial;
      padding-top: 0;
      padding-right: 0;
    }
  }
}

.product {
  overflow: visible;

  &.aside-contents-container section {
    background-color: $product-aside-section-background-color;

    @if $product-aside-section-background-color == transparent {
      @extend .border-right;

      .section-body {
        padding: inherit;
      }
    }
  }

  .no-results {
    padding: 10px;
  }

  .hr-text {
    @include hrText($product-body-bg-color, currentColor);
  }

  @import "_expandable-container";

  .product-featured-deal {
    background: $product-featured-background-color;
    color: $product-featured-name-text-color;
    font: $product-featured-font-weight $product-featured-font-size $product-featured-font-family;

    &.selected {
      font-size: $product-featured-deal-selected-outbound-font-size;
    }

    ~ .product-body {
      border: $product-featured-deal-border;
    }
  }

  &.product-selected {
    .product-additional-options-panel.panel-success,
    .product-body {
      background: $product-body-bg-color-selected;
      border: 3px solid $product-border-color-selected;
      border-radius: $product-body-border-radius;
    }

    .product-details-components-container {
      background: $product-body-bg-color-selected;
    }

  }

  .product-name {
    @extend .h3;
    margin: 0;
  }

  .product-loading {
    &.activity-rate {
      // Select ONLY activity-rate cards for this on purpose - we don't want the product-loading state on air/hotel/car cards to update, just the button.
      background: $product-body-bg-color-loading;
      border-color: $product-border-color-loading;
      border-width: $product-border-width-loading;

      .price-container {
        background: $product-body-bg-color-loading;
        border-color: $product-border-color-loading;
        border-top-right-radius: $product-body-border-radius;
        border-bottom-right-radius: $product-body-border-radius;
      }
    }

    .text-info {
      color: $product-loading-text-info-color;
    }
  }

  .product-selected {
    &.activity-rate {
      // Select ONLY activity-rate cards for this on purpose - we don't want the product-loading state on air/hotel/car cards to update, just the button.
      background: $product-body-bg-color-selected;
      border: 3px solid $product-border-color-selected;
      border-radius: $product-body-border-radius;

      .price-container {
        background: $product-body-bg-color-selected;
        border-color: $product-border-color-selected;
        border-radius: 0 $product-body-border-radius $product-body-border-radius 0;
      }
    }
  }

  &.product-xsell {
    background: $product-body-bg-color-xsell;
    border-color: $product-body-border-color-xsell;
  }

  /* product media */

  .product-header,
  .product-footer {
    @include clearfix;
  }

  .product-body-footer {
    padding: $padding-small-vertical*2 $padding-small-vertical*3; // Same as .product-body .product-padding below
    background: $product-body-footer-bg-color;
    color: $product-body-footer-text-color;
    border-top: 1px solid $gray-lighter;
    font-size: $product-body-footer-font-size;
  }

  .product-body {
    @extend .clearfix;
    padding: 0;
    border: 1px solid $product-border-color;
    background: $product-body-bg-color;
    box-shadow: $product-body-box-shadow;
    border-radius: $product-body-border-radius;

    .leg-active {
      background-color: $product-leg-bg-color-active;
    }

    .filter-product-col {
      width: 4%; //align checkbox filters with stopover row
    }

    .product-additional-info-row {
      background-color: $stopover-bg-color;
      color: $stopover-text-color;
    }

    .product-notification {
      border: 1px solid $product-notification-border-color;
      background: $product-notification-body-bg-color;
      color: $product-notification-body-text-color;
    }
  }

  .product-body,
  .product-detail {
    img {
      border-radius: $product-image-border-radius;
      max-width: 325px;


    }
  }

  .product-body .product-padding {
    padding: $padding-small-vertical * 3;

    @media(max-width: $screen-xs) {
      padding: $padding-small-vertical; //for a11y
    }

    .media-link {
      text-decoration: $product-header-link-text-decoration;
    }

    .img,
    .product-deal-container {
      position: relative;
      top: 3px; // Better aligns top of img with top of hotel name
      display: inline-block;
    }

    > .h3 {
      margin-top: 0;
    }
  }

  .product-body > .media-body {
    padding-left: 0;
  }

  .product-info {
    @extend .clearfix;
    margin-bottom: 5px;

    &.address {
      margin-bottom: 0;
    }
  }

  .media-footer {
    padding-top: $line-height-computed;
    overflow: hidden;

    .panel-heading {
      padding: $padding-small-vertical $padding-small-horizontal;
    }

    .panel-title {
      font-size: $font-size-base;
      font-weight: $text-strong-font-weight;
    }
  }

  .address .text-muted {
    font-size: $font-size-small;
    line-height: $line-height-small;
  }

  .icon-map-marker {
    color: $map-marker-color;
    margin-right: 5px;
  }

  .airline-logo {
    width: 40px;
    height: 40px;
  }

  .product-detail {

    .price {
      font-size: 120%;
    }

    .price-strikethrough {
      font-size: 100%;
    }
  }

  .price,
  .price-total,
  .price-average-only {
    font-weight: $product-price-font-weight;
  }
  .price-strikethrough {
    font-weight: $product-strikethrough-font-weight;
  }

  .price-total,
  .price-average-only {
    color: $product-price-total-color;
    font-family: $price-font-family;

    &.price-strikethrough {
      font-weight: $product-strikethrough-font-weight;
    }
  }

  .price-container {
    border-left: 1px solid $product-border-color;

    .price-total,
    .price-average-only {
      font-size: $product-price-font-size;
      line-height: $line-height-large;

      &.price-strikethrough {
        font-size: 1em;
      }
    }

    .price-info {
      word-wrap: break-word;
      hyphens: auto;
    }

    .price-info,
    ul {
      margin-bottom: 1em;
    }

    .additional-fees {
      font-size: 13px;
      margin-bottom: 1em;
    }

    .exceptional-value-tag {
      text-decoration: none;
      text-transform: uppercase;
      padding-bottom: 10px;

      i.icon {
        padding-bottom: 3px;
      }
    }
  }

  .price-detail-list { /* Is this class being used anymore? */
    font-size: $font-size-small;
  }

  .price-detail-list .price-avg {
    margin-top: 1em; /* TODO: Make price-container list spacing var */
    padding-bottom: 5px;
    border-bottom: 1px solid $base-border-color;
  }

  #price-total-detail {
    margin-top: 5px;
  }

  .price-detail-list .panel-body {
    background: #eee;
    box-shadow: inset 0 8px 8px -8px rgba(0, 0, 0, .5),
    inset 0 -8px 8px -8px rgba(0, 0, 0, .5);
    margin-left: 1px; /* WHY? */
  }

  .price-primary {
    color: $product-primary-price-value-color !important;
    font-size: $product-primary-price-value-font-size !important;
    font-weight: $product-primary-price-value-font-weight !important;
    line-height: $line-height-xs !important;

    .price-primary-label {
      color: $product-primary-price-label-color !important;
      font-size: $product-primary-price-label-font-size !important;
      font-weight: $product-primary-price-label-font-weight !important;
    }

    .or {
      color: $product-primary-price-value-color !important;
    }
  }

  .price-secondary {
    color: $product-secondary-price-color !important;
    font-size: $product-secondary-price-font-size !important;
    font-weight: $product-secondary-price-font-weight !important;
    line-height: $line-height-xs !important;

    .or {
      color: $product-secondary-price-color !important;
    }
  }

  .product-supplier {
    color: $text-muted;
    font-size: $font-size-small;
    margin-top: .5em;
  }

  .product-debug {

    .textarea {
      height: 374px;
    }

  }

  .product-promo-container {
    margin-top: $padding-large-vertical;
    padding-top: $padding-large-vertical;

    .product-promo-heading {
      text-transform: $product-promo-text-transform;
      font-weight: $text-strong-font-weight;
    }

    ul {
      margin: 5px 0;
    }

  }

  .banner-tag-container {
    border-top: $product-promo-border;
    border-bottom: $product-promo-border;
    padding: $padding-large-vertical*2 0;
    margin: $padding-large-vertical 0 $padding-large-vertical*2;

    img {
      width: 48px;
      height: 48px;
    }
  }

  // DEALS and PROMOTIONS
  .product-deal,
  .product-featured {
    padding: $product-deal-padding-vertical $product-deal-padding-horizontal;
    background-color: $product-deal-bg-color;
    color: $product-deal-text-color;
  }

  .product-deal-container {
    .product-deal { //exclusive deals slanted flag
      border-radius: $product-deal-border-radius;
      @if $product-deal-content == 'none' {
        padding-right: $product-deal-padding-horizontal;
      } @else {
        padding-right: 0;
      }
    }
  }

  .product-featured,
  .special-program,
  .product-featured-deal {
    padding: $padding-large-vertical $padding-large-horizontal;
  }

  &.product-featured {
    .product-body {
      border-color: $product-featured-border-color;

      @if ($product-body-border-radius != 0) { //in case products have rounded corners this makes banners sit better on top
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    h2 {
      @extend .col-padding;
      background: $product-featured-bg-color;
      color: $product-featured-text-color;
      padding: $product-featured-header-padding;
      font-size: $product-featured-header-size;

      @if ($product-body-border-radius != 0) { //in case products have rounded corners, round the banner
        border-top-left-radius: $product-body-border-radius;
        border-top-right-radius: $product-body-border-radius;
      }
    }
  }

  .product-deal {
    font-weight: $product-deal-font-weight;
    border-top-left-radius: $product-image-border-radius;
    border-top-right-radius: $product-image-border-radius;

    &.rate-deal {
      background: $rate-deal-bg-color;
      color: $rate-deal-text-color;
      margin: $rate-deal-margins;
      border-radius: $rate-deal-border-radius;
      text-align: left;
      position: relative;
      display: inline-block;
      @media (max-width: $screen-xs) {
        margin-left: $rate-deal-margin-left-xs;
      }

      &:before {
        content: $rate-deal-content;
        border-left: $rate-deal-border-left;
        position: $rate-deal-position;
        bottom: $rate-deal-bottom-position;
        left: 0;
        border-top: $rate-deal-border-top;
      }
    }

    + img {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .col-interactive {
    border-left: 1px solid $base-border-color;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background: $product-col-interactive-background-color;
    color: $product-col-interactive-text-color;

    &.selected {
      background: $product-col-interactive-selected-background-color;
      color: $product-col-interactive-selected-text-color;
    }
  }

  .product-details-list {

    .component-section-header {
      @include component-h3;
      margin: 0 -8px;
      padding-bottom: 10px;
    }

    .component-section-body > ul > div > {
      // This div selector is for Ember
      li {
        overflow: hidden;
        border-bottom: 1px solid $base-border-color;

        > span {
          padding: 10px 8px;
        }
      }
    }

    .component-section-footer {
      padding-bottom: 10px;
    }

    ul[id|=more] {
      margin-top: -10px; // Offsets for more room types ul:first-of-type bottom margin
    }

    .price-info div {
      border-bottom: 0;

      .or:after {
        content: "";
        display: block;
      }

    }

    .component-body {
      margin: 0;

      &.row {
        margin: 0 -8px;
      }
    }

  }

  .component-body {
    margin: 0;
  }

  .component-section-body + .component-section-body {
    border-top: 1px dotted $gray-lighter;
    margin-top: $padding-large-vertical * 1.5;
    padding-top: $padding-large-vertical * 1.5;
    border-bottom: 1px dotted $gray-lighter;
    padding-bottom: $padding-large-vertical * 1.5;
  }

  .product-details-components-container {
    margin: $line-height-computed/2 0;
    padding: $line-height-computed/2 0;
    border-top: 1px solid $almost-white;
    background-color: $product-detail-drawer-bg-color;

    > div .product-details-component:after {
      margin: $line-height-computed/2 0 $line-height-computed/2 17%;
    }
  }

  .product-note {
    .hr-text {
      @include hrText($product-detail-drawer-bg-color, currentColor);
    }
  }

  .product-additional-options-container {
    background-color: $product-additional-options-drawer-bg-color;
    color: dynamicColor($product-additional-options-drawer-bg-color);
    display: inline-block;
    width: 100%;
    border-radius: $product-body-border-radius;

    .product-additional-options-panel {
      @extend .panel;
      margin: $padding-large-vertical $padding-large-horizontal;

      .product-additional-options-panel-footer {
        border-top: 1px solid $base-border-color;
        padding: $padding-large-vertical $padding-small-horizontal;
      }

      .product-additional-options-panel-title {
        font-size: $product-additional-options-title-font-size;
      }
    }
  }

  .loyalty-deal-message-container {
    .loyalty-deal-message {
      margin-top: 0;
    }
  }

  .product-air-journey {
    > * + * {
      margin-left: 1em;
    }

    .journey-logo img {
      max-width: 36px;
    }

    .journey-datetime {
      flex: 1;
      &:first-of-type {
        margin-left: auto;
      }
    }
  }

  @media (max-width: $screen-sm) {
    .product-air-journey .text-large {
      font-size: 100%;
      text-align: right;
    }
  }

  // Journey map (flight card visual representation of num stops and layovers)
  .journey-map {
    margin-top: 1em;
    flex: 1 1 100px;

    @media (max-width: $screen-xs) {
      flex: 0;
    }

    .timeline {
      background: #ccc;
      height: 2px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 10px;
    }

    .stop {
      height: 10px;
      width: 10px;
      border: 1px solid #ccc;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 0 0 3px #fff; // Assumes a white background - need mixin if we have to pass in background color
    }
  }

  .leg-active .journey-map .stop {
    box-shadow: 0 0 0 3px $product-leg-bg-color-active;
  }

  .baggage-included {
    color: $baggage-info-included-icon-color;
  }

  .baggage-not-included {
    color: $baggage-info-not-included-icon-color;
  }
}

.aside-contents-container .product-rating > li {
  @extend .p;
}

.product-deal-container {
  position: relative;
  overflow: hidden;
  display: inline-block;

  .product-deal {
    text-align: left;
    position: relative;
    margin-right: 25px;
    font-size: $product-deal-font-size_base;

    &:after {
      content: $product-deal-content;
      float: right;
      position: absolute;
      top: 0;
      right: -15px;
      border-top: 50px solid;
      border-right: 15px solid transparent;
    }

    &.client-approved {
      background: $product-deal-client-approved-bg-color;
      color: $product-deal-client-approved-text-color;

      &:after {
        border-top-color: $product-deal-client-approved-bg-color;
      }
    }

    &.exclusive-deal {
      background: $product-deal-exclusive-bg-color;
      color: $product-deal-exclusive-text-color;

      &:after {
        border-top-color: $product-deal-exclusive-bg-color;
      }
    }

    &.client-exclusive {
      background: $product-deal-client-exclusive-bg-color;
      color: $product-deal-client-exclusive-text-color;

      &:after {
        border-top-color: $product-deal-client-exclusive-bg-color;
      }
    }

    &.eligible-for-hotel-sale {
      background: $product-deal-hotel-sale-bg-color;
      color: $product-deal-hotel-sale-text-color;

      &:after {
        border-top-color: $product-deal-hotel-sale-bg-color;
      }
    }

    &.members-only-rate {
      background: $product-deal-members-only-rate-bg-color;
      color: $product-deal-members-only-rate-text-color;

      &:after {
        border-top-color: $product-deal-members-only-rate-bg-color;
      }
    }

    &.loyalty-deal {
      background: $loyalty-deal-bg-color;
      color: $loyalty-deal-text-color;

      &:after {
        border-top-color: $loyalty-deal-bg-color;
      }
    }
  }
}

.product-deal {
  &.special-program {
    background-color: $product-deal-special-program-bg-color;
    color: $product-deal-special-program-text-color;
    text-align: $product-deal-special-program-text-align;
  }
}

.special-program { //e.g. fhr
  border-radius: $product-deal-special-program-border-radius;
  height: $product-deal-special-program-banner-height;

  //sets colors for elements inside product descriptions (e.g. fhr/thc)
  + .product-body {
    border: $product-deal-special-program-body-border;

    //in case products have rounded corners this makes banners sit better on top
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    //disables product title href when not logged in
    .is-locked & {
      .media-link {
        pointer-events: none;
        text-decoration: none;
      }
    }

    .btn-link,
    .media-link {
      text-decoration: $product-deal-special-program-link-decoration;
    }

    .price-container {
      background-color: $product-deal-special-program-price-container-background-color;
    }
    .col-sm-9,
    .col-sm-9 div:not(.tag),
    .col-sm-9 h3,
    .product-supplier {
      color: $product-deal-special-program-body-text-color;
    }

    .col-sm-9 button {
      color: $product-deal-special-program-image-button-text-color;
    }

    .col-sm-9 {
      background-color: $product-deal-special-program-body-bg-color;

      //important to override app-global
      .rating .star::before {
        color: $product-deal-special-program-star-rating-color !important;
      }
    }
  }
}

.special-program-thc { //refactor to a reusable name, e.g. special-program-alternate
  + .product-body {
    border: $product-deal-special-program-thc-body-border;

    .price-container {
      background-color: $product-deal-special-program-thc-price-container-background-color;
    }
    .col-sm-9,
    .col-sm-9 div:not(.tag) {
      color: $product-deal-special-program-thc-body-text-color;
    }

    .col-sm-9 h3,
    .col-sm-9 .media-link,
    .col-sm-9 button:not(.image-btn) {
      color: $product-deal-special-program-thc-body-accent-color;
    }

    .col-sm-9 {
      background-color: $product-deal-special-program-thc-body-bg-color;

      //important to override app-global
      .rating .star::before {
        color: $product-deal-special-program-thc-star-rating-color !important;
      }

      .btn-link,
      .media-link {
        text-decoration: $product-deal-special-program-thc-link-decoration;
      }
    }
  }
}

.products-cars {
  .media-object {
    width: 80%;
    margin: 0 auto;
  }

  .supplier-logo {
    display: block;
    margin: 0 auto $line-height-computed auto;
    width: 70px;
  }

  .media-heading small {
    display: block;
    font-weight: normal;
  }
}

#hotel-user-reviews li {
  border-bottom: 1px solid $product-border-color;
  padding-bottom: 1em;
  margin-bottom: calc(1em + (#{$line-height-computed} / 2)) !important;
}

.component-footer a[data-toggle=collapse]:not(.collapsed) .icon:before {
  @include transform(rotate(90deg));
}

.products-list {

  .product-selected .price-container {
    //overrides $product-price-container-bg-color if it has a bg color
    background-color: $product-selected-price-container-bg-color;
    border-radius: 0 $product-body-border-radius $product-body-border-radius 0;
  }

  .price-container {
    min-height: 1px; //must set a height for flex-grow to work
    background-color: $product-price-container-bg-color;
  }

  .flex-container {
    height: 100%;

    .exceptional-value {
      text-decoration: none;
      text-transform: uppercase;

      i.icon {
        padding-bottom: 3px;
      }
    }
  }
}

.product-deal {
  &.included-flag {
    background: $product-deal-included-flag-background-color;
    color: $product-deal-included-flag-text-color;
    width: 100%;
    text-align: center;
  }
}

.product-image-placeholder {
  background-color: $gray-lighter;
  height: 100px;
  width: 100%;
}

.product-notices {
  padding-top: 15px;

  > * + * {
    margin-top: 15px;
  }

  .product-notice {
    display: flex;
    align-items: flex-start;

    > span {
      flex: 1 1 auto;
      text-align: left;
      align-self: center;
    }
  }
}

.product-unit {
  > img {
    border-radius: $product-thumbnail-hotel-border-radius;
    width: 100%;
  }
}

.product-thumbnail-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  height: 100%;
  max-height: $product-thumbnail-hotel-card-max-height;

  > img {
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: $product-thumbnail-hotel-border-radius;
  }

  .image-btn {
    height: 100%;
    min-height: 100px;
    max-height: $product-thumbnail-hotel-card-max-height;
    overflow: hidden;

    > img {
      object-fit: cover;
      height: 100%;
    }
  }
}

.page-hotel-detail .product-thumbnail-container .image-btn {
  max-height: 175px;
}

.itinerary-container .product-thumbnail-container {
  max-height: initial; // unset max-height from product-thumbnail-container elsewhere
  height: 175px;
}

.included-product-message {
  color: $brand-success;
  font-weight: $text-strong-font-weight;
}

// Grouped Products

#grouped-products {

  .main-contents-container .section-body {
    padding: 30px 0 0;
  }

  .product-body {
    @include box-shadow(none);
  }

  .product-group-header {
    align-items: stretch;
    min-height: 5em;
  }

  .product-group-header-cell {
    text-align: center;
    font-size: $font-size-large;
    padding: 1em;
    margin-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.product-group_0 {
      background: $product-group_0--feature-color;
      color: dynamicColor($product-group_0--feature-color);
    }

    &.product-group_1 {
      background: $product-group_1--feature-color;
      color: dynamicColor($product-group_1--feature-color);
    }

    &.product-group_2 {
      background: $product-group_2--feature-color;
      color: dynamicColor($product-group_2--feature-color);
    }

    &.product-group_3 {
      background: $product-group_3--feature-color;
      color: dynamicColor($product-group_3--feature-color);
    }

    &.product-group_4 {
      background: $product-group_4--feature-color;
      color: dynamicColor($product-group_4--feature-color);
    }
  }

  .products-grouped-flights {

    .product + .product {
      border-top: 1px solid $base-border-color;
      margin-top: 2.25em;
      padding-top: 1.5em;
    }

    .product-body {
      border: 0;
    }

    .product-group-details-container .tab-pane.active {
      padding: 30px 20px 40px 20px;
      background: $product-group-fare-cell-active-background-color;
      border: $product-group-fare-cell-border-width solid $product-group-fare-cell-border-color;
    }

    .product-group-details-container .product-group-pane-selected.tab-pane.active {
      padding-top: 60px;
    }
  }

  .product {
    &:not(.product-selected) {
      margin-top: 1.5em;
    }

    &.selected {
      margin-top: 3em;
    }

    .product-summary-container {
      @extend .flex-1;

      > div {
        margin: 1.5em;
      }

      .icon-flight-direction-container {
        margin: 0 10px;
      }

      &.flight-details-open {
        background: $product-summary-active-background-color;

        a,
        .btn-link {
          color: $product-summary-link-color;

          &:hover,
          &:focus {
            color: $product-summary-link-color-hover;
          }

          &[disabled],
          fieldset[disabled] & {
            &:hover,
            &:focus {
              color: $product-summary-link-disabled-color;
            }
          }
        }
      }
    }

    .product-logo {
      height: 35px;
    }

    .product-drawer-content {
      background: #fff;
      padding: 10px 50px 50px;

      .product-drawer-group {
        margin-top: 30px;
      }

      .product-flight-connection {
        border: 1px dashed currentColor;
        border-width: 1px 0;
        padding-top: $padding-large-horizontal;
        padding-bottom: $padding-large-horizontal;
      }
    }

    .product-details-container {
      background: $product-details-background-color;
      padding: $padding-large-horizontal;
    }

    .product-groups-container {
      @extend .flex-2;

      .product-groups,
      .nav-cards {
        height: 100%;
      }

      // Tabs
      .nav-cards > li {
        border: $product-group-fare-cell-border-width solid $product-group-fare-cell-border-color;
        text-align: left;
        margin-left: $padding-large-horizontal;

        > a {
          display: block;
          height: 100%;
          width: 100%;
          padding: 30px 1em 2em;
          background: $product-group-fare-cell-background-color;
          color: dynamicColor($product-group-fare-cell-background-color);

          &:hover, &:focus {
            background: $almost-white;

            &:before {
              font-family: switchflyShopping;
              content: "\e952";
              font-size: 1.5em;
              font-weight: $text-strong-font-weight;
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              text-align: center;
            }
          }

          .price {
            margin: 0;
            color: $product-group-fare-cell-price-color;
          }
        }

        &.disabled > a {
          &:hover, &:focus {
            background: none;

            &:before {
              content: "";
            }
          }

          background: $product-group-fare-cell-background-color !important;
          color: dynamicColor($product-group-fare-cell-background-color) !important;
        }

        &.active {
          margin-bottom: -1px;
          border-bottom: 0;

          > a {
            background: $product-group-fare-cell-active-background-color;
            color: dynamicColor(
                            $product-group-fare-cell-active-background-color
            );

            &:before {
              font-family: switchflyShopping;
              content: "\e952";
              font-size: 1.5em;
              font-weight: $text-strong-font-weight;
              display: block;
              position: absolute;
              bottom: .75em;
              left: 0;
              width: 100%;
              text-align: center;
            }

            .price {
              color: $product-group-fare-cell-active-price-color;
            }

          }
        }

        &.product-group-selected {
          border: 3px solid $brand-success;

          .product-group-indicator {
            background: $brand-success !important;
            color: dynamicColor($brand-success);
            height: 2em;
            line-height: 2em;
            padding: 0 5px;
            text-align: center;
            top: -2em;
            left: -3px;
            width: calc(100% + 6px);
          }

          &.active {
            border: $product-group-fare-cell-border-width solid $product-group-fare-cell-border-color;
            border-bottom: 0;

            .product-group-indicator {
              width: calc(100% + 2px);
              left: -1px;
            }
          }
        }

        .product-group-summary > div:first-of-type {
          min-height: 3em;
        }

        .product-group-indicator {
          position: absolute;
          top: -1px;
          left: -1px;
          height: 6px;
          width: calc(100% + 2px);
        }

        &.product-group_0 .product-group-indicator {
          background: $product-group_0--feature-color;
        }

        &.product-group_1 .product-group-indicator {
          background: $product-group_1--feature-color;
        }

        &.product-group_2 .product-group-indicator {
          background: $product-group_2--feature-color;
        }

        &.product-group_3 .product-group-indicator {
          background: $product-group_3--feature-color;
        }

        &.product-group_4 .product-group-indicator {
          background: $product-group_4--feature-color;
        }
      }

    }

    .product-groups-container,
    .product-group-details-container {
      .price-info {
        margin-bottom: $padding-large-vertical;

        .price {
          font-size: $product-group-details-price-font-size;
        }
      }
    }
  }
}

.product-multi-leg-row {
  @extend .product-padding;
  display: flex;
  flex-wrap: nowrap;

  > .product-air-journey-container {
    flex-grow: 1;
  }
}

.hotel-images > .section-body {
  padding: 0;
  min-height: 400px; // Fixes a bug when carousel takes too long to load images and messes up with scrollToPosition animation.
}

.page-hotel-detail .main-contents-container {
  .section-body {
    padding: $hotel-detail-section-body-padding;

    //Product benefits on hotel details (e.g. Program hotel benefits, FHR, THC)
    &.product-benefits {
      background-color: $product-benefits-background-color;
      color: $product-deal-special-program-body-text-color;
      padding: $padding-small-vertical * 3;

      .btn-link {
        text-decoration: $product-benefits-info-link-decoration;
      }

      .info-link {
        text-align: $product-benefits-info-link-alignment;
      }

      .special-amenities {
        ul {
          column-count: 2;
          column-gap: 30px;
          padding-left: 0;

          li {
            display: inline-flex;
            width: 100%;
            margin-bottom: 12px;
            margin-left: 0;

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }

      div,
      button {
        color: $product-deal-special-program-body-text-color;
      }
    }
  }

  .trip-advisor-icon {
    padding: 2px;
  }
}

//Don't do this!
.selectedRoomTotalFees {
  text-align: right;

  .totalFees > div {
    display: inline;
  }
}