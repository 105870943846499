// Target IE8 and older
// --------------------------------------------------
.lt-ie9 {

  // Shim for browsers that do not support media queries. Defines static page layout.
  .col-sm-3 {
    width: 25%;
    float: left;
  }

  .col-sm-4 {
    width: 33.33%;
    float: left;
  }

  .col-sm-5 {
    width: 41.67%;
    float: left;
  }

  .col-sm-6 {
    width: 50%;
    float: left;
  }

  .col-sm-8 {
    width: 66.67%;
    float: left;
  }

  .col-sm-9 {
    width: 75%;
    float: left;
  }

  .col-sm-11 {
    width: 91.67%;
    float: left;
  }

  .col-sm-12 {
    width: 100%;
  }

}

// Targets xs-handheld (350px width) and below (also in app-global.handheld.scss)
// --------------------------------------------------
@media (max-width: 350px) {

  .input-daterange > [class*=col] {
    width: 100%;
    // Makes date + time inputs single line, full width
  }
}

// Targets handheld (480px width) and below
// --------------------------------------------------
@media (max-width: $screen-xs) {

  .hidden-phone {
    display: none;
  }

  #site-header .container {

    .navbar .logo-primary {
      background-size: 80%;
    }
  }

  .dashcart-header > p > span {
    display: none;
  }

  .dashcart-header a > .icon {
    display: inline-block !important;
  }

  .MicrosoftMap .product-mini {
    width: 300px;
  }

  .container-md {
    padding: $page-area-padded-sm;
  }
}

// Targets larger tablets (e.g. Nexus 10) (800px width)
// --------------------------------------------------
@media (max-width: 800px) {

  .financial .table-container > .col-sm-5,
  .financial .table-container > .col-sm-6 {
    width: 100%;
    padding-left: 0;
  }
  .financial .table-container > .col-sm-6 {

    .or {
      left: 50% !important;
      top: -15px;
      // Positions horizontally between 2 tables, which are now full width
    }
  }
}

@media (min-width: 0px) {
  .container-fixed-lg {
    max-width: $screen-lg;
    width: $screen-lg;
  }
}

// Targets tablet (768px width) and BELOW (should include handheld)
// --------------------------------------------------
@media (max-width: $screen-sm) {

  // Need vertical overflow to be visible for the filter slide out tp:241072
  .page-content > .page-body {
    overflow-y: visible;
  }

  .hidden-tablet-down {
    display: none;
  }

  .visible-tablet-block {
    display: block;
  }

  .visible-tablet-inline-block {
    display: inline-block;
  }

  #shopping-page-container.page-content {
    padding-left: 8px;
    padding-right: 8px;

    .page-search-form & {
      padding-left: 0;
      padding-right: 0;
    }
  }

  #site-header .container {
    padding: inherit;
  }

  .btn {
    white-space: normal;
  }

  .credit-cards-image {
    margin-bottom: 15px;
    background-size: inherit;
  }

  .page-content {
    position: relative; // Sets position context for itinerary
    padding: 0; // Removes container padding
  }

  .page-body .aside-contents-container {
    position: absolute;
    top: 0;
    z-index: 1000;
    visibility: hidden;
    width: 50%;
    @include transition(all 0.5s);
    @include transform(translate3d(200%, 0, 0));
    padding: 0;

    section:last-of-type {
      margin-bottom: 0;
    }
  }

  .page-body .toggle-container-show {
    visibility: visible;
    @include transform(translate3d(100%, 0, 0));
    @include box-shadow(-5px 1px 5px rgba(0, 0, 0, .2));
  }

  .aside-contents-container {
    padding: 0 !important;

    section {
      margin-bottom: 0;
    }
  }

  #checkout-form-container {
    padding: $checkout-form-container-padding_tablet;
  }

  #booking-form .section-body [class*=col-] {
    width: 100%;
    display: block;
  }

  #dashcart > .container {
    padding: 0;
  }

  .dashcart-header {
    padding-left: 0;
    padding-right: 0;
  }

  .product-body > .no-vertical-padding {
    padding: 0; // Fixes exclusive deal spacing
  }

  .product-details-list .col-interactive {
    width: 10%;
  }

  .page-body-vh {
    min-height: 100vh;
  }

}

// Targets tablet (767px width) and up
// --------------------------------------------------
@media (min-width: $screen-sm) {
  .hidden-tablet-up {
    display: none !important;
  }
}

// Targets above tablet
// --------------------------------------------------
@media (min-width: $screen-sm) and (max-width: $screen-md) {

  .credit-cards-image {
    margin-bottom: 15px;
    background-size: inherit;
  }

  .container {
    max-width: 100%;
  }
}

// Targets tablet and above (> 480px)
// --------------------------------------------------
@media (min-width: $screen-xs) {
  .hidden-desktop {
    display: none !important;
  }
}

// Targets above tablet (768px), but smaller than 1200px
// --------------------------------------------------
@media (min-width: $screen-sm + 1) and (max-width: $screen-lg) {

  .financial .table-container > .col-sm-6 .or {
    left: -3.5%;
  }
}

// Targets large (1200px wide) screens and up
// --------------------------------------------------
@media (min-width: $screen-lg) {

  .financial .table-container > .col-sm-6 .or {
    left: -11%;
  }
}

// Container Width Medium
// Used in place of the bootstrap "container" when we want a narrower page width
// --------------------------------------------------

.container-md {
  @include container-fixed;
  max-width: $container-tablet;
}