.media {

  &:first-of-type {
    margin-top: 0;
  }

  .media-object {
    width: 100%;
    height: auto;
  }

  .media-link {
    color: $media-heading-link-color;
  }

  .media-heading {
    font-weight: $media-heading-font-weight;
    font-size: $font-size-large;
    line-height: $line-height-large;
  }

}

.media-list > div {
  // Fix for ember div
  margin-bottom: 15px;
}

.case-handheld {
  #air-results-header {
    small {
      display: block;
    }
  }
}