.iti {
  width: 100%;

  .iti__country-list .iti__country .iti__dial-code {
    color: $text-color;
    font-weight: $text-strong-font-weight;
  }

  .iti__selected-flag {
    height: $input-height-base;
  }

  .iti__flag-container {
    top: inherit;
    bottom: $input-height-base / 2; // Mirrors form-control margin-bottom from app-global.base.scss
  }

  input,
  input[type=text],
  input[type=tel] {
    margin-top: 3px !important; // matches with app-global.base standard input offset from label text
  }
}

.iti__flag {
  background-image: url("/resources/shopping/images/application/intl-tel-input/flags.png");
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
       only screen and (min--moz-device-pixel-ratio: 2),
       only screen and (-o-min-device-pixel-ratio: 2 / 1),
       only screen and (min-device-pixel-ratio: 2),
       only screen and (min-resolution: 192dpi),
       only screen and (min-resolution: 2dppx) {
  .iti__flag {
      background-image: url("/resources/shopping/images/application/intl-tel-input/flags@2x.png")
  }
}
