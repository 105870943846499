@mixin themed-border($top-color, $top-width, $right-color, $right-width, $left-color, $left-width) {
  border-top: $top-width solid $top-color;
  border-right: $right-width solid $right-color;
  border-left: $left-width solid $left-color;
}

.leg-based-air-flight-matrix {
  margin: 0 auto 1em auto;
  display: flex;
  flex-flow: row;
  width: 100%;

  &.loading-state {
    opacity: 0;
  }

  .prev-button, .next-button {
    width: 40px;
    background-color: $three-day-calendar-arrow-btn-bg-color;
    padding: 0;
    border-radius: 0;

    @include themed-border(
      $three-day-calendar-arrow-btn-border-top-color,
      $three-day-calendar-arrow-btn-border-top-width,
      $three-day-calendar-arrow-btn-border-right-color,
      $three-day-calendar-arrow-btn-border-right-width,
      $three-day-calendar-arrow-btn-border-left-color,
      $three-day-calendar-arrow-btn-border-left-width
    );

    .icon {
      color: $three-day-calendar-arrow-btn-icon-color;
      font-weight: 600;
      font-size: 24px;
    }

    &:hover:not(:disabled) {
      background-color: $three-day-calendar-arrow-btn-bg-color-hover;
      border-color: $three-day-calendar-arrow-btn-border-color-hover;

      .icon {
        color: $three-day-calendar-arrow-btn-icon-color-hover;
      }
    }

    &:disabled {
      cursor: not-allowed;
      .icon {
        color: $gray-light;
      }
    }
  }

  .calendar-dates-wrapper {
    width: calc(100% - 80px);
    overflow: hidden;

    &.scrollable {
      overflow-x: scroll;
    }

    &.scrollable::-webkit-scrollbar {
      display:none;
    }

    &.no-button {
      width: 100%;
    }

    &::after {
      border-bottom: $three-day-calendar-lpg-cell-border-top-width-selected solid $three-day-calendar-lpg-cell-border-top-color-selected;
      content: "";
      display: block;
    }
  }

  ul.calendar-dates {
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
    flex-flow: row;
    transition: all 0.3s ease-in-out;

    li.calendar-date {
      background-color: $three-day-calendar-lpg-cell-bg-color;
      text-align: center;
      cursor: pointer;
      padding: 15px 15px 20px 15px;
      position: relative;

      button {
        background: none;
        border: none;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      @include themed-border(
          $three-day-calendar-lpg-cell-border-top-color,
          $three-day-calendar-lpg-cell-border-top-width,
          $three-day-calendar-lpg-cell-border-right-color,
          $three-day-calendar-lpg-cell-border-right-width,
          $three-day-calendar-lpg-cell-border-left-color,
          $three-day-calendar-lpg-cell-border-left-width
      );

      .date {
        color: $three-day-calendar-lpg-cell-date-text-color;
        font-weight: 400;
        font-size: $font-size-base;
      }

      .price {
        color: $three-day-calendar-lpg-cell-price-text-color;
        font-size: $three-day-calendar-lpg-cell-price-text-size;
      }

      .from-label {
        color: $gray;
        font-size: $font-size-small;
        font-weight: 400;
      }

      .not-available-label {
        color: $gray;
        font-size: $font-size-base;
        font-weight: 400;
      }

      &:hover:not(.no-flight-available):not(.selected) {

        background-color: $three-day-calendar-lpg-cell-bg-color-hover;
        border-color: $three-day-calendar-lpg-cell-border-color-hover;
        text-decoration: underline;

        .date, .price, .from-label {
          color: $three-day-calendar-lpg-cell-text-color-hover;
        }
      }

      &.selected {
        background-color: $three-day-calendar-lpg-cell-bg-color-selected;
        border-top: none !important; /* top border is simulated by using an overlay to avoid a UI glitch */
        position: relative;
        bottom: -$three-day-calendar-lpg-cell-border-top-width-selected;
        margin-top: -$three-day-calendar-lpg-cell-border-top-width-selected;

        @include themed-border(
          $three-day-calendar-lpg-cell-border-top-color-selected,
          $three-day-calendar-lpg-cell-border-top-width-selected,
          $three-day-calendar-lpg-cell-border-right-color-selected,
          $three-day-calendar-lpg-cell-border-right-width-selected,
          $three-day-calendar-lpg-cell-border-left-color-selected,
          $three-day-calendar-lpg-cell-border-left-width-selected
        );

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          background-color: $three-day-calendar-lpg-cell-border-top-color-selected;
          height: $three-day-calendar-lpg-cell-border-top-width-selected;
        }

        .date, .price, .from-label {
          color: $three-day-calendar-lpg-cell-text-color-selected;
        }
      }

      &.no-flight-available {
        cursor: not-allowed;

        button {
          cursor: not-allowed;
        }

        .date {
          color: $gray;
        }
      }

      &.no-flight-available-confirmed {
        background-color: $gray-light;

        .date, .not-available-label {
          color: $gray-dark;
          font-size: $font-size-base;
        }

      }
    }
  }
}

.case-handheld {
  .leg-based-air-flight-matrix {
    .calendar-dates-wrapper::after {
      border-bottom: $three-day-calendar-lpg-cell-border-bottom-width_handheld solid $three-day-calendar-lpg-cell-border-bottom-color_handheld;
    }

    ul.calendar-dates {
      li.calendar-date {
        @include themed-border(
            $three-day-calendar-lpg-cell-border-top-color_handheld,
            $three-day-calendar-lpg-cell-border-top-width_handheld,
            $three-day-calendar-lpg-cell-border-right-color_handheld,
            $three-day-calendar-lpg-cell-border-right-width_handheld,
            $three-day-calendar-lpg-cell-border-left-color_handheld,
            $three-day-calendar-lpg-cell-border-left-width_handheld
        );

        &.selected {
          @include themed-border(
            $three-day-calendar-lpg-cell-border-top-color-selected_handheld,
            $three-day-calendar-lpg-cell-border-top-width-selected_handheld,
            $three-day-calendar-lpg-cell-border-right-color-selected_handheld,
            $three-day-calendar-lpg-cell-border-right-width-selected_handheld,
            $three-day-calendar-lpg-cell-border-left-color-selected_handheld,
            $three-day-calendar-lpg-cell-border-left-width-selected_handheld
          );
        }
      }
    }
  }
}