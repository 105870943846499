// TODO: As part of bug 93911, this file should be removed once we're using Bootstrap's modal

#CentinelGeneratedMask {
  z-index: 1000;
  top: 0;
  position: absolute;
  background-color: rgba(204, 204, 204, 0.7) !important;
}

#CentinelGeneratedDialog {
  position: absolute;
  z-index: 1001;
  bottom: 0;
  background: #fff;
  width: 400px;
  height: 400px;
}