/*
 * Use caution and make sure that you import this as namespaced to your part of the app.
 * See _products.scss as an example.
*/

.panel {
  box-shadow: none !important;
}

.panel-drawer {
  @extend %panel-toggles;
  @include drawer-panel-style();
}

.panel-drawer-alt {
  @extend %panel-toggles;
  @include drawer-panel-style(0, none, 0, 0, 0 $padding-common-md 0 0, transparent);

  .icon-collapsible--closed:before {
    content: $icon-collapsible--opened;
  }

  .icon-collapsible--opened:before {
    transform: rotate(180deg);
  }
}

%product-selected {
  border: 3px solid $product-border-color-selected_handheld;
}

%panel-toggles {
  border: 0;

  [data-toggle] {
    .toggle-closed {
      display: none;
    }

    .toggle-opened {
      display: block;
    }

    &.collapsed {
      .toggle-closed {
        display: block;
      }

      .toggle-opened {
        display: none;
      }
    }
  }
}

/* MULTIROOM STYLES */
#multi-room-options {

  .section-body {
    padding-top: 0;
  }

  h3 {
    margin: $padding-large-vertical*2 0 0 0;
  }

  h4 {
    padding: 0 0 $padding-large-vertical 0;
  }

  .component-body {
    margin: 0;
  }

  .toolbar {
    background: transparent;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .panel-body {
    background: $panel-drawer-body-bg-color;
  }

  .panel-header {
    background: $panel-drawer-header-bg-color;

    &:hover,
    &:focus {
      background: $panel-drawer-header-hover-bg-color;
    }
  }

  .selected-products-list {

    .panel-body {
      @extend %product-selected;
      border-top: 0;
    }

    h4 {
      padding-top: 0;
    }

    .product-panel {
      border-top: 0;
    }
  }
}

// HANDHELD STYLES
.case-handheld {
  .products-rooms { //must specify rooms only
    .product-selected {
      @extend %product-selected;
    }

    .room-selected .product-selected {
      border: 1px solid $base-border-color;
    }
  }

  #multi-room-options {

    background: $almost-white !important;

    h3 {
      margin: $h3-margin-vertical 0 $h4-margin-vertical;
    }

    h3,
    h4 {
      padding-left: $padding-large-horizontal;
      padding-right: $padding-large-horizontal;
    }

    h4 {
      padding-bottom: 0;
    }

    .section-body,
    .products-list {
      padding: 0;
      background: transparent;
    }

    .panel {
      margin-bottom: 0;
    }

    .panel-body,
    .panel-header {
      border-radius: 0;
    }

    .panel-header {
      background: $product-body-bg-color;
    }

    .panel-body {
      padding-left: $padding-small-horizontal;
      padding-right: $padding-small-horizontal;
    }

    .toolbar {
      padding-top: 0;
    }

    .product-selected {
      .product-body {
        padding: $padding-small-horizontal $padding-large-horizontal;
      }

      .icon-product__selected {
        padding-right: 15px;
      }
    }

    .component-body > div:not(:first-of-type) .panel-drawer > button {
      border-top: 0; // Remove double border issue with grouped room drawers
    }

    .panel-drawer {
      margin-left: -$padding-small-horizontal;
      margin-right: -$padding-small-horizontal;
    }

    .products-list.products-rooms {
      li.product.room-selected {
        @extend %product-selected;
      }
    }

    .more-room-types,
    button[id^="toggleMoreRoomTypesId"] {
      margin-top: 15px;
    }
  }
}