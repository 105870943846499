//
// Mixins
// --------------------------------------------------

// Utilities
// -------------------------

// Clearfix
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
@mixin clearfix() {
  &:before,
  &:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }
  &:after {
    clear: both;
  }
}

// Webkit-style focus
@mixin tab-focus($outlineColor: #0634C6) {
// Default
  outline: thin dotted #333;
// Webkit
  outline: 2px solid $outlineColor;
  outline-offset: -2px;
}

// Center-align a block level element
@mixin center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Sizing shortcuts
@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin square($size) {
  @include size($size, $size);
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
  &:-moz-placeholder {
    color: $color;
  }
// Firefox 4-18
  &::-moz-placeholder {
    color: $color;
  }
// Firefox 19+
  &:-ms-input-placeholder {
    color: $color;
  }
// Internet- Explorer 10+
  &::-webkit-input-placeholder {
    color: $color;
  }
// Safari and- Chrome
}

// Text overflow
// Requires inline-block or block for proper styling
@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// CSS image replacement
//
// Heads up! v3 launched with with only `@mixin hide-text()`, but per our pattern for
// mixins being reused as classes with the same name, this doesn't hold up. As
// of v3.0.0 we have added `.text-hide()` and deprecated `.hide-text()`. Note
// that we cannot chain the mixins together in Less, so they are repeated.
//
// Source: https://github.com/h5bp/html5-boilerplate/commit/aa0396eae757

// Deprecated as of v3.0.0 (will be removed in v4)
@mixin hide-text() {
  font: unquote("0/0") a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

// New mixin to use as of v3.0.0
@mixin text-hide() {
  font: unquote("0/0") a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

// CSS3 PROPERTIES
// --------------------------------------------------

// Single side border-radius
@mixin border-top-radius($radius) {
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
}

@mixin border-right-radius($radius) {
  border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-left-radius($radius) {
  border-bottom-left-radius: $radius;
  border-top-left-radius: $radius;
}

// Drop shadows
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
// i-o-s <4.3 & Android <4.1
  box-shadow: $shadow;
}

// Transitions
@mixin transition($transition) {
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin transition-property($transition-property) {
  -webkit-transition-property: $transition-property;
  transition-property: $transition-property;
}

@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
  transition-delay: $transition-delay;
}

@mixin transition-duration($transition-duration) {
  -webkit-transition-duration: $transition-duration;
  transition-duration: $transition-duration;
}

@mixin transition-transform($transition) {
  -webkit-transition: -webkit-transform $transition;
  -moz-transition: -moz-transform $transition;
  -o-transition: -o-transform $transition;
  transition: transform $transition;
}

// Transformations
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
// IE9+
  transform: rotate($degrees);
}

@mixin scale($ratio) {
  -webkit-transform: scale($ratio);
  -ms-transform: scale($ratio);
// IE9+
  transform: scale($ratio);
}

@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
// IE9+
  transform: translate($x, $y);
}

@mixin skew($x, $y) {
  -webkit-transform: skew($x, $y);
  -ms-transform: skew-x($x) skew-y($y);
// See https://github.com/twbs/bootstrap/issues-/4885; IE9+
  transform: skew($x, $y);
}

@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden`
// See git pull https://github.com/dannykeane/bootstrap.git backface-visibility for examples
@mixin backface-visibility($visibility) {
  -webkit-backface-visibility: $visibility;
  -moz-backface-visibility: $visibility;
  backface-visibility: $visibility;
}

// Box sizing
@mixin box-sizing($boxmodel) {
  -webkit-box-sizing: $boxmodel;
  -moz-box-sizing: $boxmodel;
  box-sizing: $boxmodel;
}

// User select
// For selecting text on the page
@mixin user-select($select) {
  -webkit-user-select: $select;
  -moz-user-select: $select;
  -ms-user-select: $select;
// IE10+
  -o-user-select: $select;
  user-select: $select;
}

// Resize anything
@mixin resizable($direction) {
  resize: $direction;
// Options: horizontal, vertical, both
  overflow: auto;
// Safari fix
}

// CSS3 Content Columns
@mixin content-columns($column-count, $column-gap: $grid-gutter-width) {
  -webkit-column-count: $column-count;
  -moz-column-count: $column-count;
  column-count: $column-count;
  -webkit-column-gap: $column-gap;
  -moz-column-gap: $column-gap;
  column-gap: $column-gap;
}

// Optional hyphenation
@mixin hyphens($mode: auto) {
  word-wrap: break-word;
  -webkit-hyphens: $mode;
  -moz-hyphens: $mode;
  -ms-hyphens: $mode;
// IE10+
  -o-hyphens: $mode;
  hyphens: $mode;
}

// Opacity
@mixin opacity($opacity) {
  opacity: $opacity;
// IE8 filter
  $opacity-ie: ($opacity * 100);
  filter: unquote("alpha(opacity=#{$opacity-ie})");
}

// GRADIENTS
// --------------------------------------------------

// Horizontal gradient, from left to right
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-gradient(linear, $start-percent top, $end-percent top, from($start-color), to($end-color));
// Safari- 4+, Chrome 2+
  background-image: -webkit-linear-gradient(left, color-stop($start-color $start-percent), color-stop($end-color $end-percent));
// Safari- 5.1+, Chrome 10+
  background-image: -moz-linear-gradient(left, $start-color $start-percent, $end-color $end-percent);
// FF 3.6+
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent);
// Standard, IE10
  background-repeat: repeat-x;
  @include filter-gradient($start-color, $end-color, 1);
// IE9 and down
}

// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-gradient(linear, left $start-percent, left $end-percent, from($start-color), to($end-color));
// Safari- 4+, Chrome 2+
  background-image: -webkit-linear-gradient(top, $start-color, $start-percent, $end-color, $end-percent);
// Safari- 5.1+, Chrome 10+
  background-image: -moz-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);
// FF 3.6+
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);
// Standard, IE10
  background-repeat: repeat-x;
  @include filter-gradient($start-color, $end-color, 0);
// IE9 and down
}

@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
  background-repeat: repeat-x;
  background-image: -webkit-linear-gradient($deg, $start-color, $end-color);
// Safari- 5.1+, Chrome 10+
  background-image: -moz-linear-gradient($deg, $start-color, $end-color);
// FF 3.6+
  background-image: linear-gradient($deg, $start-color, $end-color);
// Standard, IE10
}

@mixin gradient-horizontal-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: -webkit-gradient(left, linear, 0 0, 0 100%, from($start-color), color-stop($color-stop, $mid-color), to($end-color));
  background-image: -webkit-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
  background-image: -moz-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
  background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  @include filter-gradient($start-color, $end-color, 1);
// IE9 and down, gets no color-stop at all for proper fallback
}

@mixin gradient-vertical-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($start-color), color-stop($color-stop, $mid-color), to($end-color));
  background-image: -webkit-linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-image: -moz-linear-gradient(top, $start-color, $mid-color $color-stop, $end-color);
  background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  @include filter-gradient($start-color, $end-color, 0);
// IE9 and down, gets no color-stop at all for proper fallback
}

@mixin gradient-radial($inner-color: #555, $outer-color: #333) {
  background-image: -webkit-gradient(radial, center center, 0, center center, 460, from($inner-color), to($outer-color));
  background-image: -webkit-radial-gradient(circle, $inner-color, $outer-color);
  background-image: -moz-radial-gradient(circle, $inner-color, $outer-color);
  background-image: radial-gradient(circle, $inner-color, $outer-color);
  background-repeat: no-repeat;
}

@mixin gradient-striped($color: #555, $angle: 45deg) {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, rgba(255, 255, 255, .15)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(255, 255, 255, .15)), color-stop(.75, rgba(255, 255, 255, .15)), color-stop(.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient($angle, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient($angle, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient($angle, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}

// Reset filters for IE
//
// When you need to remove a gradient background, do not forget to use this to reset
// the IE filter for IE9 and below.
@mixin reset-filter() {
  filter: unquote("progid:DXImage-transform@mixin Microsoft.gradient(enabled = false)");
}

// Retina images
//
// Short retina mixin for setting background-image and -size

@mixin img-retina($file-1x, $file-2x, $width-1x, $height-1x) {
  background-image: url("#{$file-1x}");

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    background-image: url("#{$file-2x}");
    background-size: $width-1x $height-1x;
  }
}

// Responsive image
//
// Keep images from scaling beyond the width of their parents.

@mixin img-responsive($display: block) {
  display: $display;
  max-width: 100%;
// Part 1: Set a maximum relative to the parent
  height: auto;
// Part 2: Scale the height according to the width, otherwise you get stretching
}

// COMPONENT MIXINS
// --------------------------------------------------

// Horizontal dividers
// -------------------------
// Dividers (basically an hr) within dropdowns and nav lists
@mixin nav-divider($color: #e5e5e5) {
  height: 1px;
  margin: (($line-height-computed / 2) - 1) 0;
  overflow: hidden;
  background-color: $color;
}

// Panels
// -------------------------
@mixin panel-variant($border, $heading-text-color, $heading-bg-color, $heading-border) {
  border-color: $border;
  & > .panel-heading {
    color: $heading-text-color;
    background-color: $heading-bg-color;
    border-color: $heading-border;
    + .panel-collapse .panel-body {
      border-top-color: $border;
    }
  }
  & > .panel-footer {
    + .panel-collapse .panel-body {
      border-bottom-color: $border;
    }
  }
}

// Alerts
// -------------------------
@mixin alert-variant($background, $border, $text-color) {
  background-color: $background;
  border-color: $border;
  color: $text-color;
  hr {
    border-top-color: darken($border, 5%);
  }
  .alert-link {
    color: darken($text-color, 10%);
  }
}

// Tables
// -------------------------
@mixin table-row-variant($state, $background, $border) {
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.
  .table > thead > tr,
  .table > tbody > tr,
  .table > tfoot > tr {
    > td.#{$state},
    > th.#{$state},
    &.#{$state} > td,
    &.#{$state} > th {
      background-color: $background;
      border-color: $border;
    }
  }

// Hover states for `.table-hover`
// Note: this is not available for cells or rows within `thead` or `tfoot`.
  .table-hover > tbody > tr {
    > td.#{$state}:hover,
    > th.#{$state}:hover,
    &.#{$state}:hover > td,
    &.#{$state}:hover > th {
      background-color: darken($background, 5%);
      border-color: darken($border, 5%);
    }
  }
}

// Button variants
// -------------------------
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:hover,
  &:focus,
  &:active,
  &.active,
  .open &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 8%);
    border-color: darken($border, 12%);
  }
  &:active,
  &.active,
  .open &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $background;
      border-color: $border
    }
  }
}

// Button sizes
// -------------------------
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}

// Pagination
// -------------------------
@mixin pagination-size($padding-vertical, $padding-horizontal, $font-size, $border-radius) {
  > li {
    > a,
    > span {
      padding: $padding-vertical $padding-horizontal;
      font-size: $font-size;
    }
    &:first-child {
      > a,
      > span {
        @include border-left-radius($border-radius);
      }
    }
    &:last-child {
      > a,
      > span {
        @include border-right-radius($border-radius);
      }
    }
  }
}

// Labels
// -------------------------
@mixin label-variant($color) {
  background-color: $color;
  &[href] {
    &:hover,
    &:focus {
      background-color: darken($color, 10%);
    }
  }
}

// Navbar vertical align
// -------------------------
// Vertically center elements in the navbar.
// Example: an element has a height of 30px, so write out `@mixin navbar-vertical-align(30px);` to calculate the appropriate top margin.
@mixin navbar-vertical-align($element-height) {
  margin-top: (($navbar-height - $element-height) / 2);
  margin-bottom: (($navbar-height - $element-height) / 2);
}

// Progress bars
// -------------------------
@mixin progress-bar-variant($color) {
  background-color: $color;
  .progress-striped & {
    @include gradient-striped($color);
  }
}

// Responsive utilities
// -------------------------
// More easily include all the states for responsive-utilities.less.
@mixin responsive-visibility($parent) {
  #{$parent} {
    display: block !important;
  }
  tr#{$parent} {
    display: table-row !important;
  }
  th#{$parent},
  td#{$parent} {
    display: table-cell !important;
  }
}

@mixin responsive-invisibility($parent) {
  #{$parent} {
    display: none !important;
  }
  tr#{$parent} {
    display: none !important;
  }
  th#{$parent},
  td#{$parent} {
    display: none !important;
  }
}

// Grid System
// -----------

// Centered container element
@mixin container-fixed() {
  margin-right: auto;
  margin-left: auto;
  padding-left: ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);
  @include clearfix;
}

// Creates a wrapper for a series of columns
@mixin make-row($gutter: $grid-gutter-width) {
  margin-left: ($gutter / -2);
  margin-right: ($gutter / -2);
  @include clearfix;
}

// Generate the extra small columns
@mixin make-xs-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
  float: left;
  width: percentage(($columns / $grid-columns));
// Prevent columns from collapsing when empty
  min-height: 1px;
// Inner gutter via padding
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
}

// Generate the small columns
@mixin make-sm-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
// Prevent columns from collapsing when empty
  min-height: 1px;
// Inner gutter via padding
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);

// Calculate width based on number of columns available
  @media (min-width: $screen-sm-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}

// Generate the small column offsets
@mixin make-sm-column-offset($columns) {
  @media (min-width: $screen-sm-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}

@mixin make-sm-column-push($columns) {
  @media (min-width: $screen-sm-min) {
    left: percentage(($columns / $grid-columns));
  }
}

@mixin make-sm-column-pull($columns) {
  @media (min-width: $screen-sm-min) {
    right: percentage(($columns / $grid-columns));
  }
}

// Generate the medium columns
@mixin make-md-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
// Prevent columns from collapsing when empty
  min-height: 1px;
// Inner gutter via padding
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);

// Calculate width based on number of columns available
  @media (min-width: $screen-md-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}

// Generate the large column offsets
@mixin make-md-column-offset($columns) {
  @media (min-width: $screen-md-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}

@mixin make-md-column-push($columns) {
  @media (min-width: $screen-md) {
    left: percentage(($columns / $grid-columns));
  }
}

@mixin make-md-column-pull($columns) {
  @media (min-width: $screen-md-min) {
    right: percentage(($columns / $grid-columns));
  }
}

// Generate the large columns
@mixin make-lg-column($columns, $gutter: $grid-gutter-width) {
  position: relative;
// Prevent columns from collapsing when empty
  min-height: 1px;
// Inner gutter via padding
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);

// Calculate width based on number of columns available
  @media (min-width: $screen-lg-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}

// Generate the large column offsets
@mixin make-lg-column-offset($columns) {
  @media (min-width: $screen-lg-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}

@mixin make-lg-column-push($columns) {
  @media (min-width: $screen-lg-min) {
    left: percentage(($columns / $grid-columns));
  }
}

@mixin make-lg-column-pull($columns) {
  @media (min-width: $screen-lg-min) {
    right: percentage(($columns / $grid-columns));
  }
}

// Form control sizing
//
// Relative text size, padding, and border-radii changes for form controls. For
// horizontal sizing, wrap controls in the predefined grid classes. `<select>`
// element gets special love because it's special, and that's a fact!

@mixin input-size($parent, $input-height, $padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  #{$parent} {
    height: $input-height;
    padding: $padding-vertical $padding-horizontal;
    font-size: $font-size;
    line-height: $line-height;
    border-radius: $border-radius;
  }

  select#{$parent} {
    height: $input-height;
    line-height: $input-height;
  }

  textarea#{$parent} {
    height: auto;
  }
}
