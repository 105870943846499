// BAGS SECTION
// ----------------

#bags-selection {
  .product-header {
    margin-bottom: $padding-large-vertical;
    margin-top: $padding-large-vertical * 3;
    padding-bottom: $padding-small-vertical;
  }

  header {
    background-color: white;
    border: none;

    p {
      color: $gray-light;
    }
  }

  .product {
    @include flexbox;
    justify-content: center;
  }

  .air-leg-bag-option {

    .air-leg-bag-unavailable {
      background: white;
      border: 1px solid $gray-light;
      box-sizing: content-box;
      color: $brand-warning;
      font-style: italic;
      margin-bottom: 0;
      padding: $padding-large-vertical $padding-large-horizontal;
      text-transform: lowercase;

      &:first-letter {
        text-transform: capitalize;
      }
    }

    .leg-title {
      font-weight: $text-strong-font-weight;
      margin: 0;
      padding: $padding-large-vertical $padding-large-horizontal;
    }

    .traveler-list {
      background: white;
      border: 1px solid $ancillary-bag-border-color;
      border-bottom: none;
      padding-left: 0;

      .traveler-item {
        box-sizing: content-box;
        margin-bottom: 0;

        .traveler-item-bag {
          align-items: center;
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          padding: $ancillary-bag-traveler-item-padding 0;
          border-bottom: 1px solid $ancillary-bag-border-color;

          .traveler-name, .membership-tier {
            padding-right: $padding-small-horizontal;
            padding: 0 $padding-large-horizontal;

            &.highlight {
              font-weight: $text-strong-font-weight;
            }
          }

          .membership-tier {
            font-style: italic;
            text-transform: lowercase;

            &:first-letter {
              text-transform: capitalize;
            }
          }
        }

        .bag-options, .bag-details {
          overflow: hidden;
        }

        .bag-options {
          .bag-item {

            &.selected {
              background-color: rgba($brand-primary, .15);
            }

            border-bottom: 1px solid $ancillary-bag-border-color;

            .bag-details {
              padding: 0 ($padding-base-vertical * 2);
              align-items: center;
              display: flex;
              flex-flow: row;
              justify-content: space-between;

              .ancillary-price-from-label,
              .price-strikethrough {
                line-height: 1.2;
              }

              .bag-number-stepper {
                height: calc(#{$line-height-computed} * 2.5);
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .icon {
                color: $brand-primary;
              }

              .no-bag-description {
                i {
                  display: none;
                }
                span {
                  color: $text-color;
                  cursor: default;
                }
              }

              > :first-child {
                padding-left: 0;
              }

              > :last-child {
                padding-right: 0;
              }

              span {
                display: block;
              }

              .bag-name {
                color: $brand-primary;
                .bag-subgroup-name {
                  cursor: pointer;
                }

                span {
                  display: table;
                }

                i {
                  padding: 0 $ancillary-bag-name-arrow-horizontal-padding;
                }
              }
            }

            .bags-included {
              padding-left: ($padding-small-vertical * 6);
            }

            .bag-dimensions .content {
              p {
                margin: 0;
              }
              color: $gray-medium;
              margin: $ancillary-bag-dimensions-margin-top $ancillary-bag-dimensions-margin-left $ancillary-bag-dimensions-margin-bottom $ancillary-bag-dimensions-margin-right;
            }
          }
        }
      }
    }
  }

  .product-footer {
    @include flexbox;
    justify-content: center;

    padding-top: $padding-large-vertical;
    .row {
      margin: 0;
    }

    .apply-to-all {
      color: $brand-primary;
      margin-bottom: $ancillary-bag-apply-to-all-margin-bottom;
      padding: 0 $ancillary-bag-name-base-padding;
    }

    .clear-all {
      padding: 0 $ancillary-bag-name-base-padding;
    }

  }
}