/* ITINERARY */
// Extends aside-contents-container
#itinerary-modal {
  @extend %wide-modal;
}

.itinerary-container, .dash-cart-container {

  ul:not(.list-styled) {
    @include no-bullets;
    border-bottom: 0;
    margin-left: 0;
    padding: 0;

    &.list-inline > li {
      padding-left: 0;
    }

  }

  .components-container > header {
    @extend .p;
  }

  section header {
    border-bottom: $section-header-border-bottom-width solid $section-header-border-bottom-color;
  }

  header.split-header {
    // Expects h3 and a as immediate children of header
    position: relative;
    @include clearfix;

    > h3,
    > span {
      @extend .col-xs-6;
      line-height: $line-height-computed;
    }

    > h3 {
      @extend .pull-left;
      padding-left: 0;
      padding-right: 0;

      > .icon {
        display: none;
        // By default component icons are hidden - to show, override in theme with display: inline-block
      }
    }
  }

  .component-body {
    margin: 10px 0;
  }

  .itinerary-changeRoom {
    float: right;
    margin-right: ($grid-gutter-width / 2);
  }

  .component {
    padding: $padding-large-vertical 0;
    border-bottom: 1px solid $aside-border-color;

    &:only-of-type,
    &:last-of-type {
      border-bottom: 0;
    }
  }

  #marketing-ad {
    img {
      @extend .img-responsive;
    }
  }
}