.filters-container {

  .filter-info {
    margin-bottom: $padding-large-vertical * 3;
    padding-bottom: $padding-large-vertical * 3;
    border-bottom: 1px solid $filter-section-divider-color;
    position: relative;
  }

  .filter,
  .slider-filter-group .component-section {/* targets multi-air filter */
    @extend .filter-info;
  }

  & > fieldset.filter:last-of-type,
  .slider-filter-group .component-section:last-of-type {
    border-bottom: 0;
  }

  .slider-filter-group .component-section:last-of-type {
    padding-bottom: 0;
  }

  .filter h3 {
    font-size: $filter-h3-font-size;
    margin: 0 0 $padding-large-vertical;
  }

  .filter > legend {
    margin-bottom: 0;
  }

  .filter h3 > div {
    margin: 5px 0;
    font-weight: normal;
    @extend .text-small;
  }

  div:first-of-type .filter h3 {
    margin-top: 10px;
  }

  .filter-subheader {
    display: inline-block;
    margin-left: 15px;
    @extend .p;
  }

  .filter-body .form-inline select {
    margin-bottom: 0;
  }

  .filter-body { //Todo refactor in SS-39809

    label {
      padding-left: 0;
      padding-right: 0;
      display: inline-block;
      cursor: pointer;
      // prevents text overflowing container on smaller screens
      // before filter disappears into the slideout.
      //width: 85%;
      word-break: break-word;
      position: relative;
    }

    .badge { //Todo refactor in SS-39809
      background: $badge-bg;
      color: $badge-color;
      width: 3em; // match offset with label padding above
      line-height: 1.2;
      position: absolute;
      right: 0;
      top: 0;
    }
    .filter-count{
      position: relative !important;
      margin-left: 5px;
    }

    &.checkbox-filter .checkbox {
      display: block;
      margin-top: 0;
      margin-bottom: $padding-base-vertical + 2;
    }

    &.checkradio-filter .radio {
      margin-top: 0;
      margin-bottom: $padding-base-vertical + 2;
    }
  }
  .rating {
    display: inline-block;
  }

  .rating-filter {
    input {
      margin-right: 5px;

    }

    .rating {
      line-height: 1;

      > .icon {
        line-height: 25px;
        margin-left: 5px;
      }
    }
  }

  //aligns tripadvisor and star rating filters
  .filter-image {
    font-family: initial;

    input {
      margin-top: $padding-base-vertical;
    }

    .badge {
      font-family: $font-family-base;
    }
  }
}
