// Desktop/Tablet
// -------------------------

.case-base.page-hotel-detail {
  // content block sizes
  $hotel-detail-size1: 40% 40px;
  $hotel-detail-size2: 50% 40px;
  $hotel-detail-size3: 25% 40px;

  $hotel-detail-size4: 68% 530px;
  $hotel-detail-size5a: 30% 170px;
  $hotel-detail-size5b: 30% 170px;
  $hotel-detail-size5c: 30% 170px;

  $hotel-detail-size7: 30% 40px;
  $hotel-detail-size8: 100% 40px;
  $hotel-detail-size9: 100% 40px;

  // content block positions
  $hotel-detail-pos1: 0 0;
  $hotel-detail-pos2: 0 60px;
  $hotel-detail-pos3: 100% 60px;

  $hotel-detail-pos4: left 145px;
  $hotel-detail-pos5a: right 145px;
  $hotel-detail-pos5b: right 325px;
  $hotel-detail-pos5c: right 505px;

  $hotel-detail-pos7: 0 710px;
  $hotel-detail-pos8: 0 770px;
  $hotel-detail-pos9: 0 830px;

  .page-body--is-loading .page-body {
    margin-top: 80px;

    &::after {
      @include skeleton-setup(hotel-detail-animate);

      @include skeleton (
        animate($skeleton-screen-overlay-rbga-color, 85vw, 100vh, 100%, 0),

        rectangle($skeleton-screen-content-block-color, $hotel-detail-size1, $hotel-detail-pos1),
        rectangle($skeleton-screen-content-block-color, $hotel-detail-size2, $hotel-detail-pos2),
        rectangle($skeleton-screen-content-block-color, $hotel-detail-size3, $hotel-detail-pos3),
        rectangle($skeleton-screen-content-block-color, $hotel-detail-size4, $hotel-detail-pos4),
        rectangle($skeleton-screen-content-block-color, $hotel-detail-size5a, $hotel-detail-pos5a),
        rectangle($skeleton-screen-content-block-color, $hotel-detail-size5b, $hotel-detail-pos5b),
        rectangle($skeleton-screen-content-block-color, $hotel-detail-size5c, $hotel-detail-pos5c),
        rectangle($skeleton-screen-content-block-color, $hotel-detail-size7, $hotel-detail-pos7),
        rectangle($skeleton-screen-content-block-color, $hotel-detail-size8, $hotel-detail-pos8),
        rectangle($skeleton-screen-content-block-color, $hotel-detail-size9, $hotel-detail-pos9),

      );
    }
  }

  $hotel-detail-background-size-list: (
    $hotel-detail-size1,
    $hotel-detail-size2,
    $hotel-detail-size3,
    $hotel-detail-size4,
    $hotel-detail-size5a,
    $hotel-detail-size5b,
    $hotel-detail-size5c,
    $hotel-detail-size7,
    $hotel-detail-size8,
    $hotel-detail-size9
  );

  @keyframes hotel-detail-animate {
    0% {
      background-size:
        100vw 100vh,
        $hotel-detail-background-size-list
    }

    100% {
      background-size:
        0 100vh,
        $hotel-detail-background-size-list
    }
  }
}

// Handheld
// -------------------------

.case-handheld.page-hotel-detail {
  // content block sizes
  $hotel-detail-size1h: 100% 40px;
  $hotel-detail-size2h: 40% 20px;
  $hotel-detail-size3h: 40% 20px;
  $hotel-detail-size4h: 100% 320px;

  $hotel-detail-size5h: 100% 40px;
  $hotel-detail-size6h: 85% 14px;
  $hotel-detail-size7h: 85% 14px;
  $hotel-detail-size8h: 85% 14px;
  $hotel-detail-size9h: 85% 14px;
  $hotel-detail-size10h: 55% 14px;

  $hotel-detail-size11h: 100% 40px;
  $hotel-detail-size12h: 85% 14px;
  $hotel-detail-size13h: 85% 14px;
  $hotel-detail-size14h: 85% 14px;
  $hotel-detail-size15h: 85% 14px;
  $hotel-detail-size16h: 55% 14px;


  // content block positions
  $hotel-detail-pos1h: $padding-small-horizontal*2 0;
  $hotel-detail-pos2h: $padding-small-horizontal*2 55px;
  $hotel-detail-pos3h: $padding-small-horizontal*2 85px;
  $hotel-detail-pos4h: 0 130px;

  $hotel-detail-pos5h: 0 475px;
  $hotel-detail-pos6h: $padding-small-horizontal*2 540px;
  $hotel-detail-pos7h: $padding-small-horizontal*2 560px;
  $hotel-detail-pos8h: $padding-small-horizontal*2 580px;
  $hotel-detail-pos9h: $padding-small-horizontal*2 600px;
  $hotel-detail-pos10h: $padding-small-horizontal*2 620px;

  $hotel-detail-pos11h: 0 660px;
  $hotel-detail-pos12h: $padding-small-horizontal*2 725px;
  $hotel-detail-pos13h: $padding-small-horizontal*2 745px;
  $hotel-detail-pos14h: $padding-small-horizontal*2 765px;
  $hotel-detail-pos15h: $padding-small-horizontal*2 785px;
  $hotel-detail-pos16h: $padding-small-horizontal*2 805px;

  .page-body--is-loading .page-body {
    margin-top: $padding-large-vertical;
    margin-left: -$padding-small-horizontal;
    margin-right: -$padding-small-horizontal;

    &::after {
      @include skeleton-setup(hotel-detail-animate-h);

    @include skeleton (
      animate($skeleton-screen-overlay-rbga-color, 85vw, 100vh, 105%, 0),

      rectangle($skeleton-screen-content-block-color, $hotel-detail-size1h, $hotel-detail-pos1h),
      rectangle($skeleton-screen-content-block-color, $hotel-detail-size2h, $hotel-detail-pos2h),
      rectangle($skeleton-screen-content-block-color, $hotel-detail-size3h, $hotel-detail-pos3h),
      rectangle($skeleton-screen-content-block-color, $hotel-detail-size4h, $hotel-detail-pos4h),

      rectangle($skeleton-screen-content-block-color, $hotel-detail-size5h, $hotel-detail-pos5h),
      rectangle($skeleton-screen-content-block-color, $hotel-detail-size6h, $hotel-detail-pos6h),
      rectangle($skeleton-screen-content-block-color, $hotel-detail-size7h, $hotel-detail-pos7h),
      rectangle($skeleton-screen-content-block-color, $hotel-detail-size8h, $hotel-detail-pos8h),
      rectangle($skeleton-screen-content-block-color, $hotel-detail-size9h, $hotel-detail-pos9h),
      rectangle($skeleton-screen-content-block-color, $hotel-detail-size10h, $hotel-detail-pos10h),

      rectangle($skeleton-screen-content-block-color, $hotel-detail-size11h, $hotel-detail-pos11h),
      rectangle($skeleton-screen-content-block-color, $hotel-detail-size12h, $hotel-detail-pos12h),
      rectangle($skeleton-screen-content-block-color, $hotel-detail-size13h, $hotel-detail-pos13h),
      rectangle($skeleton-screen-content-block-color, $hotel-detail-size14h, $hotel-detail-pos14h),
      rectangle($skeleton-screen-content-block-color, $hotel-detail-size15h, $hotel-detail-pos15h),
      rectangle($skeleton-screen-content-block-color, $hotel-detail-size16h, $hotel-detail-pos16h),

    );

      $hotel-detail-background-size-list-hh: (
        $hotel-detail-size1h,
        $hotel-detail-size2h,
        $hotel-detail-size3h,
        $hotel-detail-size4h,
        $hotel-detail-size5h,
        $hotel-detail-size6h,
        $hotel-detail-size7h,
        $hotel-detail-size8h,
        $hotel-detail-size9h,
        $hotel-detail-size10h,
        $hotel-detail-size11h,
        $hotel-detail-size12h,
        $hotel-detail-size13h,
        $hotel-detail-size14h,
        $hotel-detail-size15h,
        $hotel-detail-size16h
      );

      @keyframes hotel-detail-animate-h {
        from {
          background-size:
            100vw 100vh,
            $hotel-detail-background-size-list-hh
        }

        80%, 100% {
          background-size:
            0 100vh,
            $hotel-detail-background-size-list-hh
        }
      }
    }
  }
}
