@keyframes splide-loading {
  0% {
    transform: rotate(0)
  }
  to {
    transform: rotate(1turn)
  }
}

.splide__container {
  position: relative;
  box-sizing: border-box
}

.splide__list {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  will-change: transform
}

.splide.is-active .splide__list {
  display: flex
}

.splide__pagination {
  display: inline-flex;
  align-items: center;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0
}

.splide__pagination li {
  list-style-type: none;
  display: inline-block;
  line-height: 1;
  margin: 0
}

.splide {
  visibility: hidden
}

.splide, .splide__slide {
  position: relative;
  outline: none;
  border-radius: $product-image-border-radius;
}

.splide__slide {
  box-sizing: border-box;
  list-style-type: none !important;
  margin: 0;
  flex-shrink: 0
}

.splide__slide img {
  vertical-align: bottom;
}

#image-carousel-primary img {
  width : 100%;
  height: auto;
}

#image-carousel-filmstrip img {
  width : auto;
  height: 100%;
}

.splide__slide .img-caption {
  display: none;
}

.splide__slide.is-active .img-caption {
  position: absolute;
  bottom: -30px;
  display: inline-block;
  font-weight: $text-strong-font-weight;

  @media (max-width: $screen-xs) {
    bottom: 0; //a11y - text overlap
  }
}

.splide__slider {
  position: relative
}

.splide__spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid $gray;
  border-left-color: transparent;
  animation: splide-loading 1s linear infinite
}

#image-carousel-filmstrip-track {
  overflow: hidden;
  //removing overflow from main splide__track to place captions below main slider
  //setting overflow hidden on thumbnail instead
}

.splide__track {
  position: relative;
  z-index: 0;
}

.splide--draggable > .splide__track > .splide__list > .splide__slide {
  -webkit-user-select: none;
  user-select: none
}

.splide--fade > .splide__track > .splide__list {
  display: block
}

.splide--fade > .splide__track > .splide__list > .splide__slide {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
  background-color: $gray-lighter !important;
  background-size: contain !important;
  height: 63vh !important; // Overrides inline styles from splide to maintain full page modal

  @media (max-height: 750px) {
    height: 60vh !important; // Overrides inline styles from splide to maintain full page modal
  }

  @media (max-height: 650px) {
    height: 53vh !important; // Overrides inline styles from splide to maintain full page modal
  }
}

.splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  position: relative;
  z-index: 1;
  opacity: 1;
}

.splide--rtl {
  direction: rtl
}

.splide--ttb > .splide__track > .splide__list {
  display: block
}

.splide--ttb > .splide__pagination {
  width: auto
}

.splide__arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 4em;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
}

#image-carousel .splide__arrow {
  background: transparent;
}

.splide__arrow svg {
  width: 24px;
  height: 24px
}

.splide__arrow:hover {
  cursor: pointer;
}

.splide__arrow:focus {
  outline: 5px auto $brand-primary;
  outline-offset: -2px;
  svg {
    fill: $link-hover-color;
  }
}

.slideshow-container {
  position: relative;
  width: calc(100% - 8em);
  margin: 0 auto;
}

.splide__arrow--prev {
  left: -4em; // half of the .slideshow-container calculated "margin" width

  svg {
    transform: scaleX(-1); // flips the orientation of the glyph
    fill: $product-image-arrow-color;
  }
}

.splide__arrow--next {
  right: -4em;

  svg {
    fill: $product-image-arrow-color;
  }
}

.splide__pagination {
  position: absolute;
  z-index: 1;
  bottom: .5em;
  left: 50%;
  transform: translateX(-50%);
  padding: 0
}

.splide__pagination__page {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #ccc;
  border-radius: 50%;
  margin: 3px;
  padding: 0;
  transition: transform .2s linear;
  border: none;
  opacity: .7
}

.splide__pagination__page.is-active {
  transform: scale(1.4);
  background: #fff
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: .9
}

.splide__pagination__page:focus {
  outline: none
}

.splide__progress__bar {
  width: 0;
  height: 3px;
  background: #ccc
}

#image-carousel-filmstrip-list {
  padding: 5px 0 !important; // needs to offset for is-active border and focus ring
}

.splide--nav > .splide__track > .splide__list > .splide__slide {
  background-color: $gray-lighter;
  border: $product-image-thumbnail-border;
  border-radius: $product-image-border-radius;
  opacity: .7;
}

.splide--nav > .splide__track > .splide__list > .splide__slide:focus {
  outline: 2px solid $brand-primary;
  opacity: 1;
}

.splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border-color: $product-image-thumbnail-selected-border-color;
  opacity: 1;
}

.splide--rtl > .splide__arrows .splide__arrow--prev, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev {
  right: 1em;
  left: auto
}

.splide--rtl > .splide__arrows .splide__arrow--prev svg, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--prev svg {
  transform: scaleX(1)
}

.splide--rtl > .splide__arrows .splide__arrow--next, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--next {
  left: 1em;
  right: auto
}

.splide--rtl > .splide__arrows .splide__arrow--next svg, .splide--rtl > .splide__track > .splide__arrows .splide__arrow--next svg {
  transform: scaleX(-1)
}

.splide--ttb > .splide__arrows .splide__arrow, .splide--ttb > .splide__track > .splide__arrows .splide__arrow {
  left: 50%;
  transform: translate(-50%)
}

.splide--ttb > .splide__arrows .splide__arrow--prev, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev {
  top: 1em
}

.splide--ttb > .splide__arrows .splide__arrow--prev svg, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--prev svg {
  transform: rotate(-90deg)
}

.splide--ttb > .splide__arrows .splide__arrow--next, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--next {
  top: auto;
  bottom: 1em
}

.splide--ttb > .splide__arrows .splide__arrow--next svg, .splide--ttb > .splide__track > .splide__arrows .splide__arrow--next svg {
  transform: rotate(90deg)
}

.splide--ttb > .splide__pagination {
  display: flex;
  flex-direction: column;
  bottom: 50%;
  left: auto;
  right: .5em;
  transform: translateY(50%)
}

#room-images-modal-handheld {
  figure {
    position: relative;
  }

  figcaption {
    text-align: $product-image-modal-caption-text-alignment_handheld;
    background: $product-image-modal-caption-background-opacity_handheld;
    padding: $padding-small-horizontal;
    font-size: $product-image-modal-caption-font-size_handheld;
    color: $product-image-modal-caption-text-color_handheld;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    @include text-overflow;
  }
}