/* Twitter Typeahead */

.twitter-typeahead {

  width: 100%;

  .tt-dropdown-menu {
    width: 100%; // TODO: Responsive
    box-shadow: 0 10px 10px rgba(0,0,0,.3); // Note: purposefully ignoring the mixin
    background: $tt-dropdown-bg-color;
    border: 1px solid $tt-dropdown-border-color;
    color: $tt-dropdown-text-color;
  }

  .tt-suggestion .row {
    padding: 4px ($grid-gutter-width / 2);
  }

  .tt-highlight {
    color: $tt-dropdown-highlight-text-color;
  }

  li.tt-cursor {
    color: $tt-dropdown-hover-text-color;
    background: $tt-dropdown-hover-bg-color;
  }

  .tt-empty {
    background: $tt-dropdown-empty-message-background-color;
    padding: 4px 0;
  }

  .rating {
    line-height: 1;
  }

  .rating span.star {
    margin-right: -3%; // Tightens for layout
    &:before {
      color: darken($tt-dropdown-bg-color, 15%);
    }
  }
}

.basic-typeahead {
  .twitter-typeahead {
    width: 100%;
  }
  .tt-dropdown-menu {
    box-shadow: 0 10px 10px rgba(0, 0, 0, .3);
    background: $tt-dropdown-bg-color;
    border: 1px solid $tt-dropdown-border-color;
    color: $tt-dropdown-text-color;
    max-height: $tt-dropdown-max-height;
    width: 100%;
    overflow-y: scroll;
  }

  .tt-suggestion, .tt-popular-destinations-header {
    padding: 4px ($grid-gutter-width / 2);
  }

  .tt-highlight {
    color: $tt-dropdown-highlight-text-color;
  }
}

.wide-results .tt-dropdown-menu {
      width: 150%;
}
