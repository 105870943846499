// BAG INFORMATION POPOVER
.popover-bag-details.top {
  border: 1px solid #818181;

  .popover-content {
    color: #1b1b1b;
    text-align: left;
    box-shadow: 10px 10px 15px rgba(148, 148, 148, 0.35);
    padding: 10px;

    .popover-title {
      font-size: large;
      background-color: #fff;
      border-bottom: 1px dotted #818181;
      padding: 0 0 5px 0;
    }

    .popover-body {
      padding-top: 10px;
      font-size: 14px;
    }
  }
}
