// --------------------------------------------------
// This partial describes responsive layout behaviors for our "compact" header (e.g. shows on checkout)
// --------------------------------------------------

.header-brand-container,
.header-loyalty-container,
.header-toolbar-container {
  @extend .clearfix;
  min-height: 1px;
  position: relative;
  padding-top: $header-brand-container-padding;
  padding-bottom: $header-brand-container-padding;
  padding-left: ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);
  float: left;
  margin-left: 0 !important;
  z-index: 98; //for headerLoyalty dropdown when it's above other header nav items
}

.header-toolbar-container {
  @extend .text-right;
  float: none;
}

.header-toolbar-container {
  width: percentage((12 / $grid-columns)) !important;
  padding-top: ($line-height-computed / 4);
  padding-bottom: ($line-height-computed / 4);
  border-top: 1px solid $site-header-toolbar-container-border-top-color;
  background: $site-header-toolbar-container-bg-color;
  color: $site-header-toolbar-container-text-color;
}

// Targets handheld (480px width) and below
// --------------------------------------------------
@media (max-width: $screen-xs) {

  @if $site-header-brand-container-col-width-handheld == 0 {
    .header-brand-container {
      display: none;
    }
  }

  @if $site-header-loyalty-container-col-width-handheld == 0 {
    .header-loyalty-container {
      display: none;
    }
  }

  @if $site-header-brand-container-col-width-handheld == 12 {
    .header-brand-container {
      float: none;
    }
  }

  @if $site-header-loyalty-container-col-width-handheld == 12 {
    .header-loyalty-container {
      float: none;
    }
  }

  .header-brand-container {
    width: percentage(($site-header-brand-container-col-width-handheld / $grid-columns));
  }

  .header-loyalty-container {
    width: percentage(($site-header-loyalty-container-col-width-handheld / $grid-columns));
  }
}

// Targets tablet (769px width) and lower to handheld (480px)
// --------------------------------------------------
@media (min-width: $screen-xs) and (max-width: $screen-sm) {

  @if $site-header-brand-container-col-width-tablet == 0 {
    .header-brand-container {
      display: none;
    }
  }

  @if $site-header-loyalty-container-col-width-tablet == 0 {
    .header-brand-container {
      display: none;
    }
  }

  @if $site-header-brand-container-col-width-tablet == 12 {
    .header-brand-container {
      float: none;
    }
  }

  @if $site-header-loyalty-container-col-width-tablet == 12 {
    .header-loyalty-container {
      float: none;
    }
  }

  .header-brand-container {
    width: percentage(($site-header-brand-container-col-width-tablet / $grid-columns));
  }

  .header-loyalty-container {
    width: percentage(($site-header-loyalty-container-col-width-tablet / $grid-columns));
  }
}

// Targets above tablet (770px width) to 988px
// --------------------------------------------------
@media (min-width: $screen-sm + 1) and (max-width: $screen-md) {

  @if $site-header-brand-container-col-width == 0 {
    .header-brand-container {
      display: none;
    }
  }

  @if $site-header-loyalty-container-col-width == 0 {
    .header-loyalty-container {
      display: none;
    }
  }

  @if $site-header-brand-container-col-width == 12 {
    .header-brand-container {
      float: none;
    }
  }

  @if $site-header-loyalty-container-col-width == 12 {
    .header-loyalty-container {
      float: none;
    }
  }

  .header-brand-container {
    width: percentage(($site-header-brand-container-col-width / $grid-columns));
  }

  .header-loyalty-container {
    width: percentage(($site-header-loyalty-container-col-width / $grid-columns));
  }

}

// Targets medium screens (989px width) to 1200px
// --------------------------------------------------

@media (min-width: $screen-md + 1) and (max-width: $screen-lg) {

  @if $site-header-brand-container-col-width-md == 0 {
    .header-brand-container {
      display: none;
    }
  }

  @if $site-header-loyalty-container-col-width-md == 0 {
    .header-loyalty-container {
      display: none;
    }
  }

  @if $site-header-brand-container-col-width-md == 12 {
    .header-brand-container {
      float: none;
    }
  }

  @if $site-header-loyalty-container-col-width-md == 12 {
    .header-loyalty-container {
      float: none;
    }
  }

  .header-brand-container {
    width: percentage(($site-header-brand-container-col-width-md / $grid-columns));
  }

  .header-loyalty-container {
    width: percentage(($site-header-loyalty-container-col-width-md / $grid-columns));
  }
}

// Targets medium screens (1201px width) and above
// --------------------------------------------------
@media (min-width: $screen-lg + 1) {

  @if $site-header-brand-container-col-width-lg == 0 {
    .header-brand-container {
      display: none;
    }
  }

  @if $site-header-loyalty-container-col-width-lg == 0 {
    .header-loyalty-container {
      display: none;
    }
  }

  @if $site-header-brand-container-col-width-lg == 12 {
    .header-brand-container {
      float: none;
    }
  }

  @if $site-header-loyalty-container-col-width-lg == 12 {
    .header-loyalty-container {
      float: none;
    }
  }

  .header-brand-container {
    width: percentage(($site-header-brand-container-col-width-lg / $grid-columns));
  }

  .header-loyalty-container {
    width: percentage(($site-header-loyalty-container-col-width-lg / $grid-columns));
  }

}