%aside-kard-layout {
  .kard-block {
    flex: 1;
    padding: $padding-large-vertical;
  }

  .img-container {
    overflow: hidden;
    margin: $padding-large-vertical;
  }
}

%kard-img-full {
  .kard-block {
    padding: $padding-large-vertical;
  }

  .img-container,
  img {
    margin: 0;
    border-radius: $kard-border-radius;
  }

  img {
    object-fit: cover;
    min-height: 100%;
  }
}

%kard-img-fill {
  img {
    height: 100%;
    object-fit: cover;
  }
}

.kard-deck {
  @include flexbox;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
  background-color: $kard-deck-background-color;
  padding: 0;

  .media {
    &:first-of-type,
    &:first-child {
      margin-top: $padding-large-vertical;
    }
  }

  .product .product-body {
    border: none;
    padding: $padding-large-vertical $padding-base-horizontal;
    flex-grow: 1;

    .product-deal.rate-deal {
      margin: 0;
      display: inherit;

      &:before {
        content: none;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .product-detail {
    > .flex-grow + div:before {
      content: '';
      border-top: 1px solid $product-border-color;
      display: block;
      margin-bottom: $padding-small-vertical;
    }
    &.flex-container > [class*='col-'] {
      padding: 0 ($grid-gutter-width / 2);
    }
  }
}

@media (min-width: $screen-sm) {
  .kard-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;

    .kard {
      -ms-flex: 1 0 25%;
      flex: 1 0 25%;
    }
  }
}

.kard {
  @include flexbox;
  border-radius: $kard-border-radius;
  border: $kard-border-width solid $kard-border-color;
  background: $kard-body-bg-color;
  margin: $padding-large-vertical;
  @include box-shadow($shadow-md);

  .kard-block {
    padding: $padding-large-vertical $padding-large-horizontal;
    flex-grow: 1;

    hr {
      border-color: $gray-light;
    }
  }

  &.kard-link:hover {
    box-shadow: $kard-link-box-hover-hover;
    transition: $shadow-hover-transition;
  }

  .kard-title {
    font-size: $kard-title-font-size;
    font-weight: $kard-title-font-weight;
    color: $kard-title-text-color;
    line-height: $kard-title-line-height;
  }

  .img-container {
    overflow: hidden;

    img {
      min-width: 100%;
    }
  }

  .btn {
    align-self: flex-start;
  }

  &.product-body {
    .deal-container {
      margin: -30px 0 0 -26px;
    }

    .product-deal.rate-deal {
      padding-right: $padding-large-vertical;
      margin: 0;
      position: relative;
      display: inline-block;

      .text-ellipsis {
        max-width: 300px;
      }
    }
  }

  &.search-form-marketing-card {
    // align top of marketing card to search form container
    margin-top: $hero-button-group-height;
    border: none;
    @include box-shadow($shadow-xl);

    .img-container {
      padding: $padding-large-vertical;
    }

    &.search_form_standard {
      background-color: $search-form-marketing-kard-background-color;
      color: $search-form-marketing-kard-text-color;
    }

    .kard-block {
      padding: $search-form-marketing-kard-padding;
    }

    .hero-title,
    .kard-title {
      @extend .h2;
      margin-top: 0;
      color: $search-form-marketing-kard-text-color;
    }

    .hero-message {
      margin-bottom: 0;
      padding: $kard-hero-message-container-padding;
    }
  }

  // Any card with a large image top
  &.card-large-image-top,
  &.cross-sell-card-large-image-top-with-cta-button,
  &.cross-sell-text-only-with-cta-button,
  &.search-form-marketing-card {
    -ms-flex-direction: column;
    flex-direction: column;

    img {
      border-radius: $kard-border-radius $kard-border-radius 0 0;
    }

    img.img-fixed-height {
      height: 150px;
      max-width: 250px;
      object-fit: cover;
    }
  }

  &.card-large-image-bottom {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  &.banner-image-left {
    @extend %aside-kard-layout;

    .img-container {
      margin-right: 0;
    }
  }

  &.banner-image-right {
    @extend %aside-kard-layout;
    flex-direction: row-reverse;

    .img-container {
      margin-left: 0;
    }
  }

  &.banner-full-image-left {
    @extend .banner-image-left;
    @extend %kard-img-full;
  }

  &.banner-full-image-right {
    @extend .banner-image-right;
    @extend %kard-img-full;
  }

  &.alternate {
    .kard-title {
      color: $kard-alt-title-text-color;
    }

    background: $kard-alt-bg-color !important;
    color: dynamicColor($kard-alt-bg-color);

    a {
      color: dynamicColor($kard-alt-bg-color);
    }
  }

  li {
    margin-left: 1.5rem;
  }
}

@media (min-width: $screen-xs) and (max-width: 768px) {
  .kard {
    margin-left: 0;
    margin-right: 0;
  }
}

.fare-kards {
  .kard-deck  {
    flex-flow: row wrap;
    .kard {
      padding: .5em;
      border-radius: $product-kard-border-radius_handheld;
      @extend .flex-1;

      @media (min-width: $screen-xs) {
        &:not(:first-of-type) {
          margin-left: 5px;
        }
      }
    }
  }

  .kard-horizontal .kard-detail:hover {
    @include box-shadow($shadow-md);
  }

  .kard-title {
    padding: 0 0 .5em;
    border-bottom: 1px solid $gray-light;
    margin-bottom: 1em;
  }

  .kard-flag-indicator {
    display: none;
  }

  .product-selected {
    outline: 3px solid $brand-success;
    position: relative;

    .h4.text-success {
      display: none; // Hide extra success (via scss b/c it's hard coded in air-availability-price.base.hbs
    }

    .kard-flag-indicator {
      line-height: 2;
      width: calc(100% + 6px);
      position: absolute;
      top: -2em;
      left: -3px;
      display: block;
      color: #fff;
      text-align: center;
      background: $brand-success;
    }
  }
}

// Desktop styles
// -------------------------

.case-base {
  .kard {
    .kard-block {
      flex-grow: 1; //WHY do we need this? Already set above.
    }
  }

  .fare-kards {
    .kard-title {
      text-align: center;
    }

    .kard-horizontal {
      .kard {
        padding: 1.5em;
      }

      .kard-block {
        width: 100%;
      }

      .kard-title {
        text-align: left;
      }

      .kard-detail {
        @extend .flex-2;
      }
    }
  }
}

// Handheld styles
// -------------------------

.case-handheld {
  .kard.search-form-marketing-card {
    margin: $page-area-padded/2 0;
    background-color: $search-form-marketing-kard-background-color_handheld;
    color: $search-form-marketing-kard-text-color_handheld;
    @include box-shadow($shadow-md);

    .hero-title,
    .kard-title {
      color: $search-form-marketing-kard-text-color_handheld;
    }
  }

  .fare-kards {
    .kard.product-selected {
      margin-top: calc(2em + 28px);
    }
  }

  @media (orientation: landscape) {
    //marketing cache cards
    .product-body {
      -ms-flex-direction: row;
      flex-direction: row;
      flex-basis: 95%;

      .kard-block {
        width: 75%;
      }

      .deal-container {
        margin: 0;
        display: inline-block;
      }

      .product-deal.rate-deal {
        margin-left: 0;
        padding-right: $padding-large-vertical;

        &:before {
          content: none;
        }

        .text-ellipsis {
          white-space: inherit;
          max-width: 100%;
        }
      }

      .img-container {
        width: 35%;
        float: left;

        img {
          margin: $padding-large-vertical;
        }
      }
    }
  }
}

.case-base {
  .kard {
    // Any card with a large image top
    &.card-large-image-top,
    &.cross-sell-card-large-image-top-with-cta-button,
    &.cross-sell-text-only-with-cta-button,
    &.search-form-marketing-card,
    &.card-large-image-bottom  {
      @extend %kard-img-fill;
    }
  }
}