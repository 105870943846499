/*
 * Use caution and make sure that you import this as namespaced to your part of the app.
 * See _products.scss as an example.
*/

.expandable-container {
  .expandable {
    max-height: 300px;
    overflow: hidden;
    @include transition(max-height .7s ease-in-out);
    position: relative;
  }

  .section-footer {
    .icon-collapsible--opened {
      display: none;
    }
    .icon-collapsible--closed {
      display: inline-block;
    }
  }


  &.section-expanded {

    .expandable {
      max-height: 3000px; // This has to be a ridiculous number to work properly
      @include transition(max-height .5s ease-in-out);
    }

    .mask {
      display: none;
    }

  }

  .mask {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: 0;
    left: 0;
    bottom: 0;
  }

  .section-footer {
    border-top: 1px solid $gray-medium;
  }
}