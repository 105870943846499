// List view and air details and checkout
// -----------------------------
.seat-selection-preview, .air-seating {

  #seat-map-modal {
    @extend %wide-modal;

    h3 {
      margin-top: $line-height-computed;
      margin-bottom: ($line-height-computed / 2);
    }
  }

  #seat-map-modal .row {
    margin-left: 0;
  }

  #seat-map-modal .modal-body {
    padding-bottom: 0;
  }

  #seat-map-modal .loading {
    margin-top: 15%;
    display: block;
    text-align: center;
  }

  #seat-map-modal .loading:before {
    left: 48%;
  }

  .map-body {
    padding: 0 15px;
  }

  .seat-map-container,
  .seat-map-unavailable-container {
    overflow-y: auto;
    height: 325px;
    margin-right: -15px;
  }

  .seat-map-flight-container{
    .seat{
      &.btn{
        cursor: auto;
        pointer-events: none;
      }
      &.btn:hover {
        background-color: $btn-default-bg-color-hover;
      }
    }
  }

  .map-body .span4 > div {
    margin-bottom: $line-height-computed;
  }

  .map-header {
    border-bottom: 1px solid $gray-lighter;
  }

  .map-body .span8 {
    border-left: 1px solid $gray-lighter;
  }

  .map-body [class*="span"] {
    padding-top: 10px;
  }

  .map-body header span {
    padding-left: 10px;
  }

  .seat-map-legend li {
    line-height: 30px;
    margin-bottom: 5px;
  }

  .seat-map-legend li span {
    @extend .pull-left;
    margin-right: 10px;
  }

  .table-seats {
    width: auto;
    @include container-fixed;
  }

  .table-seats td {
    width: 20px;
    text-align: center;
    padding: 2px;
    border: 0;
  }

  .table-seats .row-exit td:first-of-type,
  .table-seats .row-exit td:last-of-type {
    background: transparent;
  }

  .table-seats .row-wing td:first-of-type,
  .table-seats .row-wing td:last-of-type {
    background: $gray-medium;
    width: 20px;
  }

  .table-seats td:first-of-type {
    border-right: 1px solid $gray-lighter;
  }

  .table-seats td:last-of-type {
    border-left: 1px solid $gray-lighter;
  }

  .table-seats thead td:first-of-type,
  .table-seats thead td:last-of-type {
    border: none;
  }

  .table-seats .aisle {
    border: 1px solid $gray-lighter;
    border-width: 0 1px;
  }

  .table-seats thead tr td {
    font-weight: $text-strong-font-weight;
  }

  .legend-exit {
    display: inline-block;
    height: 25px;
    width: 20px;
  }

  .table-seats .row-exit td,
  .legend-exit {
    background: pink;
    background-image: linear-gradient(left top, rgba(255, 255, 255, 0.2) 0%, transparent 25%, rgba(255, 255, 255, 0.2) 50%, transparent 75%, rgba(255, 255, 255, 0.2) 100%);
    background-size: 25% 17%;
  }

  .inline-seat-number {
    vertical-align: inherit;
  }

  .icon-spinner,
  .icon-alert__warning {
    display: inline-block;
  }

  .icon-alert__warning {
    font-size: 240px;
    margin: 0 auto;
    display: block;
  }

  .seat-map-unavailable-container .loading {
    display: block;
    margin-top: 130px;
  }

  .seat-map-unavailable-container h3 {
    margin-top: $line-height-computed;
  }

  .seat-map-unavailable-container .icon-spinner {
    margin-top: 130px;
  }

  // Seat images
  span.air-seat,
  button.air-seat {
    display: block;
    width: 20px;
    height: 25px;
  }

  span.seat-available,
  button.seat-available {
    background: transparent url('#{$assetsPath}/seat_maps/seatmap_avail.png') top left no-repeat;
    &:hover {
      cursor: pointer;
    }
  }

  span.seat-unavailable,
  button.seat-unavailable {
    background: transparent url('#{$assetsPath}/seat_maps/seatmap_unavail.png') top left no-repeat;
  }

  span.seat-selected,
  button.seat-selected {
    background: transparent url('#{$assetsPath}/seat_maps/seatmap_selected.png') top left no-repeat;
  }
}

//// Flight Results seat map preview group results
// -----------------------------
#available-seats-modal {
  .flight-details-container {
    .row {
      @include flexbox;
      align-items: center;
      justify-content: center;

      .airline-info {
        float: left;
        margin: 0 8px;
      }
    }
  }
}