.case-base #cart-summary {
  .trip-summary-price-container {
    #trip-total-price {
      font-size: $font-size-large;
      font-weight: $trip-summary-total-price-font-weight;

     > span {
        font-weight: $trip-summary-total-price-value-font-weight;
      }
    }
  }

  .product-body {
    margin-bottom: $padding-large-vertical * 3;
    border-radius: $trip-summary-product-border-radius;
  }
}