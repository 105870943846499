.modal-safekey {
    z-index: 99999;
    display: block;
    position: fixed; 
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.4); 
}

.modal-safekey-dialog {
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    z-index: 100009;
    width: 50%;
    min-width: 100%;
}

.modal-safekey-content {
    position: relative;
    background-color: #fff; 
    border: 1px solid rgba(0,0,0,.2);
    box-shadow: 0 3px 9px rgba(0,0,0,.5);
    outline:0;
    max-height: 80vh;
}

.modal-safekey-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    min-height: 16.5
}

.modal-safekey-body {
    position: relative;
    padding: 20px;
    overflow: auto;
}

.modal-safekey-footer {
    margin-top: 0;
    padding: 19px 20px 20px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}
