#seat-map-legend {
  background: white;
  padding: 8px;

  .seat, .facility {
    text-align: center;
    display: inline-block;
    margin-right: $padding-large-horizontal;
  }

  .facility {
    &.row-exit {
      background: $aircraft-exit-emergency-background-color;
    }

    &.exit-door {
      background: transparent;
      position: relative;

      &:after {
        font-family: switchflyShopping;
        color: #c92a2a;
        display: block;
        font-size: 32px;
        line-height: 44px;
        position: absolute;
        content: "\e935";
        left: calc(50% - 4px);
        top: calc(50% - 21px);
      }
    }
  }

  [class^="col-"] {
    line-height: 44px;
    padding: $padding-large-vertical $padding-large-horizontal;

    & > * {
      vertical-align: middle;
    }
  }
}