.case-base {
  #search-form-container {
    background-color: $search-form-outer-container-bg;
    border-radius: $search-form-outer-container-border-radius;

    //consider removing search-form-left/right/center & search-form-marketing-card
    //if we won't be using search form marketing cards in the future in 2.0
    @media (min-width: $screen-md + 1px) {
      &.search-form-left,
      &.search-form-right {
        width: percentage((8 / $grid-columns));
      }
    }

    &.search-form-right {
      float: right;
    }

    &.search-form-left {
      float: left;
    }

    &.search-form-center {
      @extend .col-md-12;
      margin-bottom: $padding-large-vertical * 2;

      + .search-form-marketing-card {
        margin: inherit;
      }
    }

    .main-contents-container {
      box-shadow: $search-form-container-box-shadow;

      .section-body {
        padding: $search-form-section-padding;
        @if ($search-form-container-background-color != transparent) {
          @include alpha-background-color(rgba($search-form-container-background-color, $search-form-container-background-opacity), $text-color);
        } @else {
          background-color: $search-form-container-background-color;
        }
      }

      .nav-tabs > li.active > a {
        background: transparent;
      }

      .nav-tabs > li > a,
      .nav-tabs > li > a:hover {
        height: $hero-button-group-height - 1;
      }
    }

    .btn-hero-group-container {
      border-bottom: $hero-button-group-container-border-bottom;
    }
  }

  @media (min-width: $screen-md + 1px) {
    .search-form-left {
      margin-right: $padding-large-vertical * 2;
    }

    .search-form-right {
      margin-left: $padding-large-vertical * 2;
    }
  }

  @media (max-width: $screen-md) {
    #search-form-container {
      margin-bottom: $padding-large-vertical * 2;

      &.search-form-right,
      &.search-form-left {
        float: none;
      }
    }

    .kard.search-form-marketing-card {
      margin-top: 0;
    }
  }

  @media (min-width: 768px) and (max-width: 988px) {
    .kard.search-form-marketing-card {
      margin: 0;
    }
  }

  @media (min-width: $screen-xs) and (max-width: 768px) {
    #search-form-container,
    .search-form-marketing-card,
    .marketing-promotion-groups,
    .hero-message.background-transparent,
    .hero-message:not(.background-transparent) .container,
    .marketing-promotion-groups .kard-deck.container,
    .case-base .kard.search-form-marketing-card {
      margin-left: percentage((1 / $grid-columns));
      width: percentage((10/ $grid-columns));
      padding: 0 !important; //override bootstrap column padding
    }
  }

  @media (max-width: 768px) {
    .btn-hero-group {
      display: flex;
      flex-wrap: wrap;

      .btn {
        flex-grow: 1;
        margin: 2px 2px 0 2px;
      }
    }
  }
}

#modify-search-modal {
  .select-travelers {
    width: 100%;
  }

  #search-form-travelers-selection {
    .child-ages-container {
      margin: $padding-large-vertical 0 0 0;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

// Search Form Field Icons

#search-form-container, #modify-search-modal {
  #modify-search-modal-body {
    //This overrides the padding-bottom of 20px for a modal body
    padding-bottom: 32px;
  }

  .tt-suggestions {
    margin-bottom: 0;

    .tt-cursor {
      outline: 2px solid $brand-primary;
      outline-offset: -2px;
    }
  }

  .tt-suggestion {
    padding-top: $padding-small-horizontal;
    padding-bottom: $padding-small-horizontal;

    .icon {
      color: $tt-dropdown-text-color-light;

      > div {
        font-family: $font-family-base;
        font-size: $font-size-base;
        margin-left: $padding-small-horizontal;
      }
    }

    .product-name {
      font-weight: $text-strong-font-weight;
    }

    .product-location {
      color: $tt-dropdown-text-color-light;

      .tt-highlight {
        font-weight: normal;
      }
    }
  }
}

.icon-inline {
  position: relative;

  i[class*="icon-search-form__"],
  i.icon-user,
  i.icon-flight__duration,
  i.icon-user {
    color: $input-field-icon-color;
  }

  input,
  select,
  .btn-text-input {
    padding-left: 2em;
  }

  input[disabled] {
    display: inline-block;
  }

  i[class*="icon-search-form__"],
  i.icon-user,
  i.icon-flight__duration {
    @include verticallyMiddle(absolute);
    left: 8px;
    padding-bottom: $input-height-base / 2;
    pointer-events: none;
  }

  .error {
    select,
    textarea {
      margin-bottom: 0;
    }

    i[class*="icon-search-form__"] {
      padding-bottom: 0;
    }
  }

  .input-group {
    i[class*="icon-search-form__"] {
      padding-bottom: 3px;
    }
  }
}

.btn-text-input {
  @extend .form-control;
  @include box-shadow($input-box-shadow);
  @include transition(#{"border-color ease-in-out .15s, box-shadow ease-in-out .15s"});
  text-align: left;
  margin-top: 3px;

  #modify-search-modal & {
    min-width: 50%;
  }
}

.btn-icon-left {
  display: inline-block;
}

.btn-icon-container {
  position: relative;
}

div[class*="row-air"] + div[class*="row-air"] h3 {
  margin-top: 0;
}

@media (max-width: 500px) {
  .case-handheld .air-search-service-class {
    display: grid;
    grid-template-columns: 0 1fr 1fr 0;
    align-items: end;

    .col-xs-6 {
      width: 100% !important;
    }
  }

  .checkbox-inline.form-inline.p:nth-child(2) {
    margin-left: 0;
  }
}

//Switchfly 2.0 Search Form Styles

:root {
  --search-form-label-height: 20px;
}

#search-form-container,
#modify-search-modal {
  $_search-form-gap: 16px;
  $_search-btn-width: 160px;
  $_btn-search-font-size: $font-size-large;
  $_drivers-age-input-max-width: 80px;
  $_search-form-v2-label-height: var(--search-form-label-height); //match label height

  .tt-suggestion {
    .icon {
      align-items: baseline;
    }
  }

  @mixin grid-auto-columns($min: 200px, $max: 1fr) {
    grid-template-columns: repeat(auto-fit, minmax($min, $max));
  }

  %search-grid {
    display: grid;
    gap: $_search-form-gap;
  }

  %search-flex {
    display: flex;
    gap: $_search-form-gap;
  }

  //this only works when written out separately
  input#destination-car-input::placeholder {
    color: $text-color;
    opacity: 1;
  }

  //this only works when written out separately
  input#destination-car-input::-moz-placeholder {
    color: $text-color;
    opacity: 1;
  }

  @include customSelectIcon(0);

  .grid-search {
    @extend %search-grid;

    .gap {
      gap: $_search-form-gap;
    }

    .grid {
      @extend %search-grid;
    }
  }

  #drivers-age-input {
    display: flex; // align validation error message
    align-items: baseline;
    gap: $_search-form-gap / 3;

    > input {
      max-width: $_drivers-age-input-max-width;
    }
  }

  .checkbox-inline { //Refactor .checkbox-inline after 1.0 Sunset
    > label {
      display: flex;
      gap: 5px;
    }
  }

  input,
  select,
  textarea,
  .input-group,
  .btn-text-input {
    margin-top: 0; //revisit after 1.0 sunset, refactor
  }

  .input-group,
  .form-control,
  .error .text-attn,
  .btn-text-input {
    margin-bottom: 0; //revisit after 1.0 sunset, refactor
  }

  .error .error-text {
    margin-top: 0;
  }

  i[class*="icon-search-form__"],
  i.icon-user,
  i.icon-flight__duration {
    padding-bottom: 0;
  }

  .input-daterange {
    @extend %search-flex;

    > .flex-container {
      flex-grow: 1;
    }
  }

  h2.h3 { //revisit after 1.0 sunset
    margin-top: $_search-form-gap / 2;
    margin-bottom: $_search-form-gap;
  }

  .btn-search {
    height: $input-height-base; //matches form input heights
    font-size: $_btn-search-font-size;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* ------ grid areas ------- */
  .grid-date {
    grid-area: grid-date;
  }

  .grid-travelers {
    grid-area: grid-travelers;
    overflow: hidden;
  }

  .grid-btn-search {
    grid-area: grid-btn-search;
    width: $_search-btn-width;
    justify-self: end;
    align-self: end;

    @media (max-width: $screen-sm) {
      width: 100%;
    }
  }

  .grid-driver-age {
    grid-area: grid-driver-age;
    align-items: center;
    display: flex;
    gap: $_search-form-gap;

    @media (max-width: $screen-sm) {
      display: block;
      > * + * {
        margin-top: $_search-form-gap;
      }
      #drivers-age-input {
        display: block;
      }
    }
  }

  .grid-origin {
    grid-area: grid-origin;
  }

  .grid-departure {
    grid-area: grid-departure;
  }

  .grid-destination {
    grid-area: grid-destination;
  }

  .grid-has-btn {
    grid-area: grid-has-btn;
  }

  //Air Departure & Destination fields
  .grid-row-air {
    grid-area: grid-row-air;
    @extend %search-grid;
    row-gap: 0;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "grid-departure grid-destination"
      "grid-date grid-date";
  }

  //Allow Partial Room Stay Search is ENABLED
  .has-partial-room {
    .grid-partial-room {
      grid-area: grid-partial-room;
      display: flex;
      gap: $_search-form-gap;
      min-width: 76%; //need this to be on it's own line

      label {
        align-self: center;
        flex: 1;
      }

      @media (max-width: $screen-sm) {
        display: block;
        .show {
          margin-top: $_search-form-gap;
        }
      }
    }
  }

  .grid-header {
    grid-area: grid-header;
  }

  /* ------ Shared sections ------- */

  //air advanced options
  .grid-search-options .grid-air-advanced-options {
    @extend %search-grid;
    @include grid-auto-columns();
    align-items: center;
  }

  @media (max-width: $screen-sm) {
    .grid-advanced-options {
      > div {
        width: 100%;
      }
    }
  }

  //Driver's age, flex date, cabin selection, etc. Not inside Advanced Options
  .grid-search-options {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: $_search-form-gap;

    @media (max-width: $screen-sm) {
      > div {
        width: 100%;
      }
    }

    .grid-air-advanced-options {
      width: 100%;
      display: flex;

      > div {
        //match input fields above
        flex: 0 0 calc(34% - $_search-form-gap);

        &:last-of-type {
          flex: 1;
          display: flex;
          column-gap: $_search-form-gap;
          row-gap: $_search-form-gap / 2;
          flex-wrap: wrap;
        }
      }

      @media (max-width: $screen-sm) {
        display: block;
        > * + * {
          margin-top: $_search-form-gap;
        }
      }
    }
  }

  /* ------ Search Products ------- */

  .grid-search-room,
  .grid-search-activity {

    .grid-btn-search {
      align-self: start;

      &:before { //workaround to line up search button
        content: '';
        height: $_search-form-v2-label-height; //match label height
        display: block;
      }

      @media (max-width: $screen-sm) {
        &:before {
          content: none;
        }
      }
    }
  }

  .grid-search-room,
  .grid-search-activity.has-travelers {
    grid-template-columns: repeat(2, 30%) 1fr $_search-btn-width;
    grid-template-areas: "grid-destination grid-date grid-travelers grid-btn-search";

    @media (min-width: $screen-sm) and (max-width: $screen-md) {
      grid-template-columns: auto;
      grid-template-areas:
        "grid-destination grid-destination grid-destination"
        "grid-date grid-travelers grid-btn-search";
    }

    @media (max-width: $screen-sm) {
      display: block;
      > * + * {
        margin-top: $_search-form-gap;
      }
    }
  }

  .grid-search-car,
  .grid-search-room-car,
  .grid-search-air,
  .grid-search-air-car,
  .grid-search-air-room-car {
    .input-daterange {
      .grid {
        @include grid-auto-columns(50px);
      }
    }
  }

  .grid-search-car {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "grid-origin grid-destination"
      "grid-date grid-date"
      "grid-has-btn grid-has-btn";

    .grid-has-btn {
      display: grid;
      gap: $_search-form-gap;
      grid-template-areas: "grid-driver-age grid-btn-search";
    }

    .grid-drivers-age {
      flex: 1;
      display: flex;
    }

    @media (max-width: 600px) {
      display: block;
      > * + * {
        margin-top: $_search-form-gap;
      }

      .input-daterange {
        display: block;

        > .grid + .grid {
          margin-top: $_search-form-gap;
        }
      }

      .grid-has-btn {
        grid-template-areas:
          "grid-driver-age"
          "grid-btn-search";
      }
    }
  }

  .grid-search-activity {
    grid-template-columns: repeat(2, 1fr) $_search-btn-width;
    grid-template-areas: "grid-destination grid-date grid-btn-search";

    @media (max-width: $screen-sm) {
      display: block;
      > * + * {
        margin-top: $_search-form-gap;
      }
    }
  }

  /*
  #search-form-advanced-options > .grid-advanced-options appears when "Advanced Search Option Fields" is configured.
  When it is set to 'none', .grid-search-options appears outside of #search-form-advanced-options
  */
  .grid-search-air {
    .grid-btn-search {
      align-self: end;
    }

    &.one-way {
      .grid-search-options { //only define this area if it's used
        grid-area: grid-search-options;
      }

      //one way, no time, no advanced options
      grid-template-columns: repeat(5, 1fr);
      grid-template-areas:
        "grid-row-air grid-row-air grid-row-air grid-date grid-date"
        "grid-travelers grid-travelers . . grid-btn-search"
        "grid-search-options grid-search-options grid-search-options grid-search-options grid-search-options";

      @media (max-width: $screen-sm) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas:
        "grid-row-air grid-row-air grid-date"
        "grid-travelers grid-travelers grid-travelers"
        "grid-search-options grid-search-options grid-search-options"
        "grid-btn-search grid-btn-search grid-btn-search";

        .grid-search-options {
          flex-wrap: wrap;
        }
      }

      @media (max-width: $screen-xs) {
        grid-template-columns: 1fr;
        grid-template-areas:
        "grid-row-air"
        "grid-date"
        "grid-travelers"
        "grid-search-options"
        "grid-btn-search";

        .grid-row-air {
          grid-template-columns: 1fr;
          gap: $_search-form-gap;
          grid-template-areas:
          "grid-departure"
          "grid-destination"
        }
      }

      &.search-form-has-time { // Display Departure and Return Times is set to true
        .grid-search-options {
          grid-area: grid-search-options;
        }

        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
        "grid-row-air grid-date"
        "grid-travelers grid-btn-search"
        "grid-search-options grid-search-options";

        @media (max-width: $screen-sm) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-areas:
          "grid-row-air grid-row-air"
          "grid-date grid-date"
          "grid-travelers grid-travelers"
          "grid-search-options grid-search-options"
          "grid-btn-search grid-btn-search";
        }
      }

      &.has-advanced-air-search { //no time
        .grid-search-options {
          grid-area: grid-search-options;
        }

        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
        "grid-row-air grid-row-air grid-date grid-date"
        "grid-travelers grid-travelers . ."
        "grid-search-options grid-search-options grid-search-options grid-btn-search";

        @media (max-width: $screen-sm) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-areas:
            "grid-row-air grid-row-air"
            "grid-date grid-date"
            "grid-travelers grid-travelers"
            "grid-search-options grid-search-options"
            "grid-btn-search grid-btn-search";
        }
      }
    }

    &.roundtrip {
      //round-trip, no time, no advanced options
      grid-template-columns: repeat(3, 1fr) $_search-btn-width;
      grid-template-areas:
        "grid-row-air grid-row-air grid-row-air grid-row-air"
        "grid-date grid-date grid-travelers grid-btn-search";

      @media (max-width: $screen-sm) {
        .grid-search-options {
          flex-wrap: wrap;
        }

        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
          "grid-row-air grid-row-air grid-row-air grid-row-air"
          "grid-date grid-date grid-date grid-date"
          "grid-travelers grid-travelers grid-travelers grid-travelers"
          "grid-search-options grid-search-options grid-search-options grid-search-options"
          "grid-btn-search grid-btn-search grid-btn-search grid-btn-search";

        &.search-form-has-time {
          .input-daterange {
            flex-direction: column;
            align-items: normal;
          }
        }
      }

      &.search-form-has-time {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
        "grid-row-air grid-row-air grid-row-air grid-row-air"
        "grid-date grid-date grid-date grid-date"
        "grid-travelers grid-travelers . grid-btn-search";

        @media (max-width: $screen-sm) {
          grid-template-areas:
          "grid-row-air grid-row-air grid-row-air grid-row-air"
          "grid-date grid-date grid-date grid-date"
          "grid-travelers grid-travelers grid-travelers grid-travelers"
          "grid-btn-search grid-btn-search grid-btn-search grid-btn-search";
        }
      }

      &.has-advanced-air-search { //no time
        .grid-search-options {
          grid-area: grid-search-options;
        }

        grid-template-areas:
        "grid-row-air grid-row-air grid-row-air grid-row-air"
        "grid-date grid-date grid-travelers grid-travelers"
        "grid-search-options grid-search-options grid-search-options grid-btn-search";

        @media (max-width: $screen-sm) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-areas:
            "grid-row-air grid-row-air"
            "grid-date grid-date"
            "grid-travelers grid-travelers"
            "grid-search-options grid-search-options"
            "grid-btn-search grid-btn-search";
        }

        &.search-form-has-time {
          grid-template-areas:
          "grid-row-air grid-row-air grid-row-air grid-row-air"
          "grid-date grid-date grid-date grid-date"
          "grid-travelers grid-travelers . ."
          "grid-search-options grid-search-options grid-search-options grid-btn-search";

          @media (max-width: $screen-sm) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas:
            "grid-row-air grid-row-air"
            "grid-date grid-date"
            "grid-travelers grid-travelers"
            "grid-search-options grid-search-options"
            "grid-btn-search grid-btn-search";
          }
        }
      }
    }

    &.multi-city {
      display: block;

      > [class*="row-air-search-"] { //Flight legs
        display: grid;
        row-gap: 0;
        column-gap: $_search-form-gap;
        margin-bottom: $_search-form-gap;
        @include grid-auto-columns(100px);
        grid-template-areas:
          "grid-header grid-header ."
          "grid-departure grid-destination grid-date";

        @media (max-width: $screen-sm) {
          grid-template-areas:
          "grid-header grid-header"
          "grid-departure grid-destination"
          "grid-date grid-date";

          .input-daterange {
            margin-top: $_search-form-gap;
          }
        }
      }

      &.has-advanced-air-search { //no time
        .grid-air-bottom {
          .grid-search-options {
            grid-area: grid-search-options;
          }

          @extend %search-grid;
          margin-top: $_search-form-gap;
          grid-template-columns: repeat(4, 1fr);
          grid-template-areas:
            "grid-travelers grid-travelers . ."
            "grid-search-options grid-search-options grid-search-options grid-btn-search";

          @media (max-width: $screen-sm) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas:
              "grid-travelers grid-travelers"
              "grid-search-options grid-search-options"
              "grid-btn-search grid-btn-search";

            .grid-search-options {
              flex-wrap: wrap;
            }
          }
        }
      }

      .grid-air-bottom {
        .grid-search-options {
          grid-area: grid-search-options;
        }

        @extend %search-grid;
        margin-top: $_search-form-gap;
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
        "grid-travelers grid-travelers . ."
        "grid-search-options grid-search-options grid-search-options  grid-btn-search";

        @media (max-width: $screen-sm) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-areas:
          "grid-travelers grid-travelers"
          "grid-search-options grid-search-options"
          "grid-btn-search grid-btn-search";

          .grid-search-options {
            flex-wrap: wrap;
          }
        }
      }
    }
  }

  .grid-search-room-car {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "grid-destination grid-destination grid-travelers grid-travelers"
      "grid-date grid-date grid-date grid-date"
      "grid-driver-age grid-driver-age grid-driver-age grid-btn-search";

    @media (max-width: $screen-sm) {
      display: block;
      > * + * {
        margin-top: $_search-form-gap;
      }

      .input-daterange {
        flex-wrap: wrap;

        > .flex-1 {
          flex: 40%;
        }
      }
    }
  }

  .grid-search-air-car,
  .grid-search-air-room-car {
    .grid-search-options {
      grid-area: grid-search-options;
    }

    &.no-time { //Display Departure and Return Times is DISABLED
      grid-template-columns: repeat(4, 1fr);
      grid-template-areas:
      "grid-row-air grid-row-air grid-row-air grid-row-air"
      "grid-date grid-date grid-travelers grid-travelers"
      "grid-search-options grid-search-options grid-search-options grid-btn-search";

      @media (max-width: $screen-sm) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
          "grid-row-air grid-row-air"
          "grid-date grid-date"
          "grid-travelers grid-travelers"
          "grid-search-options grid-search-options"
          "grid-btn-search grid-btn-search";

        .input-daterange > .grid {
          grid-template-columns: 1fr;
        }
      }
    }

    &.search-form-has-time { //Display Departure and Return Times is ENABLED
      grid-template-columns: repeat(4, 1fr);
      grid-template-areas:
        "grid-row-air grid-row-air grid-row-air grid-row-air"
        "grid-date grid-date grid-date grid-date"
        "grid-travelers grid-travelers . ."
        "grid-search-options grid-search-options grid-search-options grid-btn-search";

      @media (max-width: $screen-sm) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
          "grid-row-air grid-row-air"
          "grid-date grid-date"
          "grid-travelers grid-travelers"
          "grid-search-options grid-search-options"
          "grid-btn-search grid-btn-search";

        .input-daterange > .grid {
          grid-template-columns: 1fr;
        }
      }
    }

    .grid-advanced-options {
      gap: $_search-form-gap;

      .grid-car-type {
        flex: 1;
      }
    }
  }

  .grid-search-air-room {
    .grid-btn-search {
      align-self: end;
    }

    &.search-form-has-time {
      grid-template-columns: repeat(4, 1fr);
      grid-template-areas:
        "grid-departure grid-departure grid-destination grid-destination"
        "grid-date grid-date grid-date grid-date"
        "grid-travelers grid-travelers . grid-btn-search";

      &.has-partial-room {
        .grid-search-options {
          grid-area: grid-search-options;
        }

        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
        "grid-departure grid-departure grid-destination grid-destination"
        "grid-date grid-date grid-date grid-date"
        "grid-travelers grid-travelers . ."
        "grid-search-options grid-search-options grid-search-options grid-btn-search";
      }

      &.has-advanced-air-search {
        .grid-search-options {
          grid-area: grid-search-options;
        }

        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
        "grid-departure grid-departure grid-destination grid-destination"
        "grid-date grid-date grid-date grid-date"
        "grid-travelers grid-travelers . ."
        "grid-search-options grid-search-options grid-search-options grid-btn-search";
      }
    }

    &.no-time {
      .grid-search-options { //only define this area if it's used
        grid-area: grid-search-options;
      }

      grid-template-columns: repeat(4, 1fr);
      grid-template-areas:
        "grid-departure grid-departure grid-destination grid-destination"
        "grid-date grid-date grid-travelers grid-travelers"
        "grid-search-options grid-search-options grid-search-options grid-btn-search";
    }

    @media (max-width: $screen-sm) {
      display: block;
      > * + * {
        margin: $_search-form-gap 0;
      }

      .input-daterange {
        display: block;

        > * + * {
          margin: $_search-form-gap 0;
        }
      }
    }
  }

  /* ------ Modify Search ------- */
  #modify-search-modal-body {
    .grid-search-room { //needs separate layout than home
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
        "grid-destination grid-destination"
        "grid-date grid-date"
        "grid-travelers .";
    }

    .grid-search-activity { //modify search only needs 2 columns
      grid-template-columns: auto;
      grid-template-areas: "grid-destination grid-date";
    }

    .grid-partial-room {
      display: block; //accommodate checkin/out dates
    }
  }

  #modify-search-modal-body #search-form-travelers-selection {
    position: initial;
    max-height: initial;
    width: auto;
  }

  #modify-search-modal-body .group-validation-target.error + #search-form-travelers-selection {
    margin-top: initial;
  }

  /* ------ Traveler Select Drop Down ------- */
  #search-form-travelers-selection {
    position: absolute;
    background-color: #fff;
    z-index: 10000;
    border-radius: $border-radius-base;
    box-shadow: $shadow-lg;
    margin-top: $padding-base-vertical;
    max-height: $tt-dropdown-max-height;
    overflow-x: auto;
    width: 350px;
    padding: $search-form-section-padding;

    .grid-container {
      display: grid;

      > label {
        margin-top: $padding-large-vertical;
      }
    }

    > .grid-container,
    .room-card > .grid-container {
      grid-template-columns: repeat(2, 1fr);
    }

    .room-card + .room-card {
      border-top: 1px solid $base-border-color;
    }

    .room-card {
      padding: $padding-large-vertical 0;
      margin-bottom: 0;

      h3:first-of-type {
        margin-top: 0;
      }
    }

    .child-ages-container {
      display: grid;
      grid-column: 1 / span 2;
      grid-template-columns: repeat(2, 1fr);
      gap: $_search-form-gap;
    }

    .child-age-alert {
      grid-column: 1 / span 2;
    }

    .number-stepper {
      justify-content: end;
    }

    .group-validation-target.error & {
      margin-top: -30px;
    }
  }

  .search-form-specials {
    display: grid;
    grid-template-areas:
      "grid-date"
      "grid-travelers"
      "grid-has-btn";
    gap: $_search-form-gap;
  }

  div.datepicker-calendar {
    margin-top: $_search-form-gap;
  }

  .group-validation-target.error + #search-form-travelers-selection {
    margin-top: -30px; //pull up to overlap validation message

    #modify-search-modal & { //reset traveler popover
      position: relative;
      width: auto;
    }
  }
}