.number-stepper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: $number-stepper-flex-align;

  .btn {
    padding: 0;
    border: 0 !important;
    border-radius: 0;
    line-height: inherit;
    color: $btn-primary-bg;
    background: transparent !important;

    &:hover,
    &:focus,
    &:active {
      color: $number-stepper-bg-color-hover; // We don't want to always use $btn-primary-bg-hover in case it's too light
    }

    &.disabled {
      background: transparent !important;
      i:before {
        color: $number-stepper-icon-color-disabled;
      }

      &:hover,
      &:focus,
      &:active {
        background: transparent !important;
      }
    }

    &.disabled {
      .icon-product__remove--outline:before {
        content: $icon-product__remove--outline;
      }

      .icon-product__add--outline:before {
        content: $icon-product__add; // icon-product__add
      }
    }
  }

  i {
    font-size: $number-stepper-icon-size;

    &:hover, &:active, &:focus {
      text-decoration: none;
    }

  }

  label {
    width: auto;
    height: calc(#{$btn-font-size} * 2);
  }

  input {
    width: 3em;
    text-align: center;
    border: 0;
    background-color: transparent !important;
    outline: none;
    box-shadow: none;
    font-family: inherit;
    font-size: inherit;
    cursor: default;
    margin-bottom: 0;

    &[type="text"][disabled] {
      -webkit-text-fill-color: $input-color;
      -webkit-opacity: 1;
    }
  }
}