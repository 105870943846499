/* DashCart Styles - Appears at the top of search-results pages */

#dashcart {

  background: $dashcart-background-color;
  padding-bottom: 0;
  border-bottom: 0;
  z-index: 9; // Should layer over page contents but not over modals

  .section-xsell header {
    background: $dashcart-product-header-background-color-xsell;
  }

  &.affix {
    @include box-shadow(0 10px 5px rgba(0, 0 ,0 ,.3));
    width: 100%;
    left: 0;
    top: 0;
    padding-top: 3px;

    > .container > .row {
      margin: 0 ($grid-gutter-width / 2);
    }

  }

  &.dashcart-summary {
    border-bottom: 0 !important;
  }

  .dashcart-header .panel-heading .btn {
    border-radius: $dashcart-header-panel-border-radius;
  }

  .dashcart-footer .btn {
    border-radius: $dashcart-footer-btn-radius;
  }

  #checkout-button[disabled] {
    display: none;
  }

  .dashcart-header a > .icon {
    display: none; // shown for handheld case in app-responsive
  }

  .dashcart-header > {
    [class*="col-"] {
      &:first-of-type {
        border-top: 1px solid transparent;
        border-bottom: 1px solid $base-border-color;
      }
      &:last-of-type {
        border: 1px solid $base-border-color;
        border-bottom: 0;
      }

    }
    .panel-heading {
      border-bottom: 0;
      padding: 0;

      > button {
        background: $dashcart-toggle-open-background-color;
        color: $dashcart-toggle-open-text-color;
        border-color: $dashcart-toggle-border-color;

        span {
          padding-left: 3px;
        }

        span,
        .icon {
          color: $dashcart-toggle-open-text-color;
        }

        &:hover {
          background: $dashcart-toggle-open-background-color-hover;
          color: $dashcart-toggle-open-text-color-hover;

          span,
          .icon {
            color: $dashcart-toggle-open-text-color-hover;
          }
        }
      }

      > .collapsed {
        background: $dashcart-toggle-collapsed-background-color;
        color: $dashcart-toggle-collapsed-text-color;

        span,
        .icon {
          color: $dashcart-toggle-collapsed-text-color;
        }

        &:hover {
          background: $dashcart-toggle-collapsed-background-color-hover;
          color: $dashcart-toggle-collapsed-text-color-hover;

          span,
          .icon {
            color: $dashcart-toggle-collapsed-text-color-hover;
          }
        }
      }
    }
  }

  .dashcart-body,
  .dashcart-footer {
    border: 1px solid $base-border-color;
  }

  .dashcart-footer {
    border-radius: $dashcart-footer-border-radius;
    padding: $dashcart-footer-padding;
  }

  .dashcart-body {
    border-bottom: 0;
  }

  .product-unit {
    background: $dashcart-product-unit-background-color;
    padding: 0;
    margin-bottom: $padding-large-vertical;
  }

  .product-body {
    background: $dashcart-product-background-color;
    margin-bottom: 5px;
  }

  .product-summary {

    > .product-header,
    > div {
      padding: 5px 10px;
    }

    .product-header {
      margin: 5px 0;
      border-bottom:1px solid $gray-lighter;
      font-weight: $text-strong-font-weight;
      background: $dashcart-product-header-background-color;
    }

    &.product-unchosen .product-header {
      background: $dashcart-product-unchosen-header-background-color;
    }

    .flex-product {
      flex: 0 1 25%;
    }

  }

  .supplier {
    font-weight: $text-strong-font-weight;
  }

  .dashcart-toggle.in {
    max-height: 370px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .is-standalone-activity-path {
    // Only add scrolling content in product cards for when it's a single product path (like activity only) - this is required for layout
    .product-body {
      height: 210px;
      overflow-x: hidden;
      overflow-y: auto;
      margin-bottom: 20px;
    }
  }

  #trip-total-price {
    font-weight: $trip-summary-total-price-font-weight;

    > span {
      font-weight: $trip-summary-total-price-value-font-weight;
    }
  }
}

@import "_add-product-check";
