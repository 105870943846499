//
// Pagination (multiple pages)
// --------------------------------------------------
.pagination {
  display: flex;
  justify-content: $pagination-justify-content;
  padding-left: 0;
  margin: $line-height-computed 0;
  border-radius: $pagination-outer-border-radius;

  > li {
    display: $pagination-item-display;
    margin-right: $pagination-item-margin-right;
    &:last-of-type {
      padding-left: 0;
    }
    &:nth-last-child(2) {
      margin-right: 0;
    }
    // Remove list-style and block-level defaults
    > a {
      width: $pagination-size;
      height: $pagination-size;
      border-radius: $pagination-item-border-radius;
      text-align: $pagination-text-align;
    }
    > a,
    > span {
      position: relative;
      display: inline-block;
      padding: $padding-base-vertical $padding-base-horizontal;
      line-height: $line-height-base;
      text-decoration: none;
      background-color: $pagination-bg;
      color: $pagination-color;
      border: 1px solid $pagination-border;
      margin-left: -1px;
    }
    &:first-child {
      > a,
      > span {
        margin-left: 0;
        @include border-left-radius($pagination-outer-border-radius);
      }
    }
    &:last-child {
      > a,
      > span {
        @include border-right-radius($pagination-outer-border-radius);

        &:hover,
        &:focus {
          background-color: transparent;
        }
      }
    }
    &.disabled {
      > .icon {
        color: $pagination-disabled-icon-color;
      }
    }
  }

  > li > a,
  > li > span {
    &:hover,
    &:focus {
      background-color: $pagination-hover-bg;
      color: $pagination-hover-color;
      border-color: $pagination-hover-bg;
    }
  }

  > li:first-of-type a:hover,
  > li:last-of-type a:hover {
    background-color: $pagination-arrows-bg-hover-color;
    border-color: $pagination-arrows-border-hover-color;
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
      z-index: 2;
      color: $pagination-active-color;
      background-color: $pagination-active-bg;
      border-color: $pagination-active-bg;
      cursor: default;
    }
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
      color: $pagination-disabled-color;
      background-color: $pagination-bg;
      border-color: $pagination-border;
      cursor: not-allowed;
    }
  }
}

// Sizing
// --------------------------------------------------

// Large
.pagination-lg {
  @include pagination-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $border-radius-large);
}

// Small
.pagination-sm {
  @include pagination-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $pagination-border-radius-small);
}
