@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $checkloader-check-width;
    opacity: 1;
  }
  40% {
    height: $checkloader-check-height;
    width: $checkloader-check-width;
    opacity: 1;
  }
  100% {
    height: $checkloader-check-height;
    width: $checkloader-check-width;
    opacity: 1;
  }
}

.circle-loader {
  margin: 0 auto;
  border: $checkloader-check-thickness solid rgba(0, 0, 0, 0.2);
  border-left-color: $checkloader-check-color;
  @include animation(loader-spin $checkloader-spin-time infinite linear);
  position: relative;
  display: inline-block;
  vertical-align: top;

  &.load-complete {
    @include animation(none);
    border-color: $checkloader-check-success-color;
    @include transition(border 500ms ease-out);

    .checkmark {
      display: block;
    }
  }

  .checkmark {
    display: none;

    &.draw:after {
      @include animation-duration(800ms);
      @include animation-timing-function(ease);
      @include animation-name(checkmark);
      @include transform(scaleX(-1) rotate(135deg));
    }

    &:after {
      opacity: 1;
      height: $checkloader-check-height;
      width: $checkloader-check-width;
      @include transform-origin(left top);
      border-right: $checkloader-check-thickness solid $checkloader-check-success-color;
      border-top: $checkloader-check-thickness solid $checkloader-check-success-color;
      content: '';
      left: $checkloader-check-left;
      top: $checkloader-check-height;
      position: absolute;
    }
  }
}

.circle-loader,
.circle-loader:after {
  border-radius: 50%;
  width: $checkloader-size;
  height: $checkloader-size;
}
