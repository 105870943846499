@import "_switchfly-colors",
        "_bootstrap-shim";

.status-bars-container {

  // NOTE: Colors in this file must meet accessibility color contrast requirements; please see Scott G for details

  div[class*='bar'] {
    padding: 5px 0;
    border-bottom: 1px solid #495057;
    background: #fff;
    text-align: center;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif !important;

    ul {
      margin: 0;
    }
    li {
      padding: 0;
    }

  }

  div.test-bar {
    background: #fd7e14;
    color: #1b1b1b;
    @extend .text-center;
    margin-top: 0;
    margin-bottom: 0;
  }

  .swf-stubs & {
    div.test-bar {
      background: #acabfa;
      font-weight: bold;
    }
  }

  div.test-bar-payment {
    background: #da291c;
    color: #fff;
  }

  .modification-status-bar ul > li {
    > a {
      padding: 0 10px 0 5px;
      border-right: 1px solid #333;
    }

    &:last-of-type > a {
      border-right: 0;
      padding-right: 0;
    }

  }

  #MOD {
    @extend .alert-info;
  }

}
