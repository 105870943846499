// Empty State Skeleton Builder
// Modified from https://github.com/wildhaber/empty-state

@mixin skeleton-setup($reflection: animate) {
  content: '';
  display: block;
  height: 100vh;
  margin-top: $padding-large-horizontal;
  animation: $reflection 2s infinite;
}

@function shape($background, $background-size, $background-position) {
  @return (
    bg: $background,
    size: $background-size,
    position: $background-position
  );
}

@function rectangle($color, $size, $position) {
  @return shape(
    (linear-gradient(180deg, $color, $color)),
    ($size),
    ($position)
  );
}

@function animate($rgba, $width, $height, $x, $y) {
  @return shape(
    ($rgba),
    ($width $height),
    ($x $y)
  );
}

@function rect-outline($color, $width, $height, $x, $y, $outline: 1px, $fill: #ffffff) {

  $outline-y: $y;
  $outline-x: $x;

  @if (type-of($x) == number) {
    $outline-x: calc(#{$x} + #{$outline});
  }

  @if (type-of($y) == number) {
    $outline-y: calc(#{$y} + #{$outline});
  }

  @return (
    rectangle($fill, calc(#{$width} - #{$outline * 2}) calc(#{$height} - #{$outline * 2}), $outline-x $outline-y),
    rectangle($color, $width $height, $x $y)
  );
}

@function circle($color, $diameter, $x, $y) {
  @return shape(
    (radial-gradient(circle at center, $color 0, $color 50%, transparent 50%, transparent 100%)),
    ($diameter $diameter),
    ($x $y)
  );
}

@function lines($count: 3, $color: #fcfcfc, $width: 100%, $height: 10px, $gutter: 10px, $x: 0, $y: 0, $lastWidth: 100%, $outline: false, $outline-width: 1px, $outline-fill: #ffffff) {
  $lines: ();
  @for $i from 0 to $count {
    $offset-lines: 0;
    $line-width: $width;

    @if ($i > 0) {
      $offset-lines: ($height * $i);
    }

    @if ($i == ($count - 1)) {
      $line-width: $lastWidth;
    }

    $offset-y: ($gutter * $i) + $offset-lines + $y;

    $outlinedRect: rect-outline($color, $line-width, $height, $x, $offset-y, $outline-width, $outline-fill);

    @if ($outline) {
      $lines: append($lines, nth($outlinedRect, 1));
    }

    $lines: append($lines, nth($outlinedRect, 2));
  }

  @return $lines;
}

@mixin skeleton($shapes...) {

  $background-list: ();
  $background-size-list: ();
  $background-position-list: ();

  @each $shape in $shapes {
    $background-list: append($background-list, map-get($shape, bg), 'comma');
    $background-size-list: append($background-size-list, map-get($shape, size), 'comma');
    $background-position-list: append($background-position-list, map-get($shape, position), 'comma');
  }

  background: $background-list;
  background-size: $background-size-list;
  background-position: $background-position-list;
  background-repeat: no-repeat;
}
