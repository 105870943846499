#ui-patterns-page {

  padding-top: $line-height-computed;

  .nav-pills {
    margin-bottom: $line-height-computed;
  }

  .bs-example {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
  }

  section {
    margin-bottom: $line-height-computed;
    padding-bottom: $line-height-computed;
    border-bottom: 1px dotted #999;
  }

  .alert h3 {
    margin: 0;
    font-size: 130%;
  }

  .grid-sample [class^=col-] {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #eee;
    background-color: rgba(86,61,124,.15);
    border: 1px solid rgba(86,61,124,.2);
    text-align: center;
  }

  .well  {

    .row-large {
      padding: 10px 0;

      .text-small {
        clear: both;
        padding-top: 10px;
      }
    }

    h3 {
      margin: 0;
    }

    div:last-of-type form {
      margin-bottom: 0;
    }

    .text-divider {
      @include hrText($well-bg);
    }

    .or {
      text-transform: uppercase;
    }
  }

  .color-swatch {
    height: 50px;
    width: 50px;
    margin-right: 5px;
    border: 1px solid #ccc;

  }

}