.col-flexbox-container {
  @include flexbox;
  align-items: center;

  > li {
    flex-basis: 0;

    &.flex-start {
      align-self: flex-start;
    }
  }
}

.flex-valign-middle {
  @include flexbox;
  align-items: center;
}


@media(max-width: $screen-sm) { //for a11y
  .flex-col-xs,
  .flex-group.flex-col-xs {
    flex-direction: column;
    hyphens: auto;
  }
}

.flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;

  &.gap {
    gap: $flex-gap;
  }

  &.gap-xs {
    gap: $flex-gap / 3;
  }

  &.gap-sm {
    gap: $flex-gap / 2;
  }

  &.flex-col {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  &.flex-row {
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }

  &.align-items-center {
    align-items: center;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.justify-center {
    justify-content: center;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.row-wrap {
    flex-flow: row wrap;
  }

  &.baseline {
    align-items: baseline;
  }

  .align-center {
    align-content: center;
  }

  .flex-grow {
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .align-self-start {
    align-self: flex-start;
  }

  .flex-align-top {
    flex: 1;
    align-self: flex-start;
  }

  .flex-align-bottom {
    flex: 1;
    align-self: flex-end;
  }

  .flex-col2 {
    flex-direction: column;
    flex: 1 0 50%;
  }
}

.flex-baseline {
  @include flexbox;
  align-items: baseline;
}

.flex-justify-content-end {
  justify-content: flex-end;
}

// USAGE: for centering container but keeping text aligned left
.flex-center-left {
  @include flexbox;
  text-align: left;
  justify-content: center;

  .flex-center-container {
    @include flexbox;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
  }
}

// SG: Unsure how the above works within Aircomm and don't want to break anything, so adding the below for usages in the rest of the app.
// USAGE: .flex-aligner is the container around elements you wish to flex align; default alignment is center,
// use .flex-align-* classes to align top or bottom from there.

.flex-aligner {
  display: flex;
  align-items: center;
  justify-content: center;

  .flex-align-top {
    flex: 1;
    align-self: flex-start;
  }

  .flex-align-bottom {
    flex: 1;
    align-self: flex-end;
  }
}

// Flex Helpers

.flex-group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &.flex-col {
    flex-direction: column;
  }

  > * {
    min-width: 0;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow-none {
  flex-grow: unset !important;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

.flex-7 {
  flex: 7;
}

.flex-8 {
  flex: 8;
}

.flex-9 {
  flex: 9;
}

.flex-10 {
  flex: 10;
}

.flex-11 {
  flex: 11;
}

.flex-self-top {
  align-self: self-start;
}

.flex-self-bottom {
  align-self: flex-end;
}

.flex-self-middle {
  align-self: center;
}

.flex-items-top {
  align-items: flex-start;
}

.flex-items-middle {
  align-items: center;
}

.flex-items-bottom {
  align-items: flex-end;
}