/* SHOPPING PAGE */
// Extends main-contents-container

#shopping-page-container {
  position: relative;

  .main-header {

    .form-group {
      margin-bottom: 0;
    }

    ul {
      margin: $line-height-computed 0 0;

      > li > a {
        padding: 10px;
      }

    }
  }

  .aside-header {
    vertical-align: middle;
  }

  .map-view-toggle {
    cursor: pointer;
    position: relative;
    display: inline-block;
    border-radius: $hotel-view-toggle-border-radius;
    overflow: hidden;

    img {
      max-height: 132px;
      width: 100%;
      border-radius: $hotel-view-toggle-border-radius;
    }

    .toggle-list-view {
      width: 100%;
      @include box-shadow($shadow-sm);
    }

    .toggle-view-label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  #air-fare-rules-modal-label-body .fare-rule-polices > strong,
  #air-product-tab-pane .dash-cart-policies-air-pill-pane .policy > strong,
  #itinerary-policies-modal-body .p > strong {
    font-size: 120%;
    line-height: 1.7;
  }

  #shopping-cart {

    .component-section {
      border-bottom: none;
      padding-bottom: 0;
    }

  }

  // Overlay for product list when filter updates
  .products-loading-overlay:after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    background: $products-loading-overlay-bg-color;
    position: absolute;
    top: 0;
  }

  .price-total .or:after {
    content: "";
    display: inline;
  }

}

#search-results-form-container {
  > .ember-view:first-of-type > h2 {
    margin-top: 0;
    // Fixes alignment issue for activities list for first sub-category result heading.
  }

  .map-legend-wrapper {
    margin: 1em 0;
    color: $brand-primary;
  }
}

