//
// Buttons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

// Core styles

.btn {
  display: inline-block;
  padding: $btn-padding-vertical $btn-padding-horizontal;
  margin-bottom: 0;
  // For input.btn
  font-size: $btn-font-size;
  letter-spacing: $btn-letter-spacing;
  line-height: $line-height-base;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  font-family: $btn-font-family;
  // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: $btn-border-width solid currentColor;
  border-radius: $btn-border-radius;
  white-space: normal;
  @include user-select(none);

  &:not(.btn-link) {
    font-weight: $btn-font-weight;
  }

  &:focus-visible {
    @include tab-focus($brand-primary);
  }

  &:hover {
    color: $btn-default-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
    pointer-events: none; // Future-proof disabling of clicks
    @include box-shadow(none);
  }

  &.btn-search-pill {
    font-size: $btn-search-pill-font-size;
    border-radius: $btn-search-pill-border-radius;
    padding: $btn-search-pill-padding;
    border: $btn-search-pill-border-inactive;
    color: $btn-search-pill-inactive-color;
  }
}

.close:focus {
  @include tab-focus();
}

// Alternate buttons
// --------------------------------------------------

.btn-default {
  @include c-button-variant($btn-default-color, $btn-default-bg, $btn-default-border, $btn-default-color-hover, $btn-default-bg-color-hover, $btn-default-border-hover,
          $btn-default-color-active, $btn-default-bg-color-active, $btn-default-border-active);
}

.btn-primary {
  @include c-button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border, $btn-primary-color-hover, $btn-primary-bg-color-hover, $btn-primary-border-hover,
          $btn-primary-color-active, $btn-primary-bg-color-active, $btn-primary-border-active);
}

.btn-secondary {
  @include c-button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border, $btn-secondary-color-hover, $btn-secondary-bg-color-hover, $btn-secondary-border-hover,
          $btn-secondary-color-active, $btn-secondary-bg-color-active, $btn-secondary-border-active);
}

.btn-search-pill {
  @include c-button-variant($btn-search-pill-color, $btn-search-pill-bg, $btn-search-pill-border, $btn-search-pill-color-hover, $btn-search-pill-bg-color-hover, $btn-search-pill-border-hover,
          $btn-search-pill-color-active, $btn-search-pill-bg-color-active, $btn-search-pill-border-active);
}

.btn-toggle {
  @include c-button-variant($btn-toggle-color, $btn-toggle-bg, $btn-toggle-border, $btn-toggle-color-hover, $btn-toggle-bg-color-hover, $btn-toggle-border-hover,
          $btn-toggle-color-active, $btn-toggle-bg-color-active, $btn-toggle-border-active);
}

.btn-switch {
  @include c-button-variant($btn-switch-color, $btn-switch-bg, $btn-switch-border, $btn-switch-color-hover, $btn-switch-bg-color-hover, $btn-switch-border-hover,
          $btn-switch-color-active, $btn-switch-bg-color-active, $btn-switch-border-active);
}

.btn-switch[aria-checked='true'],
.btn-switch.active {
  background: $btn-switch-bg-color-active;
  color: $btn-switch-color-active;
  border-color: $btn-switch-border-active;
}

// Removes the hover state on handheld
.case-handheld {
    .btn-default {
      background-color: $btn-default-bg;
      color: $btn-default-color;
      &:active,
      &.active {
        background-color: $btn-default-bg-color-active;
        color: $btn-default-color-active;
      }
    }

    .btn-primary {
      background-color: $btn-primary-bg;
      color: $btn-primary-color;
      &:active,
      &.active {
        background-color: $btn-primary-bg-color-active;
        color: $btn-primary-color-active;
      }
    }

    .btn-secondary {
      background-color: $btn-secondary-bg;
      color: $btn-secondary-color;
      &:active,
      &.active {
        background-color: $btn-secondary-bg-color-active;
        color: $btn-secondary-color-active;
      }
    }
}

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: $link-color;
  font-weight: normal;
  font-size: $btn-link-font-size;
  font-family: inherit;
  cursor: pointer;
  border-radius: 0;
  padding: 0; // By default, btn-link elements are buttons that LOOK like links, and so shouldn't have btn padding
  vertical-align: inherit;
  text-decoration: $btn-link-text-decoration;

  &.flex-left {
    display: flex;
    text-align: left;
    gap: 5px;
  }

  &,
  &:active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    @include box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: $btn-link-border-color;
    border-width: $btn-link-border-width;
  }
  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: underline;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: $btn-link-disabled-color;
      text-decoration: none;
    }
  }
}

.aside-contents-container section {
  a,
  .btn-link {
    color: $aside-contents-section-link-color;

    &:hover,
    &:focus {
      color: $aside-contents-section-link-hover-color;
    }

  }
}

// Button Sizes
// --------------------------------------------------

.btn-xl {
  // Used for Desktop/Tablet search
  @include button-size($btn-lg-padding-vertical, $btn-xl-padding-horizontal, $btn-xl-font-size, $line-height-large, $btn-xl-border-radius);
}

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size($btn-lg-padding-vertical, $btn-padding-horizontal, $font-size-large, $line-height-large, $btn-lg-border-radius);
}

.btn-sm,
.btn-xs {
  // line-height: ensure proper height of button next to small input
  @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $btn-sm-border-radius);
}

.btn-xs {
  padding: $btn-xs-padding;
}

// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

.text-small .btn {
  font-size: $font-size-small;
}

.btn-image {
  padding: 0;
  border: 0;
}

.input-group-btn {
  background: $gray-lighter;
  border: 1px solid $input-border;
  border-left: 0;

  > .btn {
    border: 0;
    padding: 0 $padding-base-horizontal;
  }
}

.btn:not(.btn-text-input),
a {
  &.disabled,
  &[disabled]:not([disabled='false']),
  &:disabled {
    color: $btn-disabled-color !important;
    background: $btn-disabled-bg-color !important;
    border-color: $btn-disabled-bg-color !important;
    pointer-events: none;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $btn-disabled-color !important;
      background: $btn-disabled-bg-color !important;
      border-color: $btn-disabled-bg-color !important;
    }
  }
}

.btn-group {
  > .btn {
    border-width: $btn-border-width;
  }

  &.btn-group-pills {
    > .btn {
      border-radius: 40px !important;
      margin-right: $padding-base-horizontal;
    }

    > .btn:last-of-type {
      margin-right: 0;
    }
  }
}

.btn-padding > [class*='btn'] {
  margin-right: $padding-large-horizontal;

  &:last-child {
    margin-right: 0;
  }
}

.btn-half {
  width: 50%;
}

button.close {
  position: relative;
  z-index: 9;
}

// OVERRIDES
// These styles must come last

.btn-padding {
  padding: $btn-padding-vertical $btn-padding-horizontal;
  // Some usages (e.g. .btn-link) do require the padding, for alignment.
}

.case-handheld {
  .btn:not(.btn-link) {
    font-size: $btn-font-size_handheld;
  }

  .btn-link {
    font-size: $btn-link-font-size_handheld;
  }

  .btn-group:not(.btn-hero-group):not(.btn-group-panel):not(.btn-group-pills) {
    margin-bottom: -.2em; // Offset for > .btn margin-bottom

    > .btn {
      border-width: $btn-border-width;
      border-radius: $btn-border-radius !important;

      &:not(:last-child) {
        margin-right: .5em; // add margin right except for last .btn
      }
    }
  }
}
