/*
 * sizes and positions
 */

div.datepicker-calendar {
  margin: 0 10px 10px 0;
  padding: 5px;
  position: absolute;
  border: 1px solid $datepicker-border-color;
  z-index: 50;
}

.case-base div.datepicker-calendar {
  width: 261px;
}

div.datepicker-calendar div.datepicker-month-wrap {
  margin: 0 0 $padding-base-vertical;
  padding-top: 1px;
  text-align: center;
  height: 30px;
}

div.datepicker-calendar div.datepicker-month-wrap .disabled {
  color: $datepicker-input-disabled-color;
  background-color: $datepicker-input-disabled-background-color;
}

div.datepicker-calendar div.datepicker-month-fast-prev.disabled,
div.datepicker-calendar div.datepicker-month-fast-next.disabled ,
div.datepicker-calendar div.datepicker-month-prev.disabled,
div.datepicker-calendar div.datepicker-month-next.disabled {
  border: none;
  cursor: default;
}

div.datepicker-calendar div.datepicker-month-fast-prev,
div.datepicker-calendar div.datepicker-month-fast-next,
div.datepicker-calendar div.datepicker-month-prev,
div.datepicker-calendar div.datepicker-month-next {
  cursor: pointer;
  color: $date-picker-active-link-color;
}

div.datepicker-calendar div.datepicker-month {
  text-align: center;
  font-weight: $text-strong-font-weight;
  font-size: 1.2em;
  cursor: pointer;
  padding-top: 1px;
  color: $text-color;
}

.datepicker-calendar table.datepicker-grid {
  width: 100%;
  font-size: 1.2em;
  text-align: center;
  margin-bottom: $padding-base-vertical;
  table-layout: $datepicker-table-layout; //prevents dates collapsing
  width: 100%;
}

div.datepicker-calendar table.datepicker-grid.rtl {
  direction: rtl;
}

div.datepicker-calendar table.datepicker-grid th,
div.datepicker-calendar table.datepicker-grid td{
  text-align: center;
  padding: 0;
}

div.datepicker-calendar table.datepicker-grid th abbr{
  border: none;
  text-decoration: none;
}

div.datepicker-calendar table.datepicker-grid td.unselectable {
  cursor: default;
}

div.datepicker-calendar table.datepicker-grid th,
div.datepicker-calendar table.datepicker-grid td.day {
  height: 30px;
}

div.datepicker-calendar table.datepicker-grid td.month {
  height: 55px;
}

div.datepicker-calendar table.datepicker-grid td.year {
  height: 45px;
}

div.datepicker-calendar .offscreen {
  position: absolute;
  left: -200em;
  top: -100em;
}

/****************************
 * colors
 ***************************/

/****************************
 * default theme colors
 ***************************/

div.datepicker-calendar.default {
  background-color: $datepicker-input-background-color;
  box-shadow: $datepicker-box-shadow;
}

div.datepicker-calendar.default tr.datepicker-weekdays {
  border-top:  $datepicker-thead-border;
  border-bottom:  $datepicker-thead-border;
}

div.datepicker-calendar.default table.datepicker-grid th {
  background-color: $datepicker-thead-background-color;
  color: $datepicker-thead-text-color;
}

div.datepicker-calendar.default table.datepicker-grid td {
  border: 1px solid $datepicker-day-border-color;
  color: $datepicker-day-text-color;
}

.case-handheld div.datepicker-calendar.default table.datepicker-grid td {
  padding: 7px;
}

div.datepicker-calendar.default table.datepicker-grid td.unselectable {
  background: $datepicker-date-unselectable-background-color;
  color: $datepicker-date-unselectable-text-color;
  border-radius: $datepicker-selected-date-border-radius;
}

div.datepicker-calendar.default table.datepicker-grid td.empty {
  background: $datepicker-date-empty-background-color;
  color: $datepicker-date-empty-text-color;
  border-radius: $datepicker-selected-date-border-radius;
}

div.datepicker-calendar.default table.datepicker-grid td.curDay,
div.datepicker-calendar.default table.datepicker-grid td.curMonth,
div.datepicker-calendar.default table.datepicker-grid td.curYear {
  background-color: $datepicker-day-current-background-color;
  color: $datepicker-day-current-text-color;
  border-radius: $datepicker-selected-date-border-radius;
}

div.datepicker-calendar.default table.datepicker-grid td.selectable:hover {
  background-color: $datepicker-day-hover-background-color;
  color: $datepicker-day-hover-text-color;
  border-radius: $datepicker-selected-date-border-radius;
}

div.datepicker-calendar.default table.datepicker-grid td.selectable.focus {
  background-color: $datepicker-day-selected-background-color;
  color: $datepicker-day-selected-text-color;
  border-radius: $datepicker-selected-date-border-radius;
}

div.datepicker-calendar.default table.datepicker-grid td.selectable:focus {
  outline: 2px solid #fff;
  outline-offset: -4px;
}

div.datepicker-calendar.default table.datepicker-grid td.empty {
  visibility: hidden;
}
