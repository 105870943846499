.section-travel-credit {
  .panel-price {

    .panel-body-top > span {
      font-size: $panel-price-amount-font-size;
      font-weight: 700;
    }

    .panel-body-bottom > span {
      font-weight: 700;
    }
  }
}
