.trip-summary {
  margin-top: $padding-small-vertical;
  color: $gray;
  font-size: 1.2em;
  font-weight: 400;
  padding: ($padding-small-vertical * 3) 0;
  margin-bottom: ($padding-small-vertical * 4);

  p {
    margin: 0 0 $padding-small-vertical;
  }

  .icon {
    .icon-product__flight {
      color: $trip-summary-plane-color;
      font-size: 2em;
      left: $padding-small-vertical;
      top: $padding-base-vertical;
    }

    display: inline-block;
    vertical-align: 30%;
    margin-right: $padding-base-vertical;
  }

  .information {
    display: inline-block;

    .recloc {
      text-transform: uppercase;
      font-size: 16px;
      color: $trip-summary-text-color;
    }

    .departure-location,
    .arrival-location,
    .departure-date,
    .arrival-date {
      font-size: 22px;
      color: $trip-summary-text-color;
      font-weight: 700;
    }

    .to-translation {
      text-transform: lowercase;
    }
  }

}
