.confirmation-page-container {

  section {
    margin-bottom: $confirmation-section-margin-vertical;

    p {
      margin-bottom: $confirmation-base-padding / 2;
    }

    .section-body {
      background: $confirmation-section-body-bg;
      padding: $confirmation-base-padding * 4;

      header {
        border-bottom: $confirmation-border-header-width solid $confirmation-border-color;
        padding-bottom: $confirmation-base-padding / 2;
        margin-bottom: $confirmation-base-padding * 2;
      }

      .section-subheader {
        border-bottom: $confirmation-border-header-width solid $confirmation-border-color;
        margin-bottom: $confirmation-base-padding * 2;
        padding-bottom: $confirmation-base-padding;
        text-transform: $confirmation-subheader-text-case;

        i {
          display: inline-block;
          width: ($padding-large-vertical * 2);
        }
      }

      .section-subbody {
        margin-bottom: $confirmation-base-padding * 3;

        &:last-child {
          margin-bottom: 0;
        }
      }

      hr {
        border-top: $confirmation-border-width solid $confirmation-border-color;
        margin: ($confirmation-base-padding * 2) 0 ($confirmation-base-padding * 2) ($confirmation-base-padding * 3);
      }

      .traveler-name {
        padding-left: $confirmation-base-padding * 3;
      }
    }
  }

  .section-booking-confirmation {
    font-size: $confirmation-base-padding * 2;
    margin: ($confirmation-section-margin-vertical * 3) 0;

    .margin-vertical {
      margin: ($padding-large-vertical * 2) 0;
    }

    .h1,
    .h3 {
      color: $gray-dark;
    }

    .confirmation-number {
      color: $confirmation-booking-confirmed-check-success;
      margin-top: 0;
    }

    .view-updated-itinerary-button {
      padding-top: $padding-large-vertical * 2;
    }
  }

  .section-trip-summary {
    .section-body-bottom {
      border-top: $confirmation-border-header-width solid $confirmation-border-color;
      margin-top: $confirmation-base-padding * 2;
      padding-top: $confirmation-base-padding * 2;
    }

    .list-inline > li:first-child {
      padding-left: 0;
    }

  }

  .section-itinerary {
    .air-leg-component {
      border-bottom: $confirmation-border-width solid $confirmation-border-color;
      margin-bottom: $confirmation-base-padding * 4;
      padding-bottom: $confirmation-base-padding * 4;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .layover .hr-text {
      border-top: 1px solid $gray-lighter;

      span {
        background: $almost-white;
      }
    }
  }

  .section-purchase-summary {
    .card-left {
      border-right: $confirmation-border-header-width solid $confirmation-border-color;
      padding-right: $confirmation-base-padding * 4;
    }

    .card-right {
      padding-left: $confirmation-base-padding * 4;
    }

    .section-subbody {
      border-bottom: $confirmation-border-width solid $confirmation-border-color;
      margin-bottom: $confirmation-base-padding * 2;
      padding-bottom: $padding-base-vertical * 2;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  .text-success {
    color: $confirmation-booking-confirmed-check-success;
  }
}