li[class*=social-media] > a {
  font-family: $app-icon-font-family;
  font-size: 2em;

  &:hover, &:active, &:focus {
    text-decoration: none;
  }
}

.social-media-link-facebook > a:before {
  content: $icon-social__facebook;
}

.social-media-link-instagram > a:before {
  content: $icon-social__instagram;
}

.social-media-link-linkedin > a:before {
  content: $icon-social__linkedin;
}

.social-media-link-github > a:before {
  content: $icon-social__github;
}

.social-media-link-youtube > a:before {
  content: $icon-social__youtube;
}

.social-media-link-googleplus > a:before {
  content: $icon-social__googleplus;
}

.social-media-link-twitter > a:before {
  content: $icon-social__twitter;
}

.social-media-link-tumblr > a:before {
  content: $icon-social__tumblr;
}

.social-media-link-flickr > a:before {
  content: $icon-social__flickr;
}

.social-media-link-xing > a:before {
  content: $icon-social__xing;
}