// Grid component that lays out label + data columns that stack on handheld
// Style + a11y contrast expects a white background and $gray (switchfly default: #495057)
// <div class="grid grid-info">
//     <div class="grid-info-label">{{unbound (t "NTS_ROOM_TYPE_NOTATION")}}</div>
//     <div class="grid-info-data">{{roomType}}</div>
//     ...

.grid.grid-info {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: $grid-info-gap;
  grid-auto-rows: auto;

  .grid-info-label {
    color: $gray;
  }
}

@media (max-width: $screen-xs) {
  .grid.grid-info {
    display: block;
  }

  .grid-info-data + .grid-info-label {
    margin-top: $grid-info-gap;
  }
}