// CSS Grid
.grid-inline {
  display: -ms-grid;
  display: inline-grid;
  grid-column-gap: $padding-large-horizontal;

  .col-span-2 {
    -ms-grid-column-span: 3;
    grid-column-end: span 2;
  }

  > div {
    display: table-cell; // IE fallback
  }
}

// Select Option Grid
// -------------------------
// Provides a 2 column grid with 4 matching height rows with a vertical line divider
// Sample usage: Pay Now or Pay Later modal
.select-option-grid {
  $j: 0;
  @for $i from 1 through 4 {
    .grid-item-#{$i} {
      -ms-grid-row: $i + $j;
      -ms-grid-column: 1;
      grid-area: item-#{$i};
    }
    $j: $j+1;
  }

  $k: 4;
  @for $i from 5 through 8 {
    .grid-item-#{$i} {
      -ms-grid-row: $i - $k;
      -ms-grid-column: 5;
      grid-area: item-#{$i};
    }
    $k: $k - 1;
  }

  //vertical line
  .item-vl {
    -ms-grid-row: 1;
    -ms-grid-row-span: 7;
    -ms-grid-column: 3;
    grid-area: vl;
  }

  .grid-container {
    display: grid;
    display: -ms-grid;
    -ms-grid-columns: 1fr $select-option-grid-gap auto $select-option-grid-gap 1fr;
    grid-template-columns: 1fr auto 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    -webkit-column-gap: $select-option-grid-gap;
    -moz-column-gap: $select-option-grid-gap;
    column-gap: $select-option-grid-gap;
    row-gap: $select-option-row-gap;
    position: relative;
    grid-template-areas: "item-1 vl item-5" "item-2 vl item-6" "item-3 vl item-7" "item-4 vl item-8";
  }

  .item-vl.or-text {
    @include vlText(#fff, $font-size-large);
  }
}

// My Trips Grid
//--------------------------
// Provides a 2 column grid with second column auto width
.grid-container {
  &.col-2 {
    display: grid;
    grid-template-columns: minmax(150px, 1fr) auto;
    column-gap: $padding-large-vertical;
    grid-template-areas:
    "grid-a grid-b"
    "grid-c grid-c"
    "grid-d grid-d";

    &.row-gap {
      row-gap: $padding-large-vertical*2;
    }

    .grid-a {
      grid-area: grid-a;
    }

    .grid-b {
      grid-area: grid-b;
    }

    .grid-c {
      grid-area: grid-c;
    }

    .grid-d {
      grid-area: grid-d;
    }

    @media (max-width: $screen-sm) {
      grid-template-rows: auto;
      grid-template-areas:
      "grid-a"
      "grid-c"
      "grid-b"
      "grid-d";
      gap: $padding-large-vertical 0;

      .grid-b {
        text-align: right;
      }
    }
  }
}