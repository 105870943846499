// Compass has been removed as it was blocking Ember CLI and no longer necessary
@import "components/_animation-mixins";
@import "mixins/_skeleton-screens-mixins";

// *** USAGE ***
// -------------------------
// These mixins are generally designed to be consumed by application styles,
// if you want to use a mixin in a client theme, you must define that mixin's
// required vars (even if the value is identical to app-theme),
// else packaging will (correctly) fail.

// HR with Text
// -------------------------
// Include similar to a horizontal rule (hr) element, but with centered text
// Pass in background color of parent element to properly offset text,
// optionally pass in border color to change line color (defaults to hrBorder color var).
// Requires a span element to contain the text that will be centered
// Sample usage: .hr-text { @include hrText(white); }
@mixin hrText($parentBackgroundColor, $borderColor: $hr-border, $borderType: $hr-border-style) {
  border-top: 1px $borderType $borderColor;
  text-align: center;
  line-height: 0;
  margin: $line-height-computed 0;

  span {
    background: $parentBackgroundColor;
    padding: 0 1em;
    @extend .text-muted;
  }
}

// Vertical Line with Text
// -------------------------
// Vertical line element with centered text
// Pass in background color of parent element
// Optionally pass in border color to change the vertical line color, font size,
// padding on the vertical line, and text color
// Sample usage: .item-vl { @include vlText($font-size-large, #fff); }
@mixin vlText($parentBackgroundColor, $fontSize: $font-size-base, $borderColor: $hr-border,  $paddingHr: $padding-small-horizontal, $textColor: currentColor) {
  border-right: 1px solid $borderColor;

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: $parentBackgroundColor;
    font-size: $fontSize;
    padding: $paddingHr 0;
    color: $textColor;
  }
}

// Security Code Help Image Variant
// -------------------------
// Use if you want to show only amex card or only non-amex card help image
// Note: "amex" here means the credit card, not the client.
// Sample usage: For amex only: @include secCodeVariant(amex);
//               For non-amex only: @include secCodeVariant();
//               For BOTH (e.g. if you need to override default theme): @include secCodeVariant(both);

@mixin secCodeVariant($variant: "") {
  @if $variant == "amex" {
    background-position: top right !important;
    width: 61px !important;
  } @else if $variant == "both" {
    width: 117px !important;
    background-position: top left !important;
  } @else {
    width: 50px !important;
    background-position: top left !important;
  }
}

// Show Supported Credit Cards Image
// -------------------------
// Use when client wants to show supported credit cards image
// Note: Requires client theme image "supported_credit_cards.png" to reference
// Sample usage: @include show-credit-cards-image; (in a theme - $assetsPath is inherited from theme)

@mixin show-credit-cards-image {
  .credit-cards-image {
    display: block;
    height: 21px;
    background: transparent url('#{$assetsPath}/supported_credit_cards.png') top left no-repeat;
    background-size: 99% auto;
  }
}

// Two Line table head
//-----------------------
// Prevents two line table heads to misalign layout
// Example is: A translation in a table head is too long and breaks on a new line
// Sample usage:
// @include two-line-tablehead;

@mixin two-line-tablehead {
  .table thead > tr > th {
    word-wrap: break-word;
    height: $font-size-base * 3;
  }
}

// Aside Sections Merge
// -------------------------
// Show aside sections as one group instead of individual separated boxes
// Sample usage:
// @include aside-sections-merge;

@mixin aside-sections-merge($asideContainerBorderColor: $gray-dark, $asideContainerBorderWidth: "0") {
  .aside-contents-container {
    border-style: solid;
    border-color: $asideContainerBorderColor;
    border-width: $asideContainerBorderWidth;

    section {
      background: transparent;
      margin-bottom: 0 !important;
      border-width: 0 !important;
    }
  }
}

// Separated Main Content Sections
// -------------------------
// Visually separate the main content sections
// Sample usage:
// @include main-contents-section-separate(#eee);

@mixin main-contents-section-separate($sectionBgColor) {
  .main-contents-container section {
    background-color: $sectionBgColor !important;
    padding-top: 0;
    margin-bottom: $section-common-bottom-margin !important;

    header {
      background-color: transparent !important;

      > {
        h2,
        p {
          padding-left: 2%;
          padding-right: 2%;
        }
      }
    }

    .section-body {
      background-color: transparent !important;
    }

    footer {
      background-color: transparent !important;
    }
  }
}

// Component Labels
// -------------------------
@mixin c-tag-variant($color, $textColor) {
  background-color: $color;
  color: $textColor;
}

// Component Buttons
// -------------------------
// Used for defining application button styles (used in place of bootstrap button-variant mixin)
// Sample usage:
// @include c-button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border, $btn-primary-color-hover, $btn-primary-bg-color-hover, $btn-primary-border-hover);

@mixin c-button-variant($color, $background, $border, $color-hover, $background-hover, $border-hover, $color-active, $background-active, $border-active) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus-visible {
    color: $color;
    background-color: $background;
    border-color: $border;
    outline-offset: 2px;
  }

  &:hover,
  .open &.dropdown-toggle {
    color: $color-hover;
    background-color: $background-hover;
    border-color: $border-hover;
  }

  &:active,
  &.active,
  .open &.dropdown-toggle {
    color: $color-active;
    background-color: $background-active;
    border-color: $border-active;
  }

  &:active,
  &.active,
  .open &.dropdown-toggle {
    background-image: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $background;
      border-color: $border;
    }
  }
}

// Filter Gradient
// -------------------------
// Renders a simple linear gradient; used for gradient-buttons, and gradient-vertical mixins
// For use in IE 6 - 8. Best practice would have you apply this via a
// This should be placed before background-image properties.
//
// For the `$orientation` parameter, you can pass `vertical` or `horizontal`.
@mixin filter-gradient($start-color, $end-color, $orientation: vertical) {
  $gradient-type: if($orientation == vertical, 0, 1);
  filter: progid:DXImageTransform.Microsoft.gradient(gradientType=#{$gradient-type}, startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}')
}

// Nav Pills
// -------------------------
// Used for defining nav pills, used in policy modals, manage booking nav.
// For use with <a> or <button> tags
// Sample usage:
// @include nav-pills-variant ($nav-pills-padding-bottom, $nav-pills-border-radius, $nav-pills-border-style, $nav-pills-active-link-hover-bg, $nav-pills-hover-bg, $nav-pills-active-link-hover-color, $nav-pills-active-font-color, bold);

@mixin nav-pills-variant(
  $paddingBottom,
  $borderRadius,
  $borderStyle,
  $lineHeight,
  $activeBgColor,
  $hoverBgColor,
  $hoverFontColor,
  $activeBorderColor,
  $activeFontColor,
  $activeFontWeight,
  $linkColor: $link-color,
  $fontWeight: normal) {
  padding-bottom: $paddingBottom;

  > li {
    padding-right: $padding-large-horizontal;
    display: inline-block;
    float: none;

    > button,
    > a {
      border-radius: $borderRadius;
      border: $borderStyle;
      line-height: $lineHeight;
      padding: $nav-link-padding;
      text-decoration: none;
      color: $linkColor;
      font-weight: $fontWeight;

      @media (min-width: $screen-sm) {
        border-radius: $border-radius-base;
      }

      &:hover,
      &:focus {
        background-color: $hoverBgColor;
        border: 1px solid $hoverFontColor;
        color: $hoverFontColor;
      }
    }

    > button {
      background-color: transparent;
    }

    &.active > button {
      background-color: $activeBgColor;
      color: $activeFontColor;
      font-weight: $activeFontWeight;
    }

    &.active > a,
    &.active > button {
      border: $borderStyle;

      &,
      &:hover,
      &:focus {
        background-color: $activeBgColor;
        border-color: $activeBorderColor;
        font-weight: $activeFontWeight;
        color: $activeFontColor;
      }
    }
  }
}

// Gradient button styling (note: Bootstrap default is flat)
// -------------------------
// Enables the use of gradient background on buttons using variables
// Sample usage:
// @include gradient-buttons; in the buttons and use designated variables, ie. $btn-default-bg-start and $btn-default-bg-end.
// Note: For rendering purposes the variables MUST be above the include.
// TODO: Redo using maps when Sass 3.3 is applied to our App

@mixin gradient-buttons {
  .page-content,
  .modal-content {

    // THESE VARS MUST BE SET HERE
    // This is so client theme vars are inherited properly
    $btn-primary-bg-start: $btn-primary-bg !default;
    $btn-primary-bg-end: darken($btn-primary-bg-start, 10%) !default;
    $btn-primary-bg-start-hover: $btn-primary-bg-end !default;
    $btn-primary-bg-end-hover: $btn-primary-bg-start !default;

    $btn-secondary-bg-start: $btn-secondary-bg !default;
    $btn-secondary-bg-end: darken($btn-secondary-bg-start, 10%) !default;
    $btn-secondary-bg-start-hover: $btn-secondary-bg-end !default;
    $btn-secondary-bg-end-hover: $btn-secondary-bg-start !default;

    $btn-default-bg-start: $btn-default-bg !default;
    $btn-default-bg-end: darken($btn-default-bg-start, 10%) !default;
    $btn-default-bg-start-hover: $btn-default-bg-end !default;
    $btn-default-bg-end-hover: $btn-default-bg-start !default;

    .btn-primary {
      @include gradient-vertical($btn-primary-bg-start, $btn-primary-bg-end);

      &:hover, &:focus, &:active, &.active {
        @include gradient-vertical($btn-primary-bg-start-hover, $btn-primary-bg-end-hover);
      }
    }

    .btn-secondary {
      @include gradient-vertical($btn-secondary-bg-start, $btn-secondary-bg-end);

      &:hover, &:focus, &:active, &.active {
        @include gradient-vertical($btn-secondary-bg-start-hover, $btn-secondary-bg-end-hover);
      }
    }

    .btn-default {
      @include gradient-vertical($btn-default-bg-start, $btn-default-bg-end);

      &:hover, &:focus, &:active, &.active {
        @include gradient-vertical($btn-default-bg-start-hover, $btn-default-bg-end-hover);
      }
    }
  }
}

// Flat button styling
// -------------------------
// Include to style a button using Flat UI design (e.g. no gradients or shadows)
// The arguments are optional, so you can make all .btn btn-flat if you want
// without affecting their default colors or border width. Note to define just no border,
// you would reference like: @include btn-flat("","",0);
// Sample usage: .btn { @include btn-flat("","",0); } // Blank values allows for bootstrap color variables to continue being applied
// Don't delete this: Used to make flat buttons when otherwise gradients are used (e.g. via gradient-button mixin above)

@mixin btn-flat($bgColor: "", $bgColorHover: "", $borderWidth: "") {
  filter: progid:DXImageTransform.Microsoft.Gradient(enabled='false') !important;
  background-color: $bgColor !important;
  background-image: none !important;
  @include box-shadow(none !important);
  text-shadow: none !important;
  border: $borderWidth !important;

  &:hover, &:focus, &:active, &.active {
    background-color: $bgColorHover !important;
  }
}

// Site Header with gradient background
// -------------------------
// Enables the use of gradient as background
// Sample usage:
// @include site-header-gradient-background($gray-light, $gray-lighter);

@mixin site-header-gradient-background($site-header-bg-start, $site-header-bg-end) {
  #site-header {
    @include gradient-vertical($site-header-bg-start, $site-header-bg-end);
  }
}

// H3 styling
// -------------------------
// Defines a standardized h3 look to be included for specific elements (e.g. <header>) where an <h3> does not make hierarchical sense.
// NOTE: This is primarily meant for checkout form; elsewhere it's best to just use heading classes, e.g. .h3

@mixin component-h3() {
  word-wrap: break-word;
  margin-top: 0;
  font-size: $font-size-base; // Purposefully text size.
  font-weight: $headings-font-weight-h3;
}

// Pricing Summary "Or" rotate for small windows
// -------------------------
// Rotates the "Or" text in Pricing Summary for when the NTS_OR translation is too long to fit
// Apply by LANGUAGE as necessary
// Sample usage: .lang-en { @include rotateOr };

@mixin rotateOr {
  @media (max-width: 1200px) and (min-width: 769px) {
    .financial .table-container > .col-sm-6 .or {
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg);
      filter: none;
      left: -1.3em;
      top: 50%;
    }
  }
}

// Dynamic text colors
// -------------------------
// Use to set text color based on lightness of background color.
// e.g. If dark background, use white text; if light background, use black.
// Sample usage: .foo { background: black; color: dynamicColor(black); }

@function dynamicColor($color) {
  @if (lightness( $color ) > 50) {
    @return $text-color; // default $text-color For light colors, set dark text
  } @else {
    @return #fff; // For dark colors, set white text
  }
}

// Flexbox
// -------------------------
// Include to set a given component to use CSS Flexbox (pretty good write-up: http://css-tricks.com/snippets/css/a-guide-to-flexbox/)
// Include flexbox on the container of elements to flex, and pass in the direct descendant element type that will be flexing
// Sample usage: ul.foo-bar { @include flexbox() }; (note: this example uses an element selector with class, this is just for sample purpose to describe the relationship between parent/container (ul) and direct descendants (li).

@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;

  li {
    flex-grow: 1;
  }
}

// Custom Select Input Icons
// -------------------------
// Include to set custom select icons on select dropdowns.
// Include in client styles to override default icon, color, font that's set in this mixin.
// Sample usage (custom): @include customSelectIcon(#08C, "\f07d", "MaterialDesign");
// ** NOTE: app-global.scss already includes customSelectIcon by default **

@mixin customSelectIcon($iconBottomPosition: $input-height-base/2, $iconColor: inherit, $iconContent: "\e952", $iconFont: "switchflyShopping") {
  // Using $icon-input__select-indicator from the new custom icon set
  .select-dropdown,
  .btn-text-input {
    position: relative;
    display: block;
    overflow: hidden;
    white-space: nowrap;

    &:after {
      color: $iconColor;
      content: $iconContent;
      font-family: $iconFont;
      pointer-events: none;
      position: absolute;
      right: 0;
      bottom: $iconBottomPosition;
      line-height: $input-height-base;
      font-size: 120%; // Sets icon to 120% of sibling font-size
      background-color: inherit;
      width: 1.2em;
    }

    select {
      -webkit-appearance: none; // Required for Edge and MS Mobile (really)
      appearance: none;
      padding-right: $padding-base-horizontal + $font-size-base;
    }
  }

  .select-dropdown.no-margin:after,
  .btn-text-input:after {
    bottom: 0;
  }

  .ie-browser .select-dropdown:after {
    content: "";
  }

  // The following is an IE9-11 hack to target just those cases and NOT show select-dropdown icon
  // due to those browsers not properly supporting suppression of the browser default select.
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .select-dropdown:after {
      content: "";
    }
  }

  @media screen and (min-width: 0 \0
  ) {
    .select-dropdown:after {
      content: "";
    }
  }
}

// Vertically Align Middle
// -------------------------
// Include to position element in the middle of its parent
// Sample usage: .foo { @include verticallyMiddle() };
// Note: For table cells, use helper class of .valign-middle instead

@mixin verticallyMiddle($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

// Active Caret Down
// -------------------------
// Include content to add active caret down to element
// Sample usage: .foo { @include activeCaretDown($highlighted-thing-bg-color); }

@mixin activeCaretDown($caretColor) {
  position: relative;

  &::before {
    border: $font-size-small solid transparent;
    border-top: $font-size-small solid $caretColor;
    bottom: calc(0px - (calc(#{$font-size-small} * 2))); //minus 0px gives it a negative value, minus (-calc) doesn't work.
    content: "";
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 0;
  }
}

// Split To Columns
// -------------------------
// Split a single chunk of content into N columns; default 2
// Sample usage: .foo ( @include splitToColumns(); }

@mixin splitToColumns($numColumns: 2) {
  -webkit-column-gap: 32px;
  -moz-column-gap: 32px;
  column-gap: 32px;
  -webkit-column-count: $numColumns;
  -moz-column-count: $numColumns;
  column-count: $numColumns;

  @media (max-width: $screen-sm) {
    column-count: 1;
  }
}

// Image Background
// ----------------
// Changes background to .page-dashboard element. A valid image path is expected.
// $fixedBackground param can be overridden to achieve a "fixed" effect
// Sample usage: .case-handheld { @include backgroundImageDashboard("http://i.imgur.com/nJi1CvP.jpg"); }
// Sample usage: .case-desktop { @include backgroundImageDashboard("http://i.imgur.com/nJi1CvP.jpg", true); }

@mixin backgroundImageDashboard($imagePath, $fixedBackground: false) {
  &.page-dashboard {
    background: url($imagePath) no-repeat center top;
    background-size: cover;

    @if $fixedBackground {
      background-attachment: fixed;
    }

    .page-body, div.section-body {
      background-color: transparent;
    }
  }
}

// Background Opacity
// ------------------
// Changes background color opacity based on the element's background and text color
// Sample usage: @include alpha-background-color(rgba($search-form-container-background-color, 0.9), $text-color);

@mixin alpha-background-color($color, $background) {
  $percent: alpha($color) * 100%;
  $opaque: opacify($color, 1);
  $solid-color: mix($opaque, $background, $percent);
  background-color: $solid-color;
  background-color: $color;
}


// Css-Grid
// ------------------
// Prepares a container for css-grid and at the same time creates grid template areas definition and grid areas to all cells annotated with
// grid-item. Support for IE also added.
// Note: Grid item elements need to be all at the same level inside the container
@mixin grid($rows, $columns:10, $columnSize: 0, $shouldAutoFit: true, $columnSizeAutoFit: 265px) {
  //IE support
  display: -ms-grid;
  -ms-grid-rows: 1fr;

  display: grid;

  @if $shouldAutoFit {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(auto-fit, minmax($columnSize, 1fr));

    &.two-column {
      -ms-grid-columns: 1fr 1fr;
    }

  } @else {
    //Carousal implementation with fixed size
    -ms-grid-columns: $columnSizeAutoFit $columnSizeAutoFit $columnSizeAutoFit $columnSizeAutoFit $columnSizeAutoFit $columnSizeAutoFit $columnSizeAutoFit $columnSizeAutoFit $columnSizeAutoFit $columnSizeAutoFit;
    grid-template-columns: repeat($columns, $columnSizeAutoFit);
  }

  grid-template-areas: createGridTemplateAreas($rows, $columns);

  $z: 1;
  @for $i from 0 through ($columns - 1) {
    @for $j from 1 through $rows {
      .grid-item-#{$i}:nth-of-type(#{$z}) {
        grid-area: grid-item-#{$i}-#{$j};

        //IE needs to have base 1 indexed columns
        -ms-grid-column: ($i + 1);
        -ms-grid-row: #{$j};
      }
      $z: $z + 1;
    }
  }
}

@function createGridTemplateAreas($rows, $columns) {
  $grid-template-areas: ();

  @for $row from 1 to $rows + 1 {
    $row-template-area: '';
    @for $column from 0 to $columns {
      $row-template-area: $row-template-area + 'grid-item-#{$column}-#{$row}' + ' ';
    }

    $grid-template-areas: append($grid-template-areas, $row-template-area);
  }

  @return $grid-template-areas;
}

// No-bullets
// ------------------
// Disables bullets for an entire list
@mixin no-bullets {
  list-style: none;
  li {
    list-style-image: none;
    list-style-type: none;
    margin-left: 0;
  }
}

// Section Body Styled
//---------------------
@mixin section-body-styled($borderRadius) {
  border: 1px solid $base-border-color;
  padding: $section-body-styled-padding;
  background-color: $section-body-styled-bg-color;
  border-radius: $borderRadius;
}

// Form control focus state (Moved from /shopping/vendor/sass-bootstrap-3.0.16/sass/_mixins.scss)
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `$input-focus-border` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// Web-kit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.

@mixin form-control-focus($color: $input-border-focus) {
  &:focus {
    border: 2px solid $color;
    outline: 0;
  }
}

// Form validation states (Moved from /shopping/vendor/sass-bootstrap-3.0.16/sass/_mixins.scss)
//
// Used in forms.less to generate the form validation CSS for warnings, errors,
// and successes.

@mixin form-control-validation($text-color: #555, $border-color: #ccc, $background-color: #f5f5f5) {
  // Color the label and help text
  .help-block,
  .control-label {
    color: $text-color;
  }
  // Set the border on specific inputs to match
  .form-control {
    border-color: $border-color;
    &:focus {
      border-color: darken($border-color, 10%);
    }
  }
  // Set validation states also for addons
  .input-group-addon {
    color: $text-color;
    border-color: $border-color;
    background-color: $background-color;
  }
}

@mixin drawer-panel-style (
  $borderRadius: $border-radius-base,
  $bodyBorder: $panel-body-border,
  $padding: $panel-header-padding,
  $bodyBorderRadius: $panel-body-border-radius,
  $bodyPadding: $panel-header-padding,
  $btnBgColor: inherit) {
  button.panel-header {
    border-radius: $borderRadius;
    border: $bodyBorder;
    padding: $padding;
    line-height: inherit;
    background-color: $btnBgColor;
  }

  button.panel-header:not(.collapsed) {
    border-radius: $borderRadius $borderRadius 0 0;
  }

  .panel-body {
    border-radius: $bodyBorderRadius;
    border: $bodyBorder;
    border-top: 0;
    border-radius: 0 0 $borderRadius $borderRadius;
    padding: $bodyPadding;
  }
}

@mixin list-icon-bullets($icon: "\e939", $iconColor: $list-icon-bullet-color) {
  @extend .list-unstyled;
  position: relative !important;

  > li {
    margin-left: 24px;

    &:before {
      display: block;
      position: absolute;
      left: 0;
      content: $icon; // icon-list-item__default-amenity
      font-family: "switchflyShopping";
      color: $iconColor;
    }
  }
}