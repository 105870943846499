// Air Search results fare options
//------------------------------

.fare-options-container {
  background-color: white;
  margin: $padding-large-horizontal;

  .branded-fare-container {
    overflow-x: scroll;
  }

  .shadow {
    position: relative;
  }

  %hidden {
    animation-duration: 100ms;
    animation-name: fadeOut;
    animation-fill-mode: forwards;
  }

 %shadow {
   animation-duration: 100ms;
   animation-name: fadeIn;
   animation-fill-mode: forwards;
   background: rgb(255, 255, 255);
   position: absolute;
   top: 0;
   height: 100%;
   width: 15px;
   content: '';
 }

  .shadow-right {

    &.hidden-right::before {
      @extend %hidden;
    }

    &::before {
      @extend %shadow;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(73, 80, 87, .2) 100%);
      right: 0;
    }
  }

  .shadow-left {
    &.hidden-left::after {
      @extend %hidden;
    }

    &::after {
      @extend %shadow;
      background: linear-gradient(90deg, rgba(73, 80, 87, .2) 0%, rgba(255, 255, 255, 0) 100%);
      left: 0;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .branded-fare-names {
    font-size: $product-additional-options-title-font-size;
    text-align: $product-additional-options-title-text-align;
  }

  .branded-fare-cabin-details {
    font-size: $font-size-base;
    color: $gray;
  }

  $brandedFareColumns: 13;
  $brandedFareRows: 7;
  .branded-fare-options-container {
    @include grid($brandedFareRows, $brandedFareColumns);
  }

  .branded-fare-options-container-fixed {
    @include grid($brandedFareRows, $brandedFareColumns, 200px, false);
  }

  .branded-fare-options-container .selected,
  .branded-fare-options-container-fixed .selected {
    border-left: solid 3px $brand-success;
    border-right: solid 3px $brand-success;

    &:nth-child(#{$brandedFareRows}n+2) {
      border-top: 3px solid $brand-success;
    }

    &:nth-child(#{$brandedFareRows}n+#{$brandedFareRows}) {
      border-bottom: 3px solid $brand-success;
    }
  }
}

.mixed-fare-details-container {
  background-color: $baggage-info-section-bg-color;
  padding-top: $padding-large-vertical;
  padding-bottom: $padding-large-vertical;
}

.branded-fare-baggage-info {
  background-color: $baggage-info-section-bg-color;

  .fare-details-link {
    color: $brand-primary;
  }
}
.branded-fare-info-icon{
  transform: translateY(-15%);
}