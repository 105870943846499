.dashcart-summary-page-container {

  button.btn-add-extras {
    padding: ($padding-large-vertical * 2) ($padding-large-horizontal * 2);
    font-size: $font-size-h3;
  }

  section {
    margin-bottom: $dashcart-summary-section-margin-vertical;

    h3 {
      font-size: 28px; //TODO: heading font size shouldnl't be defined here
      margin: ($padding-large-vertical * 2) 0;
    }

    p {
      margin-bottom: $dashcart-summary-base-padding / 2;
    }

    .section-body {
      background: $almost-white;
      padding: $dashcart-summary-base-padding * 4;

      header {
        border-bottom: $dashcart-summary-border-header-width solid $dashcart-summary-border-color;
        padding-bottom: $dashcart-summary-base-padding / 2;
        margin-bottom: $dashcart-summary-base-padding * 2;
      }

      .section-subheader {
        border-bottom: $dashcart-summary-border-header-width solid $dashcart-summary-border-color;
        margin-bottom: $dashcart-summary-base-padding * 2;
        padding-bottom: $dashcart-summary-base-padding;
        text-transform: $dashcart-summary-subheader-text-case;

        i {
          display: inline-block;
          width: ($padding-large-vertical * 2);
        }
      }

      .section-subbody {
        margin-bottom: $dashcart-summary-base-padding * 3;

        &:last-child {
          margin-bottom: 0;
        }
      }

      hr {
        border-top: $dashcart-summary-border-width solid $dashcart-summary-border-color;
        margin: ($dashcart-summary-base-padding * 2) 0 ($dashcart-summary-base-padding * 2) ($dashcart-summary-base-padding * 3);
      }

      .traveler-name {
        padding-left: $dashcart-summary-base-padding * 3;
      }
    }
  }

  .section-booking-confirmation {
    font-size: $dashcart-summary-base-padding * 2;
    margin: ($dashcart-summary-section-margin-vertical * 3) 0;

    .margin-vertical {
      margin: ($padding-large-vertical * 2) 0;
    }

    .h1,
    .h3 {
      color: $gray-dark;
    }

    .confirmation-number {
      color: $dashcart-summary-booking-confirmed-check-success;
      margin-top: 0;
    }
  }

  .section-trip-summary {
    .section-body-bottom {
      border-top: $dashcart-summary-border-width solid $dashcart-summary-border-color;
      margin-top: $dashcart-summary-base-padding * 2;
      padding-top: $dashcart-summary-base-padding * 2;
    }

    .list-inline > li:first-child {
      padding-left: 0;
    }

  }

  .section-itinerary {
    .air-leg-component {
      border-bottom: $dashcart-summary-border-width solid $dashcart-summary-border-color;
      margin-bottom: $dashcart-summary-base-padding * 4;
      padding-bottom: $dashcart-summary-base-padding * 4;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .layover .hr-text {
      border-top: 1px solid $gray-lighter;

      span {
        background: $almost-white;
      }
    }
  }

  .section-purchase-summary {
    .card-left {
      border-right: $dashcart-summary-border-header-width solid $dashcart-summary-border-color;
      padding-right: $dashcart-summary-base-padding * 4;
    }

    .card-right {
      padding-left: $dashcart-summary-base-padding * 4;
    }

    .section-subbody {
      border-bottom: $dashcart-summary-border-width solid $dashcart-summary-border-color;
      margin-bottom: $dashcart-summary-base-padding * 2;
      padding-bottom: $padding-base-vertical * 2;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  .text-success {
    color: $dashcart-summary-booking-confirmed-check-success;
  }
}