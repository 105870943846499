@keyframes progress-bar-stripes {
  from {
    background-position: $progress-bar-height-desktop 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress-bar-component {
  width: $progress-bar-width-desktop;
  height: $progress-bar-height-desktop;
  background-color: $progress-bar-background-color;
  border: 1px solid $progress-bar-border-color;
  border-radius: $progress-bar-border-radius;
  margin: 0 auto;

  .bar {
    background-size: $progress-bar-height-desktop $progress-bar-height-desktop;
    background-color: $progress-bar-foreground-color;
    background-image: linear-gradient(45deg, $progress-bar-foreground-bars-color 25%, transparent 25%, transparent 50%, $progress-bar-foreground-bars-color 50%, $progress-bar-foreground-bars-color 75%, transparent 75%, transparent);
    height: 100%;
    animation: progress-bar-stripes 2s linear infinite;
  }
}
