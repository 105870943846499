.branded-flights {

  section {
    margin-bottom: calc(#{$line-height-computed} * 4);
  }

  .product-body {
    background: transparent;
    border: 0;

    .view-details {
      color: $branded-flight-cell-toggle-view-details-color;
      vertical-align: middle;

      &:focus {
        text-decoration: none;
      }
    }

    &.cols-height-match > [class*=col-] {
      padding: 0 $padding-base-horizontal;
      vertical-align: bottom;
    }

    &.cols-height-match > [class*=col-]:first-child {
      vertical-align: middle;
    }

    &.selected {
      background: transparent;
    }
    .product-details-component {
      .product-details-header {
        margin-bottom: ($padding-small-vertical * 2);
      }
      .product-details-body {
        font-weight: 400;
      }
    }
  }

  .branded-flight-selected-date {
    text-transform: $branded-flight-selected-date-capitalization;
    font-weight: 400;
  }

  .branded-products-list {
    border-top: $branded-flight-cell-container-active-border-width solid $branded-flight-cell-container-active-border-color;

    .cabin-flag-messages {
      @include flexbox;
      color: $branded-flight-cabin-flag-message-text-color;
      margin-top: $padding-base-vertical;

      li {
        padding: 0 $padding-base-vertical;

        > div {
          font-size: $font-size-base;
          margin-bottom: $padding-base-vertical / 2;

          &:focus {
            outline: none;
          }

          .cabin-message {
            display: none;

            &.only-n-seats-left {
              color: $text-color;
              display: block;
            }

            &.no-messages {
              display: block;
            }
          }

          .fare-cabin-selected {
            background: $brand-success;
          }

        }
        &.selected {
          > div {
            .fare-cabin-selected {
              background: $branded-flight-cell-coach-expanded-background-color;
            }
          }
        }
      }
    }

    .branded-fares {
      @include flexbox;

      .branded-fares-type-button {
        color: #fff;
        margin: 0 $padding-base-vertical $padding-base-horizontal;
        width: 50%;
        padding: 0;

        &:first-child,
        &.branded-fares-type-first-button {
          button {
            background: $branded-flight-cell-background-color;
            color: $branded-flight-cell-text-color;
          }
        }

        &:focus {
          outline: none;
        }

        button {
          background: $branded-flight-cell-background-color-secondary;
          color: $branded-flight-cell-text-color-secondary;
          padding: ($grid-gutter-width / 2);
          width: 100%;
        }

        &.selected-flight {
          .cols-height-match > [class*=col-] {
            padding: 2px 0;
          }

          .branded-fares-button-cabin-subtitle,
          .branded-fares-button-cabin-price {
            visibility: visible;
          }
        }

        &.selected {
          // TODO: dchoi rename class to .expanded in .hbs and .js
          button {
            padding-bottom: $padding-large-vertical + $padding-base-vertical;
          }

          margin-bottom: -$padding-large-vertical;

          .branded-fares-button-cabin-subtitle,
          .branded-fares-button-cabin-price {
            visibility: hidden;
          }
        }

        &.selected-flight.selected {
          .branded-fares-col-footer .icon-product__selected {
            color: $brand-success;
          }
        }
      }
    }

    .air-segment-container {
      border-bottom: $branded-flight-cell-container-active-border-width solid $branded-flight-cell-container-active-border-color;

      &.active {
        background: $branded-flight-product-body-stripe-background-color;
      }

      &:hover {
        background: $branded-flight-product-body-stripe-background-color;
      }

      .air-segments-toggle,
      .branded-fares-upgrade-container {
        display: none;
      }

      .media-object {
        display: inline-block;
        width: auto;
      }

    }

    .air-segments-toggle {
      .hr-text {
        border-color: $branded-flight-layover-span;
        span {
          background: $branded-flight-product-body-stripe-background-color;
        }
      }
    }

    .branded-fares-upgrade-container {
      background: $branded-flight-cell-expanded-background-color;

      &.branded-fare-upgrade-container-coach {
        background: $branded-flight-cell-coach-expanded-background-color;
      }

      &.branded-fare-upgrade-container-business {
        background: $branded-flight-cell-business-expanded-background-color;
      }

      &.branded-fare-upgrade-container-other {
        background: $branded-flight-cell-other-expanded-background-color;
      }

      margin: 0 calc(#{$padding-base-horizontal} * 2) calc(#{$line-height-computed} * 2) calc(#{$padding-base-horizontal} * 2);
      padding: calc(#{$line-height-computed} * 2) calc(#{$line-height-computed} + #{$padding-base-horizontal}) $padding-base-vertical;

      > a {
        color: $branded-flight-cell-expanded-link-text-color;
        line-height: $line-height-computed;
        margin-top: ($line-height-computed / 4);
      }

      > ul {
        @include flexbox;

        > .single-brand-fare-container {
          display: inherit;
          flex-grow: 1;

          .text-large {
            .list-icon-bullets li .icon {
              top: 8px !important;
            }

            li {
              line-height: 2em;
            }
          }

          > li:nth-child(1) {
            flex-grow: 2;

            h4 {
              text-align: left;
            }
          }
        }

        li {
          flex-basis: 0;
        }
      }

      .branded-fares-col {
        background: $branded-flight-column-background-color;
        @include flexbox;
        flex-flow: column;
        margin: 0 $branded-flight-cell-container-active-border-width;
        padding: $padding-base-vertical $padding-base-horizontal;
        position: relative;

        .branded-fares-col-footer .btn-primary {
          background: $branded-flight-cell-button-background-color;
          color: $branded-flight-cell-button-text-color;
        }
        &:hover {
          background: $branded-flight-column-background-color-hover;
          opacity: 1;

          .branded-fares-col-footer {
            .btn-primary {
              background: $branded-flight-cell-button-background-color;
              color: $branded-flight-cell-button-text-color;

              &:hover {
                background: $branded-flight-cell-background-color-hover;
              }
            }
          }
        }

        &.selected,
        &.selected:hover {
          color: $branded-flight-column-selected-text-color;
          background: $branded-flight-column-selected-background-color;

          .branded-fares-col-header h3,
          .branded-fares-col-header .icon-alert__info {
            color: $link-color;
          }
        }

        &.promoted {
          border: ($branded-flight-cell-container-active-border-width * 2) solid $branded-fare-color-border-promoted;
          border-top: 0;
          margin: 0;

          .text-promoted {
            background: $branded-flight-cell-promoted-background-color;
            color: $branded-flight-cell-promoted-text-color;
            font-size: $font-size-small;
            left: 0;
            position: absolute;
            margin: -($branded-flight-cell-container-active-border-width * 2);
            padding: floor($padding-small-vertical / 2);
            right: 0;
            top: -($font-size-small + $branded-flight-cell-container-active-border-width * 2);
          }
        }

        .branded-fares-col-header {
          @include flexbox;
          align-items: center;
          border-bottom: 1px solid $branded-flight-cell-expanded-header-border;
          height: $padding-large-vertical * 5;
          justify-content: center;
          margin: $padding-base-vertical;
          padding: $padding-base-vertical;

          h4 {
            font-weight: 400;
          }
          .icon-alert__info {
            color: $link-color;
            &:hover {
              color: darken($link-color, 20%);
            }
          }

          h4 {
            color: $branded-flight-cell-header-color;
            flex: 2 0 0;
            text-transform: uppercase;
          }
        }

        .branded-fares-col-body {
          flex-grow: 1;
          padding-top: $padding-large-vertical;
          padding-bottom: ($padding-large-vertical * 1.5);

          &.branded-fares-col-body-error {
            padding-bottom: calc(calc(#{$line-height-computed} * 3) + calc(#{$padding-base-vertical} * 3));
          }

          .product-price {
            margin-bottom: ($padding-large-vertical * 1.5);
          }

          .promotionals-texts {
            padding-left: $padding-base-horizontal * 2.5;
            li {
              text-indent: -$padding-small-horizontal / 2;

              &::before{
                left: $padding-base-horizontal * 2;
              }
            }
          }
        }

        .branded-fares-col-footer {
          margin-bottom: $line-height-computed;
          padding: 0 ($padding-base-horizontal * 1.5);
          @include flexbox;
          align-items: center;
          justify-content: center;
          height: calc(#{$line-height-computed} * 3);

          .btn.selected {
            background: $branded-flight-selected-button-background-color;
            color: $branded-flight-cell-selected-text-color;

            &:hover {
              background: $branded-flight-background-color-button-selected-hover;
              color: $brand-danger;
            }
          }

          .error-message {
            color: $brand-danger;
          }
        }
      }

    }

    .branded-fares-upgrade-footer {
      color: $branded-flight-upgrade-footer-text-color;
    }
  }

  .clickable:hover {
    text-decoration: none;
  }

  .margin-vertical {
    margin: ($padding-large-vertical + $padding-base-vertical) 0;
  }

  .line-height-small {
    line-height: $line-height-small;
  }

}