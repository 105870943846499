.web-chat {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 2;

  &.online {

    &.expanded {
      top: 34%;
    }

    .component-header {
      & > div,
      button {
        background-color: $webchat-online-nav-background-color;
      }
    }
  }

  &.offline .component-header > div {
    background-color: $webchat-offline-nav-background-color;
  }

  .component {
    display: flex;
    align-items: center;
  }

  .component-body {
    padding: 10px 10px 0;
    border-radius: $webchat-border-radius 0 0 $webchat-border-radius;
    border: $webchat-border-width solid $webchat-border-color;
    border-right: 0 none;
    background-color: $webchat-background-color;
    width: $webchat-width;
    margin: 0;

    .h2 {
      margin: 0;
    }
  }

  .component-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;

    & > div,
    button,
    button:hover,
    button:focus {
      transform: rotate(-90deg);
      white-space: nowrap;
      padding: 4px 20px;
      border-radius: $webchat-border-radius $webchat-border-radius 0 0;
      color: $webchat-nav-color;
      position: absolute;
      text-decoration: none;
    }
  }
}

#aws-chat {
  width: calc(min(55px, 10.5vh));
  height: calc(min(55px, 10.5vh));
  padding: 0;
  border-radius: 50%;
  border: none;
  box-shadow: 0 0 8px rgba(0,0,0,.3);
  outline: none;
  transition: background 0.5s ease;
  position: fixed;
  z-index: 999999999;
  bottom: 30px;
  right: 30px;
  font-size: $font-size-h3;
}

.modal-open #aws-chat {
  display: none;
}

.aws-chat-section {
  position: fixed;
  bottom: 95px;
  right: 30px;
  z-index: 999999999;
  background-color: $body-bg;
  box-shadow: 0 0 8px rgba(0,0,0,.3);
  border-radius: 10px;

  .component {
    .component-body {
      margin: 0;
      width: 365px;
      height: 525px;
    }
  }

  #aws-chat-iframe {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
}