.date-carousel {
  @include flexbox;
  margin: calc(#{$line-height-computed} / 2) auto calc(#{$line-height-computed} * 2);
  padding-bottom: $font-size-small;
  text-align: center;
  overflow: hidden;

  li {
    margin: 0 1px;
    padding: 0;

    // prev/next date
    &.btn-container {
      background: $date-carousel-li-background-color;
      color: $date-carousel-li-text-color;
      margin: 0;
      width: 3em;
      z-index: 1;

      &:hover {
        background: $date-carousel-li-background-color-hover;

        i {
          color: $date-carousel-li-icon-text-color-hover;
        }

        cursor: pointer;
      }

      a {
        color: $date-carousel-li-text-color;
        height: 100%;
        position: relative;

        i {
          left: 0;
          position: relative;
          top: calc(50% - 15px);
          right: 0;
        }
      }
    }

    // dates
    &.date-carousel-container {
      width: calc(100% - 6em);

      .date-carousel-dates {
        width: 300%;
        position: relative;
        right: 100%;
        align-items: stretch;
        display: flex;

        &.carousel-left-animation {
          @include transition-duration(500ms);
          transition-timing-function: ease;
          @include transform(translateX(33.33%));
        }

        &.carousel-right-animation {
          @include transition-duration(500ms);
          transition-timing-function: ease;
          @include transform(translateX(-33.33%));
        }
      }

      li {
        text-align: center;
        width: 15%;
        background: $date-carousel-li-background-color;
        color: $date-carousel-li-text-color;

        &:hover,
        &.unavailable:hover {
          background: $date-carousel-li-background-color-hover;

          p {
            color: $date-carousel-li-text-hover-color;

            &.date-carousel-price {
              color: $date-carousel-li-price-selected-text-color;
            }
          }
        }

        p {
          margin-bottom: 0;
          -ms-word-wrap: break-word;
          word-wrap: break-word;

          &.date-carousel-price {
            color: $date-carousel-li-price-text-color;
          }
        }

        a {
          color: $date-carousel-li-text-color;
          padding: 8px;
          text-decoration: none !important;
          width: 100%;
          @extend .inline-block;
        }
      }

      li.unavailable {
        background: $date-carousel-li-unavailable-background-color;

        p {
          color: $date-carousel-li-unavailable-text-color;

          &.date-carousel-price {
            color: $date-carousel-li-unavailable-price-text-color;
          }
        }
      }

      li.selected {
        @include activeCaretDown($date-carousel-li-selected-background-color);
      }

      li.selected,
      li.selected:hover {
        background: $date-carousel-li-selected-background-color;

        a {
          cursor: default;
        }

        p {
          color: $date-carousel-li-selected-text-color;

          &.date-carousel-price {
            color: $date-carousel-li-price-selected-text-color;
          }
        }
      }

      li.disabled {
        background: $date-carousel-li-disabled-background-color;

        p {
          color: $date-carousel-li-disabled-text-color;
        }
      }
    }
  }

  li.btn-container.disabled {
    background-color: $date-carousel-li-disabled-background-color;
    a {
      color: $date-carousel-li-disabled-text-color;
    }
  }

  .date-carousel-panel {
    @include flexbox;
    margin-left: 1px;
  }
}

.date-carousel-legend {
  margin: calc(#{$line-height-computed} * 2) 5% $line-height-computed 0;
}