/* 404 PAGE */
// hello world! 
// Extends main-contents-container

.error-page-404 {
  width: 50%;
  margin: 5% auto;

  h1 {
    font-size: 8em;
    border-bottom: 2px solid;
    margin: 0 auto $line-height-computed auto;
    color: $page-404-text !important;
  }

  p {
    color: $page-404-paragraph-text !important;
  }

  .lead {
    font-size: 32px;
    line-height: 32px;
    font-weight: $text-strong-font-weight;
    letter-spacing: -1px;
    color: $page-404-lead-text !important;
  }

  .icon {
    padding-right: 10px;
  }

}

@media (max-width: $screen-sm) {

  .error-page-404 {
    width: 85%;
  }

}
