// -----------------------------

.seat-selection-popover {
  overflow: visible;
  max-width: none;
  width: 320px;

  &.bottom {
    margin-top: 0;
  }

  .popover-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    .btn {
      flex-grow: 0;
      margin: $popover-body-spacing-h / 2;
    }
    .btn {
      flex-grow: 1;
    }
  }
}

.membership-tier {
  text-transform: lowercase;

  &:first-letter {
    text-transform: capitalize;
  }
}

// Account for hiding of scrollbar
.modal-open .seat-map-flight-header.affix {
  right: 15px
}

.seat-map-flight-header {
  padding-left: 0;
  padding-right: 0;

  .flight-info {
    background: $almost-white;
    padding: $padding-small-vertical $padding-small-horizontal;
  }

  &.affix {
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;

    padding-left: $padding-small-horizontal * 2;
    padding-right: $padding-small-horizontal * 2;

    .content {
      padding-left: $padding-small-horizontal * 3;
      padding-right: $padding-small-horizontal * 3;
      background: $almost-white;
    }
  }
}

.spoke-page .affix {
  padding-left: $padding-small-horizontal;
  padding-right: $padding-small-horizontal;
}

// Can be refactored to be a more generic component
.seat-selection-status-bar {
  &.seat-selection-status-success {
    @include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
  }

  &.seat-selection-status-info {
    @include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
  }

  .status-row {
    @include flexbox;
    align-items: baseline;
    padding: $padding-large-vertical 0;
  }
}

.seat-map-legend-trigger {
  height: 2em;
  margin: 4px 8px;
}

.case-base #seat-map-legend {
  padding: $padding-small-vertical * 3 $padding-large-horizontal;
}

.seat-map-flight-container, #seat-map-legend
{
  .seat, .facility {
    font-size: 12px;
    border-radius: 0;
    height: 44px;
    width: 44px;
  }

  .seat {
    &.seat-unavailable {
      background: $aircraft-facility-background-color;
      pointer-events: none;
      position: relative;
      overflow: hidden;
      &::before,
      &::after {
        content: '';
        position: absolute;
        height: 1px;
        width: 62px;
        top: 50%;
        left: -8px;
        background: $aircraft-facility-text-color;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }

    &.selected {
      background-color: $brand-success;
      color: $brand-success-text-color;
      border-radius: 50%;
    }
  }

  .facility {
    background-color: $aircraft-facility-background-color;
    color: $aircraft-facility-text-color;
    font-size: 24px;
    line-height: 38px;

    &.empty {
      background: transparent;
    }

    &.bulkhead {
      border-bottom: 2px $aircraft-cabin-border-color solid;
      background: transparent;
      height: 0;
    }

    &.facility-wchr {
      font-size: 19px; // for alignment of 2 icons with pipe separator
    }
  }
}

.seat-total .row {
  margin-bottom: $padding-large-vertical;
}

.seat-map-buttons, .seat-total {
  padding-top: $padding-large-vertical;
  padding-bottom: $padding-large-vertical;
}

section.seat-map-container {
  background-color: $almost-white;
  margin: 0;
  padding-left: $padding-small-horizontal * 3;
  padding-right: $padding-small-horizontal * 3;
  padding-top: $padding-small-horizontal * 3;

  .module-title {
    padding: $padding-large-vertical 0;
  }

  .seat-map-unavailable {
    padding: $padding-large-vertical * 5 $padding-large-horizontal;
  }

  .seat-map {
    background: #fff;
    overflow: auto;
    padding-bottom: $padding-small-horizontal * 3;
    padding-top: $padding-small-horizontal * 3;
  }

  .seating-summary {
    background: #fff;
    margin: 0 auto;
    max-width: 740px;
    border: 1px $gray-light solid;
    padding: $padding-small-horizontal * 3;

    header {
      border-bottom: none;
      padding: $padding-small-horizontal;
    }

    .seat-air-segments {

      .flight-segment {
        padding: $padding-base-vertical * 3 $padding-small-horizontal $padding-small-vertical / 2;
      }

      .seat-selection-summary-row {
        @include flexbox;
        align-items: center;
        justify-content: center;

        background-color: #fff;
        padding: $padding-small-horizontal 0;
        border-bottom: 1px solid $almost-white;

        &:last-child {
          border-bottom: none;
        }

        .icon-collapsible--closed {
          padding-left: $padding-small-horizontal;
        }
      }

      .seat-selection-summary-container .seat-selection-summary-row:first-of-type {
        border-top: 1px solid $gray-light;
      }

      .segment-content {
        @include flexbox;
        padding: $padding-large-vertical $padding-small-horizontal;
        background: #fff;
        border-bottom: 1px solid $gray-light;
        border-top: 1px solid $gray-light;

        .select-seat {
          color: $link-color;
          span {
            margin-right: $padding-small-horizontal
          }
        }

      }
    }
  }
}

.seat-map-flight-container {
  .table-seat-map {
    margin: 0 auto;

    tr,
    th + th,
    td + td {
      background: $aircraft-cabin-background-color;
    }

    th:first-of-type,
    td:first-of-type,
    th:last-of-type,
    td:last-of-type {
      background: #fff;
    }

    .aircraft-left,
    .aircraft-right {
      width: 30px;
      min-width: 30px;
      border-right: 3px solid $aircraft-cabin-border-color;
    }

    .aircraft-right {
      border-right: 0;
      border-left: 3px solid $aircraft-cabin-border-color;
    }

    th {
      text-align: center;
      padding-top: 20px;
      + th {
        border-bottom: 3px solid $aircraft-cabin-border-color;
      }
      &:last-of-type,
      &.row-number {
        border-bottom: 0;
      }
    }

    td {
      padding: 10px 2px;

      &.bulkhead {
        border-top: 2px $aircraft-cabin-border-color solid;
      }

      .aisle {
        min-width: 44px;
        width: 100%;
      }

      &[colspan]:not([colspan="1"]) .facility {
        width: 100%;
      }
    }

    .row-exit {
      .aircraft-left,
      .aircraft-right {
        padding: 0;
        position: relative;

        &:after {
          font-family: "switchflyShopping";
          color: $aircraft-exit-emergency-border-color;
          display: block;
          font-size: 32px;
          line-height: 44px;
          position: absolute;
          content: "\e934";
          right: 2px;
          top: calc(50% - 22px);
        }
      }

      .aircraft-right:after {
        content: "\e935";
        left: 2px;
      }
    }

    .row-emergency:not(.cabin-facility-row) {
      td + td:not(.aircraft-left):not(.aircraft-right) {
        background: $aircraft-exit-emergency-background-color;
      }
    }

    .row-wing {
      .aircraft-left,
      .aircraft-right {
        background: $aircraft-wing-background-color;
      }

      &.row-wing-first {
        .aircraft-left {
          background-image: linear-gradient(135deg, #fff 0%, #fff 30%, $aircraft-wing-background-color 30%, $aircraft-wing-background-color 100%);
        }

        .aircraft-right {
          background-image: linear-gradient(45deg, $aircraft-wing-background-color 0%, $aircraft-wing-background-color 70%, #fff 70%, #fff 100%);
        }
      }
    }
  }
}
