.header-shortcut-container {
  background: $dashbar-bg-color;
  border-bottom: 2px solid #fff;

  &.affix {
    top: 0;
    width: 100%;
    z-index: 1000;
  }

  &.dashbar-relative {
    position: relative;
  }

  .navbar-nav > li > a {
    line-height: inherit;
  }

  .dashbar-modify-search {
    float: left;
  }

  .dashbar-travelers-shopping-cart {
    float: right;

    > li {
      float: left;
    }

  }

  .nav .dashbar-dropdown-toggle {
    color: dynamicColor($dashbar-bg-color);
    padding: ($padding-large-vertical * 2) $padding-large-horizontal;

    i {
      font-size: $font-size-large;
    }

    &:hover {
      background: $dashbar-bg-color-hover;
      color: $dashbar-link-color;
      text-decoration: none;
    }
  }

  .empty-dashcart {
    color: dynamicColor($dashbar-bg-color);
    padding: ($padding-large-vertical * 2) $padding-large-horizontal;
  }

  .dropdown.dropdown-masked {
    position: static;

    &.open > .dropdown-menu {
      background: $dashbar-contents-bg-color;
      border-bottom: 5px solid $dashbar-border-color;
      border-radius: 0;
      border-top: 0;
      box-shadow: 0 100vh 0 100vh rgba(0, 0, 0, .5), 0 5px 5px rgba(0, 0, 0, .5);
      margin-left: -1px; // Negative offset for the .navbar left 1px border
      margin-top: 0;
      padding: ($padding-large-vertical * 4) 0;
      width: 100vw;

      .clearfix {
        clear: both;
      }
    }
  }

  // Optionally add css animations on dropdowns
  .dropdown {
    .dropdown-menu {
      background: $dashbar-dropdown-bg-color;
      border: 0;
      display: block;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      padding: 0;
      transition: all 0.3s;
      width: 100%;
    }

    &.open {
      .dashbar-dropdown-toggle {
        background: $dashbar-link-bg-color;
        color: $dashbar-link-color;
        //margin-bottom: -1px;
        z-index: 1001;

        @include activeCaretDown($dashbar-link-bg-color);
      }

      .dropdown-menu {
        transition: max-height 1s;
        max-height: 100vh;
        opacity: 1;
      }

      i {
        &.icon* {
          color: $dashbar-link-color;
        }
      }
    }
  }

  // Dropdown section headers
  .dropdown-header {
    border-bottom: 1px solid $dashbar-border-color;
    display: block;
    padding: 3px 0;
    font-size: $font-size-small;
    line-height: $line-height-base;
    color: $dropdown-header-color;
  }

  .dropdown-body {
    .shopping-drawer-icon-hover {
      &:before {
        content: "";
        display: inline-block;
        font-size: $font-size-base;
        font-family: switchflyShopping;
        margin-right: $padding-base-horizontal;
      }
    }

    .shopping-drawer-edit {
      &:before {
        content: "\e94c";
      }
    }

    .shopping-drawer-add {
      &:before {
        content: "\e94e";
      }
    }
  }

  .dashbar-traveler-container {
    .table {
      background: transparent;

      thead {
        font-size: $font-size-small;
        font-weight: $text-strong-font-weight;
        margin-bottom: -$padding-small-vertical;
      }

      tbody {
        background: #fff;
      }
    }

    .traveler-number-message {
      margin: 10px 0 20px;
    }
  }

  .dashbar-dropdown-row {
    margin: ($dashbar-padding / 4) auto;
    background-color: #fff;
  }

  #shopping-cart-dropdown {
    .panel-body {
      background: $shopping-cart-dropdown-background-color;

      > [class*=col-] {
        @extend .no-padding;
      }

      &.empty {
        background: $shopping-cart-section-background-color;
      }

      .heading {
        text-transform: uppercase;
      }
    }

    .dashbar-total-row {
      padding-right: ($padding-base-horizontal + 1);
      p {
        margin-bottom: 0;
      }
    }

    .dashbar-total-fees-notation {
      padding-right: ($padding-base-horizontal + 1);
      margin-bottom: ($padding-base-vertical * 2);
    }

    .drawer-elements-list .panel {
      margin-bottom: $padding-base-vertical;
    }
  }
}

@media (max-width: 769px) {

  .header-shortcut-container {

    .dropdown.dropdown-masked.open > .dropdown-menu {
      padding: ($padding-large-vertical * 2) 0;
    }

    .dropdown {
      .container {
        margin: 0 ($padding-base-horizontal * 2);
      }
    }

  }
}
