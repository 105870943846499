// SG TODO: We will want to refactor the spacing units here to be common wit the rest of the app

.marketing-ad {
  color: $marketing-ad-text-color;
  margin-bottom: 4em;
  max-height: 300px;
  min-width: 988px;
  overflow: hidden;
}

.marketing-ad-info {
  background: $marketing-ad-bg-color;
}

.marketing-ad-body {
  padding: 30px 20px;
}

.mt1em {
  margin-top: 1em;
}

.mw700 {
  min-width: 700px;
}