#ancillary-tiles {
  h3, .h3 {
    color: $ancillary-hub-tile-body-text-color;
  }

  .tile-row {
    @include flexbox;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .tile {
    background: $ancillary-hub-tile-background-color;
    border-color: $brand-primary;
    border-radius: 0;
    margin: 1%;
    width: 30%;
    padding: 0;

    .tile-spinner {
      padding: $ancillary-hub-tile-spinner-padding;
      .icon-spinner {
        @include verticallyMiddle();
      }
    }

    .tile-body {
      .selected {
        color: $brand-primary;
      }
      .icon-product__selected {
        color: $ancillary-hub-tile-success-icon-color;
      }
    }

    .tile-footer {
      padding: $ancillary-hub-tile-footer-padding;
    }

    &:hover,
    &:focus,
    &:visited {
      text-decoration: none;
    }

    &:hover,
    &.tile-selected {
      background: rgba($brand-primary, 0.05);
      h3, .h3 {
        color: $brand-primary;
      }
    }
  }
}