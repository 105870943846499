.similar-products {
  .slideshow-container {
    position: relative;
    margin: 0 auto;

    @media (min-width: $screen-lg) {
      width: $similar-product-container-width;
    }

    @media (min-width: $screen-md) and (max-width: $screen-lg - 1) {
      width: calc(100% - 4em);
    }
  }

  .splide__arrow svg {
    width: 20px;
    height: 20px;
  }

  .splide__arrow path {
    fill: $btn-default-color;
  }

  .splide__arrow:hover path {
    fill: $btn-default-color-hover;
  }

  .splide__arrow:disabled {
    display: none;
  }

  .splide__arrow {
    width: initial;
    height: initial;
    padding: 10px;
    border-radius: 50%;
    border: 1px solid $darker-border-color;
  }

  .splide__arrow--prev {
    left: -2em;
  }

  .splide__arrow--next {
    right: -2em;
  }

  a.kard-link {
    text-decoration: none;
    color: $text-color;
  }

  .img-container {
    min-width: 100%;
  }

  .img-fixed-height {
    height: 161px;
    object-fit: cover;
    border-radius: $border-radius-base $border-radius-base 0 0;

    @media (min-width: $screen-lg) {
      max-width: 326px;
      width: 326px;
    }

    @media (max-width: $screen-lg - 1) {
      max-width: 282px;
      width: 282px;
    }
  }

  .splide__slide {
    border-radius: $border-radius-base;
    border: 1px solid $base-border-color;
    background: #fff;
    margin: 10px;
  }

  .splide__track {
    overflow: hidden;
    padding-bottom: $padding-large-vertical*2;
  }

  .kard-block {
    padding: $padding-large-vertical $padding-large-horizontal;
    margin-top: 0;

    .text-ellipsis {
      @media (min-width: $screen-lg) {
        max-width: 294px;
      }

      @media (max-width: $screen-lg - 1) {
        max-width: 250px;
      }
    }

    .product-deal {
      margin-right: auto;
    }

    hr {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .trip-advisor {
    img {
      height: initial;
      width: initial;
    }
  }
}
.case-handheld .similar-products {
  padding-left: 10px;

  .splide__arrows {
    display: none;
  }
}