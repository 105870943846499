// Colors
// --------------------------------------------------

// Note: Colors are based on https://yeun.github.io/open-color/swatches.html

// Grays
// -------------------------

$gray-dark: #212529 !default;
$gray: #495057 !default;
$gray-medium: #868e96 !default;
$gray-light: #adb5bd !default;
$gray-lighter: #dee2e6 !default;
$almost-white: #f1f3f5 !default;

// Brand colors
// -------------------------

$brand-primary: #1b6ec2 !default; // Used for links, pagination, primary CTA buttons
$brand-secondary: #687090 !default; // Used for promotional messaging, e.g. hotel promotions
$brand-featured: #228ae6 !default; // Used for filter slider, selected room checks, etc, also Featured Hotel
$brand-success: #396f3a !default; // Used for positive alerts:            "Well done! You successfully read this important alert message."
$brand-warning: #b24d04 !default; // Used for warning alerts:             "Warning! Better check yourself, you're not looking too good."
$brand-danger: #c92a2a !default; // Used for error messaging/alerts:     "Oh snap! Change a few things up and try submitting again."
$brand-info: #0b7285 !default; // Used for informational text/alerts:  "Heads up! This alert needs your attention, but it's not super important."
$brand-active: #e3fafc !default; // Used for highlights
$brand-special: #f59f00 !default; // Used for star ratings, hotel rate deals
