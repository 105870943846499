//
// Wells
// --------------------------------------------------

// Base class
.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: $line-height-computed;
  background-color: $well-backround-color;
  border: 1px solid $well-border-color;
  border-radius: $well-border-radius;
  @include box-shadow($shadow-sm);

  &.well-active {
    background: $well-background-color-active;
    color: $well-color-active;
  }
}

// Sizes
.well-lg {
  padding: 24px;
}

.well-sm {
  padding: 9px;
}

// Handheld Variants
.case-handheld {

  .well {
    margin: $padding-base-vertical 0;
  }

  .well-lg {
    margin: $padding-large-vertical 0;
  }

  .well-sm {
    margin: $padding-small-vertical 0;
  }

}