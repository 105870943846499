.air-flight-matrix {
  display: flex;
  flex-flow: column;

  .air-flight-matrix-header {
    margin-bottom: 0;
    margin-top: 0;
  }

  .air-flight-matrix-description {
    margin-top: 1em;
  }

  .air-flight-matrix-navigation-bar {
    display: flex;
    margin-bottom: .5em;

    &.left-visible button:first-child {
      visibility: visible;
    }

    &.right-visible button:last-child {
      visibility: visible;
    }

    button {
      margin-right: 2px;
      visibility: hidden;
      padding: .5em 0;
      width: 50%
    }
  }

  //When hover is set to darker color, all text needs to be visible
  .selected button.btn:hover span,
  .selected button.btn:hover,
  button.btn:hover span,
  button.btn:hover .icon,
  button.btn:hover {
    color: $flight-matrix-cell-hover-text-color !important;
  }

  button.btn:hover span.or {
    color: $flight-matrix-cell-or-text-hover-color !important;
  }

  .air-flight-matrix-table-wrapper {
    position: relative;
    margin-top: 2em;

    table {
      width: 100%;
      table-layout: fixed;

      td, th {
        padding: 1em;
        text-align: center;
        border: 1px solid $flight-matrix-border-color;
        height: 80px;
      }

      th {
        font-weight: $flight-matrix-headers-weight;
      }

      td {
        color: $flight-matrix-cell-text-color;
        padding: 0;
        font-size: $flight-matrix-price-font-size;

        &.no-flight-available:hover {
          background: $flight-matrix-disabled-cell-background-color;
        }

        button {
          color: $flight-matrix-cell-text-color; //overrides btn-link color
          height: 100%;
          padding: 0 .5em;
          font-size: $flight-matrix-price-font-size;
          position: relative;

          &:hover {
            text-decoration: none;
            cursor: pointer !important;
            background-color: rgba($flight-matrix-cell-hover-background-color, $flight-matrix-cell-hover-background-opacity);
          }

          &:focus {
            text-decoration: none;
            cursor: default !important;
            background-color: rgba($flight-matrix-cell-hover-background-color, $flight-matrix-cell-hover-background-opacity);
          }

          &.no-flight-available {
            height: 100%;

            &:disabled {
              background: $flight-matrix-disabled-cell-background-color !important;
              color: $flight-matrix-disabled-cell-text-color !important;

              &:hover {
                background: $flight-matrix-disabled-cell-background-color !important;
                color: $flight-matrix-disabled-cell-text-color !important;
                text-decoration: none;
              }
            }
          }
        }

        &.selected {
          outline: 2px solid $flight-matrix-selected-cell-outline-color;
          outline-offset: -1px;
          background-color: $flight-matrix-selected-cell-background-color;

          .btn, .or {
            color: $flight-matrix-selected-cell-text;
          }

          .btn {
            font-weight: $flight-matrix-selected-cell-weight;
          }

          button.btn-link:hover, button.btn-link:focus {
            cursor: default !important;
            text-decoration: none !important;
            outline: none;
            background-color: transparent;
          }
        }

        &.selected.air-flight-matrix-lowest-price:not(:hover) {
          background-color: $flight-matrix-selected-cell-background-color;
        }
      }

      .air-flight-matrix-lowest-price {
        outline: 2px solid $flight-matrix-lowest-price-outline-color;
        outline-offset: -1px;
        background-color: $flight-matrix-lowest-price-color;
        color: $flight-matrix-lowest-price-text-color;

        span.or,
        button.btn {
          color: $flight-matrix-lowest-price-text-color;
          font-weight: $flight-matrix-lowest-price-font-weight;
        }

        .icon.icon-product__lowest-price {
          color: $flight-matrix-lowest-price-icon-color;
          font-size: $flight-matrix-lowest-price-icon-font-size;
          position: absolute;
          left: 3px;
          top: 3px;
        }
      }

      /* Headers */
      .header {
        background-color: $flight-matrix-headers-background-color;
        color: $flight-matrix-headers-text;
        font-weight: $flight-matrix-headers-weight;
        font-size: $flight-matrix-headers-font-size;

        &:hover {
          background-color: $flight-matrix-headers-background-color;
        }

        /* Selected departure/return dates */
        &.selected {
          background-color: $flight-matrix-selected-dates-background-color;
          border-color: $flight-matrix-selected-dates-background-color;
          color: $flight-matrix-selected-dates-text !important;
          outline: none;

          &:hover {
            background-color: $flight-matrix-selected-dates-background-color;
          }
        }
      }

      .return-header {
        text-align: center;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        padding: 0 0 .5em 0;
        border: none;
      }
    }
  }

  .air-flight-matrix-depart-label {
    position: absolute;
    top: -2em;
    left: 0;
  }

  .air-flight-matrix-lowest-price-legend-wrapper {
    margin-top: 1em;

    .air-flight-matrix-lowest-price-legend-label {
      color: $flight-matrix-legend-label-text-color;

      .icon.icon-product__lowest-price {
        background: $flight-matrix-legend-lowest-price-icon-background-color;
        color: $flight-matrix-legend-lowest-price-icon-text-color;
        padding: 3px;
      }

      span {
        margin-left: $padding-small-horizontal;
      }
    }
  }
}
