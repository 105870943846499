.main-contents-container section.product-images-mosaic {
  background-color: $product-images-mosaic-section-background;
}

#images-mosaic {
  margin: $padding-common-md 0 $padding-common-md*2;
  overflow: hidden;
  border-radius: $images-mosaic-border-radius;
  padding: 0;

  > div {
    width: 100%;
    height: 100%;
    transition: all .2s;
    position: relative;
    overflow: hidden;

    &:hover, &:active, &:focus {
      opacity: .8;
      cursor: pointer;
      transition: all .2s;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &.mosaic-grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    justify-content: start;
    overflow: hidden;
    position: relative;
    max-height: 530px;

    > div {

      &:nth-of-type(1) {
        grid-column: 1 / 4;
        grid-row: 1 / 4;
      }

      &:nth-of-type(2) {
        grid-column: 4 / 4;
        grid-row: 1 / 1;
      }

      &:nth-of-type(3) {
        grid-column: 4 / 4;
        grid-row: 2 / 2;
      }

      &:nth-of-type(4) {
        grid-column: 4 / 4;
        grid-row: 3 / 3;
        display: flex;
      }
    }
  }

  .images-count {
    color: #fff;
    background: $btn-image-label-opacity;
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.mosaic-single {
    > div {
      display: flex;
      height: 530px;
      width: 100%;

      .images-count {
        height: auto;
        width: auto;
        bottom: 20px;
        right: 20px;
        padding: 5px;
        border-radius: $border-radius-base;
      }
    }
  }

}