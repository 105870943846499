.card-deck {
  @include flexbox;
  align-items: baseline;
  justify-content: center;
}

.card {
  @include flexbox;
  flex-flow: column;
  flex: 1;
  border: 1px solid $product-border-color;
  background: $product-body-bg-color;
  padding: $padding-large-vertical $padding-large-horizontal;
  margin: $padding-large-vertical $padding-large-horizontal;
}

.card-header {
  @include flexbox;
  flex-flow: column;
  margin-top: $padding-large-vertical;

  .card-header-title {
    @include flexbox;
    align-items: flex-end;
  }
}

.card-block {
  @include flexbox;
  border-top: 1px solid $product-border-color;
  margin-top: ($padding-large-vertical * 2);
  padding-top: ($padding-large-vertical * 2);

  &.inclusions {
    min-height: 230px;
  }

  &.ancillary-bundle-items {
    flex-flow: column;
  }

  ol, ul {
    margin-top: $padding-large-horizontal;
  }

  li {
    margin-bottom: ($padding-large-vertical * 2);
  }
}

.card-footer {
  @include flexbox;
  align-self: flex-end;
  flex-flow: column;
  border-top: 1px solid $product-border-color;
  margin-top: ($padding-large-vertical * 2);
  padding-top: ($padding-large-vertical * 2);
}
