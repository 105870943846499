.air-layovers {
  height: unset !important;
  flex-wrap: unset !important; // .row-wrap class sometimes is unnecessary

  .layover-element {
    // Add some space between layovers when they are displayed inline
    .text-separator {
      margin-right: $padding-small-horizontal;
    }

    // Remove separator from the last element containing the class (.layover-element)
    &.layover-full:nth-last-child(2) .text-separator {
      display: none;
    }

    // Remove separator from the last element in case only airport codes are displayed
    &.airport-codes-only .text-separator:last-child {
      display: none;
    }
  }
}