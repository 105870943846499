/*!
 * Slider for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */

// This is used for the points allocation slider on checkout
// NOTE: This file HAS BEEN EDITED for use within our application, as such do NOT update it in full

.points-allocation-component {
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.allocation-container {

  .slider {
    display: block;
    vertical-align: middle;
    position: relative;

    input {
      display: none;
    }

    .tooltip-inner {
      white-space: nowrap;
    }

    &.slider-horizontal {
      width: 93% !important;
      height: 30px;
      margin: 12px 0 10px;

      .slider-track {
        height: 11px;
        width: 100%;
        top: 0;
        left: 0;
        border: 1px solid darken($points-slider-selection-background-color, 20%);
      }

      .slider-selection {
        height: 100%;
        top: 0;
        bottom: 0;
      }
    }
  }

  .slider-track {
    position: absolute;
    cursor: pointer;
    background: $points-slider-background-color;
    @include box-shadow(inset 0px 2px 9px -2px rgba(0, 0, 0, 0.5));
    border-radius: 0;
  }

  .slider-selection {
    position: absolute;
    @include box-sizing(border-box);
    background: $points-slider-selection-background-color;
    @include box-shadow(inset 0px 2px 9px -2px rgba(0, 0, 0, 0.5));
    border-radius: 0;
  }

  .slider-handle {
    position: absolute;
    top: -15px; // should be half of the height
    background: $points-slider-handle-text-color;
    width: 30px;
    height: 30px;
    border: 8px solid $points-slider-handle-background-color;
    border-radius: 50%;
  }

  .table {
    margin-bottom: 0;

    > tbody > tr > td {
      line-height: 34px;
    }

    td {
      input {
        line-height: 34px;
        float: none;
        height: initial;
        width: 120px;
        display: inline-block;
        padding: 0;
        padding-left: 12px;
      }
    }

    .plus {
      width: 30px;
      text-align: center;
    }

    td:nth-of-type(2) {
      text-align: right;
    }
  }

  td {
    vertical-align: middle;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      width: 81px; // Keeps open space for price without causing elements to jump when slider is moved
      text-align: right;
    }
  }

  input[type="text"] {
    padding: 0;
    margin-bottom: 0;
  }

  .cash-label {
    @extend .text-muted;
  }

  .points-label {
    color: $points-text-color;
  }

}

.points-allocation-radio .allocation-container {

  .link-breakdown {
    display: inline-block;
  }

  .popover {
    max-width: none;
  }
}
